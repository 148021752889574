import React from "react";
import { trpc } from "@/lib/trpc";
import { PieChart, Pie, Cell, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "@/redux/store";
import { cn } from "@/lib/utils";

interface InventoryPieChartProps {
  fullScreen?: boolean;
}

export function InventoryPieChart({ fullScreen }: InventoryPieChartProps) {
  const project = useAppSelector((state) => state.root.activeProject);

  const { data: inventoryList, isLoading } = trpc.inventory.getInventorySummary.useQuery(
    {
      projectId: project?.id ?? 0,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!inventoryList || inventoryList.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No inventory data available
          </div>
        </CardContent>
      </Card>
    );
  }

  // Filter stocks that have warning balance set
  const inventoryWithWarningLevel = inventoryList.filter(
    (stock) => stock.warningBalance !== null
  );
  
  if (inventoryWithWarningLevel.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No inventory with warning levels found
          </div>
        </CardContent>
      </Card>
    );
  }
  
  // Count inventory by status
  const healthyCount = inventoryWithWarningLevel.filter((stock) => 
    stock.balance > (stock.warningBalance || 0)
  ).length;
  
  const lowInventoryCount = inventoryWithWarningLevel.filter((stock) => 
    stock.balance <= (stock.warningBalance || 0) && stock.balance > 0
  ).length;
  
  const outOfInventoryCount = inventoryList.filter((stock) => 
    stock.balance <= 0
  ).length;

  const chartData = [
    { name: "Healthy", value: healthyCount },
    { name: "Low Inventory", value: lowInventoryCount },
    { name: "Out of Inventory", value: outOfInventoryCount },
  ];

  const chartConfig = {
    healthy: { color: "#10b981" }, // Green
    lowinventory: { color: "#f59e0b" }, // Yellow/Amber
    outofinventory: { color: "#ef4444" }, // Red
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Inventory Status Distribution</CardTitle>
        <CardDescription>
          Overview of inventory levels
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={chartConfig}
          className={cn("mx-auto aspect-square max-h-[300px] w-full", {
            "max-h-none aspect-video h-full relative mt-0": fullScreen,
          })}
        >
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={fullScreen ? 140 : 80}
              innerRadius={fullScreen ? 100 : 60}
              labelLine={false}
              label={({ payload, ...props }) => {
                if (payload.value === 0) {
                  return null;
                }
                return (
                  <text
                    cx={props.cx}
                    cy={props.cy}
                    x={props.x}
                    y={props.y}
                    textAnchor={props.textAnchor}
                    dominantBaseline={props.dominantBaseline}
                    fill={chartConfig[payload.name.toLowerCase().replace(/\s+/g, "") as keyof typeof chartConfig]?.color}
                  >
                    {`${payload.name} (${payload.value})`}
                  </text>
                );
              }}
            >
              {chartData.map((entry, index) => {
                if (entry.value === 0) {
                  return null;
                }
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={chartConfig[entry.name.toLowerCase().replace(/\s+/g, "") as keyof typeof chartConfig]?.color}
                  />
                );
              })}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-bold"
                        >
                          {inventoryList.length}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total Items
                        </tspan>
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Pie>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
} 