import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { trpc } from "../../../../../lib/trpc";
import { Loader2, FileText, ChevronRight, Pen } from "lucide-react";
import { useAppSelector } from "../../../../../redux/store";
import AppButton from "../../../../AppButton";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { DYNAMIC_WORK_ORDER_FORM_DRAWER } from "../../../../drawers/AppDrawer";
import { ExportPdfButton } from "./ExportPdfButton";

// Use a non-null assertion to handle the potential null value
type WorkOrderConfigOutput = NonNullable<
  RouterOutputs["workOrders"]["getConfiguration"]
>;
type WorkOrderTab = WorkOrderConfigOutput["workOrderTabs"][number];

export default function AdditionalFormsTab() {
  const project = useAppSelector((state) => state.root.activeProject);
  const { workOrderId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Fetch work order configuration
  const { data: workOrderConfig, isLoading } =
    trpc.workOrders.getConfiguration.useQuery(
      { projectId: project?.id ?? 0 },
      {
        enabled: !!project?.id,
      }
    );

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (
    !workOrderConfig ||
    !workOrderConfig.workOrderTabs ||
    workOrderConfig.workOrderTabs.length === 0
  ) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center p-8 text-center">
        <FileText className="h-16 w-16 text-gray-300 mb-4" />
        <h3 className="text-lg font-medium text-gray-700">
          No Additional Forms
        </h3>
        <p className="text-sm text-gray-500 mt-2 max-w-md">
          There are no additional forms configured for this project. Contact
          your administrator to set up custom forms.
        </p>
      </div>
    );
  }

  const handleFillForm = (tabId: number) => {
    setSearchParams((prev) => {
      prev.set("drawerType", DYNAMIC_WORK_ORDER_FORM_DRAWER);
      prev.set("tabId", tabId.toString());
      if (workOrderId) {
        prev.set("workOrderId", workOrderId);
      }
      return prev;
    });
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4 text-gray-800">
        Additional Forms
      </h2>
      <div className="grid grid-cols-2 gap-4">
        {workOrderConfig.workOrderTabs.map((tab) => {
          return (
            <div
              key={tab.id}
              className="border rounded-lg overflow-hidden bg-white shadow-sm"
            >
              <div className="space-y-4 p-4">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-primary-900 mr-3" />
                  <div>
                    <h3 className="font-medium text-gray-800">{tab.tabName}</h3>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <AppButton
                    onClick={() => handleFillForm(tab.id)}
                    icon={<Pen className="h-4 w-4" />}
                    size="sm"
                    variant="outline"
                  >
                    Fill Form
                  </AppButton>
                  <ExportPdfButton tabName={tab.tabName} tabId={tab.id} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
