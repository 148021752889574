import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/store";
import { useAssetControllerGetOneAsset } from "../../../../../api/assets/assets";
import AppButton from "../../../../AppButton";
import { ASSET_FORM_DRAWER, DRAWER_CREATE_STATE } from "../../../AppDrawer";
import { DataTable } from "../../../../react-table/DataTable";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { subAssetColumns } from "./columns";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../ui/card";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { trpc } from "../../../../../lib/trpc";

export default function SubEquipmentTab() {
  const [searchParam, setSearchParams] = useSearchParams();
  const assetId = searchParam.get("assetId");
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const {
    data: assets,
    isLoading: assetIsLoading,
    isFetching,
  } = trpc.assets.getOneAsset.useQuery(
    {
      assetId: Number(assetId),
      projectId: activeProj?.id ?? 0,
    },
    {
      enabled: !!assetId && !!activeProj,
      select: (res) => ({
        currentAsset: res,
        children: res.childrenAsset,
      }),
    }
  );

  const table = useReactTable({
    data: assets?.children ?? [],
    columns: subAssetColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <div className="space-y-6 pt-4">
      {/* Parent Equipment Section */}
      {assets?.currentAsset?.parentAssetId && (
        <Card>
          <CardHeader>
            <CardTitle className="font-semibold text-xl">
              Parent Equipment
            </CardTitle>
            <CardDescription className="text-base">
              {assets?.currentAsset?.parentAsset?.name}
            </CardDescription>
            <AppButton
              className="w-fit"
              variant="outline"
              label="View"
              icon={<ChevronLeft />}
              onClick={() => {
                setSearchParams((p) => {
                  p.set(
                    "assetId",
                    assets?.currentAsset?.parentAssetId?.toString() ?? ""
                  );
                  p.set("assetDetailTabIdx", "4");
                  return p;
                });
              }}
            />
          </CardHeader>
        </Card>
      )}

      {/* Children Equipment Section */}
      <Card>
        <CardHeader>
          <CardTitle className="font-semibold text-xl">Sub Equipment</CardTitle>
          <CardDescription className="text-base">
            {assets?.currentAsset?.name}
          </CardDescription>
        </CardHeader>
        <div className="p-6 pt-0">
          <DataTable
            isLoading={assetIsLoading}
            isFetching={isFetching}
            table={table}
            totalCount={assets?.children?.length ?? 0}
          />
          <AppButton
            className="mt-4"
            label="+ Add Sub Equipment"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", ASSET_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                p.set("parentAssetId", assetId ?? "");
                return p;
              });
            }}
          />
        </div>
      </Card>
    </div>
  );
}
