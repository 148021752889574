import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import { AppDateRangePicker } from "../../AppDateRangePicker";
import SelectLocationFilterDialog from "../../dialogs/SelectLocationFilterDialog/SelectLocationFilterDialog";
import { useAssetTypeControllerGetAssetType } from "../../../api/asset-type/asset-type";
import AppUncontrolledWithDialog from "../../dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { selectAssetTypeColumn } from "../workOrders/columns/selectAssetTypeColumns";
import { useSelectAssetFilterStore } from "../../../stores/assets/assetFilterStore";
import { AlertCircle } from "lucide-react";
import { trpc } from "../../../lib/trpc";

export default function AssetFilterDrawer() {
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  // Get store values and functions
  const filterLocation = useSelectAssetFilterStore(
    (state) => state.filterLocation
  );
  const setFilterLocation = useSelectAssetFilterStore(
    (state) => state.setFilterLocation
  );
  const dateRange = useSelectAssetFilterStore((state) => state.dateRange);
  const setDateRange = useSelectAssetFilterStore((state) => state.setDateRange);
  const assetTypesFilter = useSelectAssetFilterStore(
    (state) => state.assetTypes
  );
  const setAssetTypes = useSelectAssetFilterStore(
    (state) => state.setAssetTypes
  );
  const clearAll = useSelectAssetFilterStore((state) => state.cleanUp);

  const { data: assetTypes } = trpc.assets.getAssetTypes.useQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
      companyId: activeComp?.id?.toString() ?? "",
    },
    {
      enabled: !!activeProj && !!activeComp,
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>

      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Location</p>
        <SelectLocationFilterDialog
          currentFilter={filterLocation}
          onApplyFilter={(location) => {
            setFilterLocation(location);
          }}
        />
      </div>
      <AppUncontrolledWithDialog
        placeholder="Select Equipment Type"
        label="Equipment Type"
        items={assetTypes ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Equipment Type"
        columns={selectAssetTypeColumn}
        multiple
        value={assetTypesFilter}
        onChange={(value) => {
          if (Array.isArray(value)) setAssetTypes(value);
        }}
      />
      <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            clearAll();
          }}
        />
      </div>
    </div>
  );
}
