import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import WorkOrderSettingRequiredCheckbox from "./components/WorkOrderSettingRequiredCheckbox";
import WorkOrderSettingVisibilityCheckbox from "./components/WorkOrderSettingVisibilityCheckbox";

export default function RequiredFieldsSettings() {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-8 items-start">
        <AppTitle title="Work Order Field Settings" />
      </div>
      <p className="text-sm text-slate-600">
        Configure which fields are required and visible when creating a new work
        order.
      </p>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Fields</TableHead>
            <TableHead>Is Required?</TableHead>
            <TableHead>Is Visible?</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <div className="space-y-1">
                <p>Subject</p>
                <p className="text-xs text-slate-500">
                  This field is necessary to identify the work order and cannot
                  be modified
                </p>
              </div>
            </TableCell>
            <TableCell>
              <WorkOrderSettingRequiredCheckbox
                keyValue="subjectRequired"
                disabled
              />
            </TableCell>
            <TableCell>
              <WorkOrderSettingVisibilityCheckbox
                keyValue="subjectVisibility"
                disabled
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div className="space-y-1">
                <p>Assign To</p>
                <p className="text-xs text-slate-500">
                  This field cannot be hidden.
                </p>
              </div>
            </TableCell>
            <TableCell>
              <WorkOrderSettingRequiredCheckbox keyValue="assignToRequired" />
            </TableCell>
            <TableCell>
              <WorkOrderSettingVisibilityCheckbox keyValue="assignToVisibility" disabled/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>
              <WorkOrderSettingRequiredCheckbox keyValue="descriptionRequired" />
            </TableCell>
            <TableCell>
              <WorkOrderSettingVisibilityCheckbox keyValue="descriptionVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Work Order Type</TableCell>
            <TableCell>
              <WorkOrderSettingRequiredCheckbox keyValue="workOrderTypeRequired" />
            </TableCell>
            <TableCell>
              <WorkOrderSettingVisibilityCheckbox keyValue="workOrderTypeVisibility" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
