import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { InventoryStatusCard } from "./InventoryStatusCard";

export function InventoryMetrics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);

  const { data: inventoryList, isLoading } = trpc.inventory.getInventorySummary.useQuery(
    {
      projectId: Number(activeProject?.id),
    },
    {
      enabled: !!activeProject?.id,
    }
  );

  if (isLoading || !inventoryList) return null;

  // Filter stocks that have warning balance set
  const stocksWithWarningLevel = inventoryList.filter(
    (stock) => stock.warningBalance !== null
  );
  
  // Count stocks by inventory status
  const healthyCount = stocksWithWarningLevel.filter((stock) => 
    stock.balance > (stock.warningBalance || 0)
  ).length;
  
  const lowStockCount = stocksWithWarningLevel.filter((stock) => 
    stock.balance <= (stock.warningBalance || 0) && stock.balance > 0
  ).length;
  
  const outOfStockCount = inventoryList.filter((stock) => 
    stock.balance <= 0
  ).length;

  const totalCount = inventoryList.length;

  return (
    <div className="grid gap-4 grid-cols-3">
      <InventoryStatusCard
        title="Healthy Inventory"
        value={healthyCount}
        total={totalCount}
        className="bg-green-50"
      />
      <InventoryStatusCard
        title="Low Inventory"
        value={lowStockCount}
        total={totalCount}
        className="bg-yellow-50"
      />
      <InventoryStatusCard
        title="Out of Inventory"
        value={outOfStockCount}
        total={totalCount}
        className="bg-red-50"
      />
    </div>
  );
} 