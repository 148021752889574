export * from "./server-types/user/index";
export * from "./server-types/legalCompliances/index";
export * from "./server-types/workPermit/index";
export * from "./server-types/workOrder/index";
export * from "./server-types/analytics/index";
export * from "./server-types/policies/index";
export * from './server-types/policies';
export * from "./server-types/equipments/index";
export * from "./server-types/pm/index";
export * from "./server-types/assets/index";
export * from "./server-types/feature-toggle/index";
export * from "./server-types/workRequest/index";
export * from "./server-types/pdf/index";
export * from "./server-types/custom-csv/index";
export * from "./server-types/settings/index";
export * from "./server-types/meters/index";
export * from "./server-types/pdf-reports/index";
export * from "./server-types/vendor/index";
export * from "./server-types/tnb/index";
export * from "./server-types/inventory/index";
export * from "./server-types/reports/index";
export * from "./server-types/location/index";
export * from './server-types/pm';
export * from './server-types/location';
export * from './server-types/dynamic-fields';

export const CEREV_CMMS_VERSION = "2.15.0";