import { useSearchParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import {
  ASSET_FORM_DRAWER,
  DRAWER_CREATE_STATE,
} from "../../components/drawers/AppDrawer";
import { useLazyAssetControllerGenerateQrCodeQuery } from "../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../redux/store";
import {
  AssetView,
  useAssetView,
} from "../../stores/assetViewStore/assetViewStore";
import AppTitle from "../HomeScreen/components/AppTitle";
import PrintPdfBtn from "./component/PrintPdfBtn";
import SelectAssetView from "./component/SelectAssetView";
import EquipmentTableView from "./views/EquipmentTypeBasedView/EquipmentTableView";
import LocationBasedView from "./views/LocationBasedView";
import AssetMoreAction from "./component/AssetMoreAction";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";

export default function AssetScreen() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const assetView = useAssetView();
  const [, setSearchParams] = useSearchParams();
  const [trigger, result] = useLazyAssetControllerGenerateQrCodeQuery();

  return (
    <ModuleAccessGuard module={CerevModule.EQUIPMENT}>
      <div className="flex flex-col gap-1 flex-grow">
        <div className="flex justify-between">
          <div className="flex gap-4">
            <AppTitle title="Equipments" />
            <AppButton
              label="+ Create"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", ASSET_FORM_DRAWER);
                  p.set("drawerState", DRAWER_CREATE_STATE);
                  return p;
                });
              }}
            />
            <AssetMoreAction />
          </div>
          <div className="flex gap-4 items-center">
            <PrintPdfBtn />
            <SelectAssetView />
          </div>
        </div>
        {assetView === AssetView.LOCATION_BASED_VIEW ? (
          <LocationBasedView />
        ) : (
          <EquipmentTableView />
        )}
      </div>
    </ModuleAccessGuard>
  );
}
