import { useSelectedCharts, useDashboardActions, useIsSelectionMode } from "../../../stores/dashboardStore/dashboardStore";
import { Button } from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { cn } from "../../../lib/utils";
import { Presentation, X } from "lucide-react";

export default function DashboardBottomBar() {
  const selectedCharts = useSelectedCharts();
  const selectionMode = useIsSelectionMode();
  const { clearSelection } = useDashboardActions();
  const navigate = useNavigate();

  return (
    <div 
      className={cn(
        "fixed translate-y-24 left-0 right-0 bottom-0 flex justify-center z-50 transition-all ease-in-out duration-300",
        {
          "translate-y-0": selectionMode,
        }
      )}
    >
      <div className="bg-neutral-900/95 backdrop-blur-sm shadow-2xl rounded-lg border border-neutral-800 p-4 flex items-center gap-6">
        {/* Selection Status */}
        <div className="flex flex-col">
          <span className="text-xs font-medium text-neutral-400">Selected</span>
          <span className="text-lg font-semibold text-white">
            {selectedCharts.length} Charts
          </span>
        </div>

        {/* Divider */}
        <div className="w-px h-10 bg-neutral-700" />

        {/* Actions */}
        <div className="flex items-center gap-3">
          <Button
            variant="default"
            className="bg-primary-900  text-white gap-2"
            onClick={() => {
              navigate("/analytic-slide-show", {
                state: { selectedCharts },
              });
            }}
            disabled={selectedCharts.length === 0}
          >
            <Presentation className="w-4 h-4" />
            Start Slideshow
          </Button>

          <Button 
            variant="ghost" 
            className="text-neutral-400 hover:text-white hover:bg-neutral-800 gap-2"
            onClick={clearSelection}
          >
            <X className="w-4 h-4" />
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
} 