// Define the interface for the Work Clearance Checklist
export interface WorkClearanceChecklistProps {

  // Form Fields
  location: string;
  maintenanceDate: string;
  maintenanceName: string;

  // Checkbox States
  lineVerification: "Pass" | "Fail" | "N/A" | "";
  chemicalHazard: "Pass" | "Fail" | "N/A" | "";
  microbiologicalHazard: "Pass" | "Fail" | "N/A" | "";

  // Additional Fields
  remarkFurtherAction: string;
  allergenToolsCleaning: "Yes" | "No" | "";

  // Production Info
  productionName: string;
  productionRemark: string;

  // QC Info
  qcName: string;
  finalRelease: "Release" | "Not Release" | "";
  qcFinalReleaseRemark: string;

  // Additional Remarks
  remarks: string;

  // Signatures
  // engineeringSignature is populated from the Defect's createdBy.name
  engineeringSignature: string;
  // engineeringDate is populated from the Defect's createdOn
  engineeringDate: string;
  // productionSignature is populated from the latest WorkOrderStatusTransaction created by a user with position starting with "Production - "
  productionSignature: string;
  // productionDate is populated from the createdOn of the latest WorkOrderStatusTransaction by a Production user
  productionDate: string;
  // qcSignature is populated from the latest WorkOrderStatusTransaction created by a user with position starting with "QC - "
  qcSignature: string;
  // qcDate is populated from the createdOn of the latest WorkOrderStatusTransaction by a QC user
  qcDate: string;
  // acceptedBySignature is populated from the latest WorkOrderStatusTransaction with status name "Close"
  acceptedBySignature: string;
  // acceptedByDate is populated from the createdOn of the latest "Close" status transaction
  acceptedByDate: string;
}

// Function to create default props
export const getDefaultWorkClearanceChecklistProps =
  (): WorkClearanceChecklistProps => {
    return {

      // Form Fields
      location: "",
      maintenanceDate: "",
      maintenanceName: "",

      // Checkbox States
      lineVerification: "Pass",
      chemicalHazard: "Pass",
      microbiologicalHazard: "Pass",

      // Additional Fields
      remarkFurtherAction: "",
      allergenToolsCleaning: "",

      // Production Info
      productionName: "",
      productionRemark: "",

      // QC Info
      qcName: "",
      finalRelease: "",
      qcFinalReleaseRemark: "",

      // Additional Remarks
      remarks: "",

      // Signatures
      // These will be populated from the Defect's createdBy and createdOn in the ExportPdfButton component
      engineeringSignature: "",
      engineeringDate: "",
      // These will be populated from WorkOrderStatusTransactions by Production users
      productionSignature: "",
      productionDate: "",
      // These will be populated from WorkOrderStatusTransactions by QC users
      qcSignature: "",
      qcDate: "",
      // These will be populated from WorkOrderStatusTransactions with "Close" status
      acceptedBySignature: "",
      acceptedByDate: "",
    };
  };

// Modified function to accept props
export const getWorkClearanceChecklistHtml = (
  props: WorkClearanceChecklistProps = getDefaultWorkClearanceChecklistProps()
) => {
  return /*html*/`
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        font-size: 11px;
        box-sizing: border-box;
        margin: 11;
        padding: 0;
      }
      .container {
        width: 100%;
        margin: 0 auto;
        border: 1px solid #000;
        max-width: 800px;
      }
      .container-with-break {
        width: 100%;
        margin: 0 auto;
        border: 1px solid #000;
        page-break-after: always;
        max-width: 800px;
      }
      .header-row {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #000;
      }
      .logo-cell {
        width: 25%;
        border-right: 1px solid #000;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title-cell {
        width: 50%;
        border-right: 1px solid #000;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .title-cell h2 {
        font-weight: bold;
        font-size: 18px;
        margin: 0;
      }
      .doc-info-cell {
        width: 25%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 9px;
      }
      .logo {
        text-align: center;
        line-height: 1.1;
      }
      .company-name {
        font-size: 10px;
        margin-bottom: 3px;
        color: #666;
      }
      .monin-text {
        font-size: 20px;
        font-weight: bold;
        color: #8B5A2B;
        font-family: 'Times New Roman', Times, serif;
      }
      .form-row {
        margin: 8px 20px;
        clear: both;
      }
      .checkbox-row {
        display: flex;
        align-items: center;
        margin: 3px 0 3px 20px;
        width: calc(100% - 40px);
        justify-content: flex-start;
      }
      .checkbox-option {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 33.33%;
      }
      /* For Yes/No options */
      .binary-checkbox-row {
        width: 50%;
        justify-content: flex-start;
      }
      .binary-checkbox-row .checkbox-option {
        max-width: 50%;
      }
      .checkbox {
        width: 16px;
        height: 16px;
        margin: 0 4px;
        display: inline-block;
        box-sizing: border-box;
        flex-shrink: 0;
      }
      .input-line {
        border-bottom: 1px solid #000;
        width: 100%;
        display: inline-block;
        min-height: 14px;
        margin-top: 2px;
      }
      .label {
        font-weight: normal;
        display: block;
        margin-bottom: 1px;
      }
      .footer {
        margin: 10px 20px 10px 20px;
        font-size: 9px;
      }
      h2 {
        margin: 0;
        font-size: 15px;
        line-height: 1.3;
        font-weight: normal;
        color: #555;
      }
      .doc-info-line {
        font-size: 10px;
      }
      .doc-info-cell .doc-info-line {
        font-size: 12px !important;
      }
      .form-content {
        padding-bottom: 10px;
      }
      /* Adjust spacing for specific form elements */
      .form-row:nth-child(n+4):nth-child(-n+6) .checkbox-row {
        margin-top: 0;
      }
      .form-row:nth-child(8) .checkbox-row,
      .form-row:nth-child(12) .checkbox-row {
        margin-top: 0;
      }
      .hazard-description {
        margin: 2px 20px;
        line-height: 1.2;
      }
      .signature-section {
        display: flex;
        justify-content: space-between;
        margin: 8px 20px;
      }
      .signature-column {
        width: 22%;
      }
      .signature-line {
        border-bottom: 1px solid #000;
        width: 100%;
        display: block;
        margin-bottom: 3px;
        min-height: 14px;
      }
      .filled-text {
        font-size: 10px;
      }
      .highlighted-text {
        background-color: #ffff00;
        padding: 2px 5px;
        display: inline-block;
        margin-bottom: 10px;
      }
      .sop-section {
        margin: 20px;
      }
      .sop-title {
        font-weight: bold;
        margin-bottom: 15px;
      }
      .sop-content {
        margin-left: 20px;
      }
      .sop-item {
        margin-bottom: 20px;
      }
      .sop-paragraph {
        margin-top: 10px;
        line-height: 1.5;
      }
      .sop-list {
        list-style-type: disc;
        padding-left: 20px;
        margin-top: 10px;
      }
      .sop-list li {
        margin-bottom: 10px;
        line-height: 1.5;
      }
      .sop-subsection {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .sop-subsection-title {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .procedure-section {
        margin: 0 20px 20px 20px;
      }
      .procedure-title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .procedure-content {
        margin-left: 0;
        margin-bottom: 20px;
      }
      .procedure-item {
        margin-bottom: 15px;
      }
      .procedure-paragraph {
        margin: 5px 0;
        line-height: 1.5;
      }
      .numbered-item {
        margin-bottom: 10px;
        line-height: 1.5;
      }
      .highlight {
        background-color: #ffff00;
      }
      .continued-list {
        list-style-type: disc;
        padding-left: 20px;
        margin-top: 0;
      }
      .continued-list li {
        margin-bottom: 10px;
        line-height: 1.5;
      }
      .conclusion {
        margin: 30px 20px;
        line-height: 1.5;
      }
      /* Compact the signature section further */
      .signature-column > div {
        margin-bottom: 1px;
        font-size: 9px;
      }
      /* Ensure the logo image is properly sized */
      .logo img {
        max-width: 150px;
        max-height: 85px;
      }
    </style>
  </head>
  <body>
    <!-- Page 1 -->
    <div class="container-with-break">
      <!-- Header Section -->
      <div class="header-row">
        <div class="logo-cell">
          <div class="logo">
          <img src="https://storage.googleapis.com/cerev-prod.appspot.com/public/monin-asia-logo.png" style="width: 150px; height: 85px;" />
          </div>
        </div>
        <div class="title-cell">
          <h2>Engineering Plant Maintenance<br>Work Clearance Checklist</h2>
        </div>
        <div class="doc-info-cell">
          <div class="doc-info-line">Doc No: MA / F217</div>
          <div class="doc-info-line">Rev No: 3</div>
          <div class="doc-info-line">Date: 29/07/2024</div>
          <div class="doc-info-line">Page 1 of 4</div>
        </div>
      </div>

      <!-- Form Content -->
      <div class="form-content">
        <div class="form-row">
          <span class="label">1.Location: Section and Machine:</span>
          <span class="input-line"><span class="filled-text">${props.location}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">2.Date:</span>
          <span class="input-line"><span class="filled-text">${props.maintenanceDate}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">3.Maintenance Name:</span>
          <span class="input-line"><span class="filled-text">${props.maintenanceName}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">4.Line Verification:</span>
          <div class="checkbox-row">
            <div class="checkbox-option">
              <span>Pass</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.lineVerification === "Pass" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>Fail</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.lineVerification === "Fail" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>N/A</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.lineVerification === "N/A" ? 'checked' : ''} />
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <span class="label">5.Chemical Hazard:</span>
          <div class="checkbox-row">
            <div class="checkbox-option">
              <span>Pass</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.chemicalHazard === "Pass" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>Fail</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.chemicalHazard === "Fail" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>N/A</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.chemicalHazard === "N/A" ? 'checked' : ''} />
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <span class="label">6.Microbiological Hazard</span>
          <div class="checkbox-row">
            <div class="checkbox-option">
              <span>Pass</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.microbiologicalHazard === "Pass" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>Fail</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.microbiologicalHazard === "Fail" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>N/A</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.microbiologicalHazard === "N/A" ? 'checked' : ''} />
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <span class="label">7.Remark/Further Action Request:</span>
          <span class="input-line"><span class="filled-text">${props.remarkFurtherAction}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">8. Allergen Tools: After Work Done cleaning.</span>
          <div class="checkbox-row binary-checkbox-row">
            <div class="checkbox-option">
              <span>Yes</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.allergenToolsCleaning === "Yes" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>No</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.allergenToolsCleaning === "No" ? 'checked' : ''} />
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <span class="label">9.Production Name:</span>
          <span class="input-line"><span class="filled-text">${props.productionName}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">10.Production Remark:</span>
          <span class="input-line"><span class="filled-text">${props.productionRemark}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">11.QC Name:</span>
          <span class="input-line"><span class="filled-text">${props.qcName}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">12.Final Release:</span>
          <div class="checkbox-row binary-checkbox-row">
            <div class="checkbox-option">
              <span>Release</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.finalRelease === "Release" ? 'checked' : ''} />
              </div>
            </div>
            <div class="checkbox-option">
              <span>Not Release, Further action required</span>
              <div class="checkbox">
                <input type="checkbox" style="vertical-align: middle;" ${props.finalRelease === "Not Release" ? 'checked' : ''} />
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <span class="label">13.QC Final Release Remark:</span>
          <span class="input-line"><span class="filled-text">${props.qcFinalReleaseRemark}</span></span>
        </div>
        
        <div class="form-row">
          <span class="label">14.Remarks:</span>
          <span class="input-line"><span class="filled-text">${props.remarks}</span></span>
        </div>
        
        <div class="footer">
          <div class="hazard-description">
            <strong>Physical Hazard Check</strong> -Visual check ensure area clean with no left behind tools/spare parts/residues/foreign materials.
          </div>
          <div class="hazard-description">
            <strong>Chemical Hazard Check</strong> - Residual check(pH/Chlorine/allergen) to ensure no chemical residual carryover.
          </div>
          <div class="hazard-description">
            <strong>Microbiological Check</strong> – Verification by Air/Microbial Swab Test
          </div>
          
          <div class="signature-section">
            <div class="signature-column">
              <div>Engineering:</div>
              <span class="signature-line"><span class="filled-text">${props.engineeringSignature}</span></span>
              <div>Date:</div>
              <span class="signature-line"><span class="filled-text">${props.engineeringDate}</span></span>
            </div>
            <div class="signature-column">
              <div>Production:</div>
              <span class="signature-line"><span class="filled-text">${props.productionSignature}</span></span>
              <div>Date:</div>
              <span class="signature-line"><span class="filled-text">${props.productionDate}</span></span>
            </div>
            <div class="signature-column">
              <div>QC:</div>
              <span class="signature-line"><span class="filled-text">${props.qcSignature}</span></span>
              <div>Date:</div>
              <span class="signature-line"><span class="filled-text">${props.qcDate}</span></span>
            </div>
            <div class="signature-column">
              <div>Accepted By:</div>
              <span class="signature-line"><span class="filled-text">${props.acceptedBySignature}</span></span>
              <div>Date:</div>
              <span class="signature-line"><span class="filled-text">${props.acceptedByDate}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Continuous content for pages 2-4 -->
    <div class="container">
      <!-- Header Section -->
      <div class="header-row">
        <div class="logo-cell">
          <div class="logo">
          <img src="https://storage.googleapis.com/cerev-prod.appspot.com/public/monin-asia-logo.png" style="width: 150px; height: 85px;" />

          </div>
        </div>
        <div class="title-cell">
          <h2>Engineering Plant Maintenance<br>Work Clearance Checklist</h2>
        </div>
        <div class="doc-info-cell">
        <div class="doc-info-line">Doc No: MA / F217</div>
        <div class="doc-info-line">Rev No: 3</div>
        <div class="doc-info-line">Date: 29/07/2024</div>
          <div class="doc-info-line">Continuation Page</div>
        </div>
      </div>
      
      <div class="form-content">
        <div style="margin: 20px;">
          <div class="highlighted-text">
            Requirements: The form header needs to follow the format shown above and include all the required information.
          </div>
        </div>
        
        <div style="margin: 20px;">
          <div class="highlighted-text">
            Standard Operating Procedure (SOP) for Engineering Plant Maintenance Work Clearance Checklist
          </div>
        </div>
        
        <div class="sop-section">
          <div class="sop-item">
            <div class="sop-title">1. Purpose</div>
            <div class="sop-paragraph">
              This SOP establishes a standardized process for verifying and documenting the completion of engineering maintenance activities while ensuring food safety, hygiene, and operational integrity before releasing equipment for production.
            </div>
          </div>
          
          <div class="sop-item">
            <div class="sop-title">2. Scope</div>
            <div class="sop-paragraph">
              This SOP applies to all maintenance personnel, production supervisors, and quality control (QC) staff responsible for equipment maintenance and clearance procedures.
            </div>
          </div>
          
          <div class="sop-item">
            <div class="sop-title">3. Responsibilities</div>
            <ul class="sop-list">
              <li>Engineering Team: Conduct maintenance, verify equipment functionality, and ensure cleanliness.</li>
              <li>Production Team: Verify equipment readiness for production use.</li>
              <li>QC Team: Verify compliance with food safety and hygiene standards.</li>
            </ul>
          </div>
          
          <div class="sop-item">
            <div class="sop-title">4. Procedure</div>
            <div class="sop-subsection">
              <div class="sop-subsection-title">4.1 Maintenance and Initial Inspection</div>
              <ul class="sop-list">
                <li>The engineer fills in the Location, Machine Name, and Date of maintenance work.</li>
                <li>The Maintenance Name (responsible technician) is recorded.</li>
                <li>After maintenance is performed, the engineer ensures that the machine is functioning correctly.</li>
              </ul>
            </div>
          </div>
          
          <div class="sop-subsection">
              <div class="sop-subsection-title">4.2 Hazard Verification</div>
              <ul class="sop-list">
                <li>The engineer performs a physical hazard check to ensure no tools, spare parts, or foreign materials are left behind.</li>
                <li>Chemical hazard verification is conducted to ensure no chemical residues remain.</li>
                <li>If applicable, microbiological verification is performed.</li>
                <li>Results are marked as Pass, Fail, or N/A as appropriate.</li>
              </ul>
            </div>
            
            <div class="sop-subsection">
              <div class="sop-subsection-title">4.3 Further Action and Remarks</div>
              <ul class="sop-list">
                <li>If any hazard check fails, the engineer documents the required further actions.</li>
                <li>Any additional remarks or observations are recorded.</li>
              </ul>
            </div>
            
            <div class="sop-subsection">
              <div class="sop-subsection-title">4.4 Allergen Tools Cleaning</div>
              <ul class="sop-list">
                <li>The engineer verifies if allergen tools were used and properly cleaned after work completion.</li>
                <li>This is marked as Yes or No accordingly.</li>
              </ul>
            </div>
            
            <div class="sop-subsection">
              <div class="sop-subsection-title">4.5 Production and QC Verification</div>
              <ul class="sop-list">
                <li>Production personnel inspect the equipment and document their findings.</li>
                <li>QC personnel verify compliance with food safety standards.</li>
                <li>Final release decision is made based on all verifications.</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div class="procedure-section">
          <div class="procedure-title">4.1 Maintenance Documentation</div>
          <div class="procedure-content">
            <div class="numbered-item">
              1. The engineer records the *Location* where maintenance was performed.
            </div>
            <div class="numbered-item">
              2. The *Maintenance Name* field identifies the responsible technician.
            </div>
            <div class="numbered-item">
              3. The *Date* of maintenance is documented.
            </div>
          </div>
          
          <div class="procedure-title">4.2 Physical Hazard Verification</div>
          <div class="procedure-content">
            <div class="numbered-item">
              1. The engineer visually inspects the area to ensure:
            </div>
            <ul class="sop-list">
              <li>No tools are left behind</li>
              <li>No spare parts remain in the equipment</li>
              <li>No foreign materials are present</li>
              <li>The area is clean and ready for use</li>
            </ul>
            <div class="numbered-item">
              2. The result is marked as:
            </div>
            <ul class="sop-list">
              <li>*Pass*: No physical hazards detected</li>
              <li>*Fail*: Physical hazards found (requires further action)</li>
              <li>*N/A*: Not applicable to this maintenance activity</li>
            </ul>
          </div>
          
          <div class="procedure-title">4.3 Chemical Hazard Verification</div>
          <div class="procedure-content">
            <div class="numbered-item">
              1. The engineer checks for chemical residues:
            </div>
            <ul class="sop-list">
              <li>pH testing where applicable</li>
              <li>Chlorine residue testing where applicable</li>
              <li>Allergen testing where applicable</li>
            </ul>
            <div class="numbered-item">
              2. The result is marked as:
            </div>
            <ul class="sop-list">
              <li>*Pass*: No chemical residues detected</li>
              <li>*Fail*: Chemical residues found (requires further action)</li>
              <li>*N/A*: Not applicable to this maintenance activity</li>
            </ul>
          </div>
          
          <div class="procedure-title">4.4 Microbiological Verification</div>
          <div class="procedure-content">
            <div class="numbered-item">
              1. If applicable, microbiological verification is performed:
            </div>
            <ul class="sop-list">
              <li>Air testing</li>
              <li>Surface swab testing</li>
            </ul>
            <div class="numbered-item">
              2. The result is marked as:
            </div>
            <ul class="sop-list">
              <li>*Pass*: Meets microbiological standards</li>
              <li>*Fail*: Does not meet standards (requires further action)</li>
              <li>*N/A*: Not applicable to this maintenance activity</li>
            </ul>
          </div>
          
          <div class="procedure-title">4.5 Final Release Process</div>
          <div class="procedure-content">
            <div class="numbered-item">
              1. Production team inspects the machine and records *Production Name* and *Production Check* status.
            </div>
            <div class="numbered-item">
              2. Engineering and QC teams review the maintenance and hazard check; this applies when performing repair work or any other tasks inside the filling room, process room, puree room or any area that involves contact with the product. After completion, a QC check or swab test must be conducted before releasing it for production. <span class="highlight">Situational for product risk.</span>
            </div>
            <div class="numbered-item">
              3. Final Release Decision:
            </div>
            <ul class="sop-list">
              <li>If the machine is ready for use, check *Release* and proceed with production.</li>
              <li>If further action is required, check *Not Release* and document the required actions.</li>
            </ul>
            <div class="numbered-item">
              4. All parties sign and date the form to confirm their verification:
            </div>
            <ul class="sop-list">
              <li>Engineering signature and date</li>
              <li>Production signature and date</li>
              <li>QC signature and date</li>
              <li>Final acceptance signature and date</li>
            </ul>
          </div>
          
          <div class="procedure-title">5. Record Keeping</div>
          <div class="procedure-content">
            <div class="numbered-item">
              1. The completed Work Clearance Checklist is maintained as part of the maintenance records.
            </div>
            <div class="numbered-item">
              2. Records are kept for a minimum of one year or as specified by company policy.
            </div>
            <div class="numbered-item">
              3. These records serve as documentation for:
            </div>
            <ul class="sop-list">
              <li>Maintenance history</li>
              <li>Food safety compliance</li>
              <li>Audit purposes</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;
};

type WorkOrderFormData = Record<string, any>;

// Helper function to safely get values from form data
const getValue = (formData: WorkOrderFormData, fieldId: string): string => {
  if (formData && fieldId in formData) {
    const value = formData[fieldId];
    
    // Handle null/undefined
    if (value == null) {
      return "";
    }
    
    // Handle select field (object with id and label)
    if (typeof value === 'object' && 'id' in value && 'label' in value) {
      return value.id;
    }
    
    // Handle empty array
    if (Array.isArray(value) && value.length === 0) {
      return "";
    }
    
    return String(value);
  }
  return "";
};

// Helper function to get boolean value from form data
const getBooleanValue = (formData: WorkOrderFormData, fieldId: string): boolean => {
  const value = formData[fieldId];
  
  // Handle empty array (unchecked)
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  
  // Handle object with id/label (selected value)
  if (value && typeof value === 'object' && 'id' in value) {
    return value.id.toLowerCase() === 'yes';
  }
  
  return false;
};

// Function to map dynamic field values to WorkClearanceChecklist props
export const mapDynamicFieldsToWorkClearanceChecklist = (
  formData: WorkOrderFormData
): WorkClearanceChecklistProps => {
  return {
    // Form Fields
    location: getValue(formData, "location"),
    maintenanceDate: getValue(formData, "maintenanceDate"),
    maintenanceName: getValue(formData, "maintenanceName"),

    // Checkbox States
    lineVerification: getValue(formData, "lineVerification") as "Pass" | "Fail" | "N/A" | "",
    chemicalHazard: getValue(formData, "chemicalHazard") as "Pass" | "Fail" | "N/A" | "",
    microbiologicalHazard: getValue(formData, "microbiologicalHazard") as "Pass" | "Fail" | "N/A" | "",

    // Additional Fields
    remarkFurtherAction: getValue(formData, "remarkFurtherAction"),
    allergenToolsCleaning: getValue(formData, "allergenToolsCleaning") as "Yes" | "No" | "",

    // Production Info
    productionName: getValue(formData, "productionName"),
    productionRemark: getValue(formData, "productionRemark"),

    // QC Info
    qcName: getValue(formData, "qcName"),
    finalRelease: getValue(formData, "finalRelease") as "Release" | "Not Release" | "",
    qcFinalReleaseRemark: getValue(formData, "qcFinalReleaseRemark"),

    // Additional Remarks
    remarks: getValue(formData, "remarks"),

    // Signatures
    // These will be populated from the Defect's createdBy and createdOn in the ExportPdfButton component
    engineeringSignature: getValue(formData, "engineeringSignature"),
    engineeringDate: getValue(formData, "engineeringDate"),
    // These will be populated from WorkOrderStatusTransactions by Production users
    productionSignature: getValue(formData, "productionSignature"),
    productionDate: getValue(formData, "productionDate"),
    // These will be populated from WorkOrderStatusTransactions by QC users
    qcSignature: getValue(formData, "qcSignature"),
    qcDate: getValue(formData, "qcDate"),
    // These will be populated from WorkOrderStatusTransactions with "Close" status
    acceptedBySignature: getValue(formData, "acceptedBySignature"),
    acceptedByDate: getValue(formData, "acceptedByDate"),
  };
};
