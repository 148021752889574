import { FormProvider, useForm } from "react-hook-form";
import { CerevModule, PtwControllerGetPtwsPtwStatus } from "../../api/model";
import AppTextField from "../../components/AppTextField";
import { Tabs, TabsContent, TabsList } from "../../components/ui/tabs";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import AppTitle from "../HomeScreen/components/AppTitle";
import PermitToWorkTab from "./PermitToWorkTab";
import PtwTabTrigger from "./PtwTabTrigger";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";

const ptwStatuses = [
  PtwControllerGetPtwsPtwStatus.NEW,
  PtwControllerGetPtwsPtwStatus.APPROVED,
  PtwControllerGetPtwsPtwStatus.REJECTED,
];

export default function PermitToWorkScreen() {
  const methods = useForm<{ search: string }>();

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "permitToWorkIdx",
    defaultValue: "0",
  });

  return (
    <ModuleAccessGuard module={CerevModule.WORK_PERMIT}>
      <div className="flex gap-4">
        <AppTitle title="Work Permits" />
      </div>
      <FormProvider {...methods}>
        <AppTextField
          name="search"
          className="w-[400px] mb-1"
          inputClassName="bg-transparent"
          placeholder="Search Name, Ref No..."
        />
        <Tabs
          value={currentTabIdx}
          onValueChange={onTabChange}
          className="mb-4"
        >
          <TabsList className="mb-2">
            {ptwStatuses?.map((sta, idx) => (
              <PtwTabTrigger key={idx} status={sta} tabValue={idx.toString()} />
            ))}
          </TabsList>
          {ptwStatuses?.map((sta, idx) => (
            <TabsContent key={idx} value={idx.toString()}>
              <PermitToWorkTab status={sta} />
            </TabsContent>
          ))}
        </Tabs>
      </FormProvider>
    </ModuleAccessGuard>
  );
}
