import { z } from "zod";

export const updateUserDataSchema = z.object({
    userId: z.number(),
    name: z.string(),
    email: z.string().email(),
    phoneNo: z.string().optional(),
    position: z.string().optional(),
    projectIds: z.array(z.number()),
    attachments: z.array(z.object({
      id: z.number()
    })),
    accountType: z.enum(["SUPERADMIN", "COMPANY_ADMIN", "NORMAL_USER", "VENDOR"]),
    updatedById: z.number()
  });
  
  export type UpdateUserDataInput = z.infer<typeof updateUserDataSchema>;

const WorkOrderFieldStatusEnum = z.enum(['REQUIRED', 'NOT_REQUIRED']);
const WorkOrderFieldVisibilityEnum = z.enum(['VISIBLE', 'HIDDEN']);

export const updateWorkOrderSettingSchema = z.object({
  subjectRequired: WorkOrderFieldStatusEnum,
  descriptionRequired: WorkOrderFieldStatusEnum,
  subjectVisibility: WorkOrderFieldVisibilityEnum,
  descriptionVisibility: WorkOrderFieldVisibilityEnum,
  workOrderTypeRequired: WorkOrderFieldStatusEnum,
  workOrderTypeVisibility: WorkOrderFieldVisibilityEnum,
  assignToRequired: WorkOrderFieldStatusEnum,
  assignToVisibility: WorkOrderFieldVisibilityEnum,
  projectId: z.number()
});

export type UpdateWorkOrderSettingInput = z.infer<typeof updateWorkOrderSettingSchema>;

export const getWorkOrderSettingSchema = z.object({
  projectId: z.number()
});

export type GetWorkOrderSettingInput = z.infer<typeof getWorkOrderSettingSchema>;