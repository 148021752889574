import { useParams, useSearchParams } from "react-router-dom";
import { FileDown } from "lucide-react";
import { trpc } from "../../../../../lib/trpc";
import AppButton from "../../../../AppButton";
import {
  getWorkClearanceChecklistHtml,
  getDefaultWorkClearanceChecklistProps,
  getEngineeringPlantServiceReportHtml,
  getDefaultEngineeringPlantServiceReportProps,
  getRequisitionFormHtml,
  getDefaultRequisitionFormProps,
  mapDynamicFieldsToWorkClearanceChecklist,
  mapDynamicFieldsToEngineeringPlantServiceReport,
  mapDynamicFieldsToRequisitionForm,
} from "@cerev-cmms/pdf-html";
import { format, formatInTimeZone } from "date-fns-tz";

interface ExportPdfButtonProps {
  tabName: string;
  tabId: number;
}

export function ExportPdfButton({ tabName, tabId }: ExportPdfButtonProps) {
  const [searchParams] = useSearchParams();
  const workOrderId = searchParams.get("workOrderId");

  // Fetch work order custom fields if workOrderId is available
  const { data: customFields } =
    trpc.workOrders.getWorkOrderCustomFields.useQuery(
      {
        workOrderId: Number(workOrderId),
        workOrderCustomTabId: tabId,
      },
      {
        enabled: !!workOrderId && !!tabId,
      }
    );

  // Fetch work order details to get createdBy and createdOn
  const { data: workOrderDetails } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) },
    { enabled: !!workOrderId }
  );

  const handleExportPdf = () => {
    const formName = tabName.toLowerCase().replace(/\s+/g, "");
    let htmlContent = "";
    let documentTitle = "";

    // Get form data or use default props if no data is available
    const formData = customFields?.fieldValues
      ? typeof customFields.fieldValues === "string"
        ? JSON.parse(customFields.fieldValues)
        : customFields.fieldValues
      : {};

    if (formName === "workclearancechecklist") {
      // Add createdBy name and createdOn date to the form data
      if (workOrderDetails) {
        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Engineering signature (from work order creator)
        formData.engineeringSignature = workOrderDetails.createdBy?.name || "";
        formData.engineeringDate = workOrderDetails.createdOn
          ? formatInTimeZone(
              new Date(workOrderDetails.createdOn),
              userTimeZone,
              "d MMM yyyy"
            )
          : "";

        // Extract workOrderStatusTransactions
        const statusTransactions =
          workOrderDetails.workOrderStatusTransactions || [];

        // Find the latest Production user transaction
        const productionTransaction = statusTransactions
          .filter((transaction) =>
            transaction.createdBy?.position
              ?.toLowerCase()
              .startsWith("production - ")
          )
          .sort(
            (a, b) =>
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
          )[0];

        // Find the latest QC user transaction
        const qcTransaction = statusTransactions
          .filter((transaction) =>
            transaction.createdBy?.position?.toLowerCase().startsWith("qc - ")
          )
          .sort(
            (a, b) =>
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
          )[0];

        // Find the latest "Close" status transaction
        const closeTransaction = statusTransactions
          .filter(
            (transaction) => transaction.workOrderStatus?.name === "Close"
          )
          .sort(
            (a, b) =>
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
          )[0];

        // Set Production signature and date
        if (productionTransaction) {
          formData.productionSignature =
            productionTransaction.createdBy?.name || "";
          formData.productionDate = productionTransaction.createdOn
            ? formatInTimeZone(
                new Date(productionTransaction.createdOn),
                userTimeZone,
                "d MMM yyyy"
              )
            : "";
        }

        // Set QC signature and date
        if (qcTransaction) {
          formData.qcSignature = qcTransaction.createdBy?.name || "";
          formData.qcDate = qcTransaction.createdOn
            ? formatInTimeZone(
                new Date(qcTransaction.createdOn),
                userTimeZone,
                "d MMM yyyy"
              )
            : "";
        }

        // Set Accepted By signature and date (from Close status)
        if (closeTransaction) {
          formData.acceptedBySignature = closeTransaction.createdBy?.name || "";
          formData.acceptedByDate = closeTransaction.createdOn
            ? formatInTimeZone(
                new Date(closeTransaction.createdOn),
                userTimeZone,
                "d MMM yyyy"
              )
            : "";
        }
      }

      const mappedProps = mapDynamicFieldsToWorkClearanceChecklist(formData);
      htmlContent = getWorkClearanceChecklistHtml(mappedProps);
      documentTitle = "Work Clearance Checklist";
    } else if (formName === "engineeringplantservicereport") {
      // Add createdBy name and createdOn date to the form data for Engineering Plant Service Report
      if (workOrderDetails) {
        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Engineering signature (from work order creator)
        formData.engineeringName = workOrderDetails.createdBy?.name || "";
        formData.engineeringDate = workOrderDetails.createdOn
          ? formatInTimeZone(
              new Date(workOrderDetails.createdOn),
              userTimeZone,
              "d MMM yyyy"
            )
          : "";

        // Extract workOrderStatusTransactions
        const statusTransactions =
          workOrderDetails.workOrderStatusTransactions || [];

        // Find the latest "Close" status transaction
        const closeTransaction = statusTransactions
          .filter(
            (transaction) => transaction.workOrderStatus?.name === "Close"
          )
          .sort(
            (a, b) =>
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
          )[0];

        // Set Accepted By signature and date (from Close status)
        if (closeTransaction) {
          formData.acceptedByName = closeTransaction.createdBy?.name || "";
          formData.acceptedByDate = closeTransaction.createdOn
            ? formatInTimeZone(
                new Date(closeTransaction.createdOn),
                userTimeZone,
                "d MMM yyyy"
              )
            : "";
        }
      }

      const mappedProps =
        mapDynamicFieldsToEngineeringPlantServiceReport(formData);
      htmlContent = getEngineeringPlantServiceReportHtml(mappedProps);
      documentTitle = "Engineering Plant Service Report";
    } else if (formName === "requisitionformforrepairsandmaintenanceworks") {
      // Add createdBy name and createdOn date to the form data for Requisition Form
      if (workOrderDetails) {
        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Set requestedBy from work order creator
        formData.requestedBy = workOrderDetails.createdBy?.name || "";

        formData.machine = workOrderDetails?.asset?.name || "";
        formData.area = workOrderDetails?.location?.name || "";

        // formData.area = workOrderDetails?.asset?. || "";
        // Set dateTimeRequest from work order creation date
        formData.dateTimeRequest = workOrderDetails.createdOn
          ? formatInTimeZone(
              new Date(workOrderDetails.createdOn),
              userTimeZone,
              "dd/MM/yyyy HH:mm"
            )
          : "";

        // Extract workOrderStatusTransactions
        const statusTransactions =
          workOrderDetails.workOrderStatusTransactions || [];

        // Find the latest "Close" status transaction for dateTimeCompleted and verifiedBy
        const closeTransaction = statusTransactions
          .filter(
            (transaction) => transaction.workOrderStatus?.name === "Close"
          )
          .sort(
            (a, b) =>
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
          )[0];

        // Set dateTimeStart from created on of work order
        formData.dateTimeStart = workOrderDetails.createdOn
          ? formatInTimeZone(
              new Date(workOrderDetails.createdOn),
              userTimeZone,
              "dd/MM/yyyy HH:mm"
            )
          : "";

        // Set dateTimeCompleted and verifiedBy from Close status
        if (closeTransaction) {
          formData.dateTimeCompleted = closeTransaction.createdOn
            ? formatInTimeZone(
                new Date(closeTransaction.createdOn),
                userTimeZone,
                "dd/MM/yyyy HH:mm"
              )
            : "";

          formData.verifiedBy = statusTransactions
            .filter((transaction) =>
              transaction.createdBy?.position?.toLowerCase().startsWith("qc - ")
            )
            .sort(
              (a, b) =>
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
            )[0];

          // Also set repairsServiceDoneBy if not already set
          if (!formData.repairsServiceDoneBy) {
            formData.repairsServiceDoneBy =
              workOrderDetails.assignedUsers?.map((user) => user.name)?.join(", ") || "";
          }
        }

        // Spare parts
        formData.spareParts =
          workOrderDetails.stockLedgers?.map((ledger) => ({
            description: ledger.stock?.name || "",
            rkCode: ledger.stock?.name || "",
            qtyUsed: ledger.adjustmentQty || "",
            stkQty: ledger.stock?.balance || "",
          })) || [];
      }

      const mappedProps = mapDynamicFieldsToRequisitionForm(formData);
      htmlContent = getRequisitionFormHtml(mappedProps);
      documentTitle = "Requisition Form for Repairs and Maintenance Works";
    } else {
      alert("PDF export is only available for specific forms.");
      return;
    }

    // Open a new window
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      console.error("Failed to open new window. Pop-up might be blocked.");
      alert(
        "Failed to open PDF. Please check if pop-ups are blocked in your browser."
      );
      return;
    }

    try {
      // Create a blob with the HTML content
      const blob = new Blob([htmlContent], { type: "text/html" });

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Navigate to the blob URL
      newWindow.location.href = url;

      // Clean up the URL object when the window is closed
      newWindow.addEventListener("beforeunload", () => {
        URL.revokeObjectURL(url);
      });

      // Trigger print dialog after content is loaded
      newWindow.addEventListener("load", () => {
        setTimeout(() => {
          newWindow.print();
        }, 500);
      });
    } catch (error) {
      console.error("Error creating PDF content:", error);
      newWindow.close();
      alert("An error occurred while creating the PDF content.");
    }
  };

  // Only show the Export PDF button for supported forms
  const formName = tabName.toLowerCase().replace(/\s+/g, "");
  const supportedForms = [
    "workclearancechecklist",
    "engineeringplantservicereport",
    "requisitionformforrepairsandmaintenanceworks",
  ];

  if (!supportedForms.includes(formName)) {
    return null;
  }

  return (
    <AppButton
      onClick={handleExportPdf}
      icon={<FileDown className="h-4 w-4" />}
      size="sm"
      variant="outline"
    >
      Export PDF
    </AppButton>
  );
}
