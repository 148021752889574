import {
  Hash,
  Tag,
  Text,
  File,
  Calendar,
  ToggleLeft,
  List,
  Image,
} from "lucide-react";
import AppItemInfo from "../../../../AppItemInfo";
import { RouterOutputs } from "@cerev-cmms/trpc";

type PtwFormatWithOption = RouterOutputs["workPermit"]["getOneWorkPermitTemplate"]["ptwFormat"][number];

interface PtwTemplateDetailItemProps {
  ptwFormats: PtwFormatWithOption[];
}

export default function PtwTemplateDetailItem({
  ptwFormats,
}: PtwTemplateDetailItemProps) {
  const renderPtwFields = (ptwFmt: PtwFormatWithOption) => {
    switch (ptwFmt.ptwFormatType) {
      case "TEXT":
        return (
          <AppItemInfo
            label="Input Field"
            icon={<Text className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}
          />
        );
      case "NUMBER":
        return (
          <AppItemInfo
            label="Number"
            icon={<Hash className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );

      case "ATTACHMENT":
        return (
          <AppItemInfo
            label="Attachment"
            icon={<File className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case "DATE":
        return (
          <AppItemInfo
            label="Date"
            icon={<Calendar className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case "BOOLEAN":
        return (
          <AppItemInfo
            label="Yes / No"
            icon={<ToggleLeft className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case "DESCRIPTION":
        return (
          <AppItemInfo
            label="Description"
            icon={<Text className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case "HEADING":
        return (
          <AppItemInfo
            label="Heading"
            icon={<Text className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case "SINGLE_OPTION":
      case "MULTI_OPTION":
        return (
          <AppItemInfo
            label="Options"
            icon={<List className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case "IMAGE":
        return (
          <AppItemInfo
            label="Image"
            icon={<Image className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
    }
  };

  return (
    <>
      {ptwFormats.map((ptwFmt, idx) => {
        return renderPtwFields(ptwFmt);
      })}
    </>
  );
}
