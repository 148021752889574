import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "@/components/ui/badge";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  INVENTORY_DETAIL_DRAWER,
} from "@/components/drawers/AppDrawer";

interface Stock {
  id: number;
  name: string;
  balance: number;
  warningBalance: number | null;
  stockType?: {
    id: number;
    name: string;
  } | null;
}

export const columns: ColumnDef<Stock>[] = [
  {
    accessorKey: "index",
    header: "No.",
    cell: ({ row }) => row.index + 1,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "name",
    header: "Inventory Name",
    cell: ({ row }) => {
      const [searchParams, setSearchParams] = useSearchParams();
      return (
        <span
          className="cursor-pointer underline text-primary-900 hover:text-primary/80"
          onClick={() => {
            setSearchParams((params) => {
              params.set("drawerType", INVENTORY_DETAIL_DRAWER);
              params.set("drawerState", DRAWER_VIEW_STATE);
              params.set("inventoryId", row.original.id.toString());
              return params;
            });
          }}
        >
          {row.original.name}
        </span>
      );
    },
  },
  {
    accessorKey: "stockType",
    header: "Type",
    cell: ({ row }) => {
      const stockType = row.original.stockType;
      return stockType ? <div>{stockType.name}</div> : <div>-</div>;
    },
    accessorFn: (row) => row.stockType?.name || "",
  },
  {
    accessorKey: "balance",
    header: "Current Balance",
    cell: ({ row }) => {
      const balance = parseFloat(row.getValue("balance"));
      return <div>{balance.toFixed(2)}</div>;
    },
  },
  {
    accessorKey: "warningBalance",
    header: "Warning Level",
    cell: ({ row }) => {
      const warningBalance = row.original.warningBalance;
      return <div>{warningBalance ? warningBalance.toFixed(2) : "-"}</div>;
    },
  },
  {
    id: "status",
    header: "Status",
    cell: ({ row }) => {
      const balance = parseFloat(row.getValue("balance"));
      const warningBalance = row.original.warningBalance || 0;
      
      let status = "Normal";
      let statusClass = "bg-green-500 text-white";
      
      if (balance <= 0) {
        status = "Out of Inventory";
        statusClass = "bg-red-500 text-white";
      } else if (balance <= warningBalance) {
        status = "Low Inventory";
        statusClass = "bg-yellow-500 text-white";
      }
      
      return (
        <Badge
          variant="outline"
          className={statusClass}
        >
          {status}
        </Badge>
      );
    },
    accessorFn: (row) => {
      const balance = row.balance;
      const warningBalance = row.warningBalance || 0;
      
      if (balance <= 0) {
        return "Out of Inventory";
      } else if (balance <= warningBalance) {
        return "Low Inventory";
      }
      return "Normal";
    },
  },
]; 