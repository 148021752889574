import { zodResolver } from "@hookform/resolvers/zod";
import { parseISO } from "date-fns";
import { FileCheck2, Hash, MapPin, User } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import AppItemInfo from "../../../AppItemInfo";
import PtwFormatRenderer from "../PtwFormatRenderer";
import { PtwFormSchema, PtwFormType } from "../PtwFormSchema";
import ExportCustomPtwPdf from "./components/ExportCustomPtwPdf";
import PtwApproveBtn from "./components/PtwApproveBtn";
import PtwRejectBtn from "./components/PtwRejectBtn";
import PtwStatusChip from "./components/PtwStatusChip";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

export default function PtwDetailDrawer() {
  const [searchParams] = useSearchParams();
  const ptwId = searchParams.get("ptwId");
  const methods = useForm<PtwFormType>({
    resolver: zodResolver(PtwFormSchema),
    defaultValues: {
      formFields: [],
    },
  });

  const { data, isFetchedAfterMount } = trpc.workPermit.getWorkPermit.useQuery(
    {
      ptwId: ptwId as string,
    },
    {
      enabled: !!ptwId,
    }
  );

  const isCustomTemplate = data?.ptwTemplate?.setupType === "CUSTOM";

  // Populate the work permit form based on format in template
  useEffect(() => {
    if (isFetchedAfterMount && data) {
      methods.reset({
        refNo: data.refNo ?? "",
        name: data.name ?? "",
        icNo: data.icNo ?? "",
        location: data.location ?? "",
        email: data.email ?? "",
        ptwTemplate: data.ptwTemplate,
        ptwTexts: data.ptwTexts,
        ptwBools: data.ptwBools,
        ptwDates: data.ptwDates.map((ptwd) => ({
          ...ptwd,
          date: ptwd.date ?? undefined,
        })),
        ptwNumbers: data.ptwNumbers?.map((ptwn) => ({
          ...ptwn,
          value: Number(ptwn.value),
        })),
        ptwImages: data.ptwImages,
        ptwAttachments: data.ptwAttachments,
        ptwOptionValues: data.ptwOptionValues,
        formFields: data.ptwTemplate.ptwFormat.map((f) => {
          return {
            id: f.id,
            name: f.name,
            ptwFormatType: f.ptwFormatType,
            heading: f.heading,
            description: f.description,
            ptwOptions: f.ptwOptions,
            ptwFormatId: f.id,
          };
        }),
      });
    }
  }, [isFetchedAfterMount, data]);

  const arrayFields = useFieldArray({
    control: methods.control,
    name: "formFields",
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-bold font-sans">
          {data?.ptwTemplate?.name ?? "-"}
        </h3>
        {isCustomTemplate && (
          <Badge
            variant="outline"
            className={cn(
              "w-fit text-xs px-3 py-1 bg-primary-50 border-primary-900 text-primary-900",
              "flex items-center gap-1.5 mb-4"
            )}
          >
            <div className="w-1.5 h-1.5 rounded-full bg-primary-900" />
            Custom Template
          </Badge>
        )}
        <div className="flex gap-3 items-center">
          <PtwStatusChip ptwStatus={data?.ptwStatus} />
        </div>
      </div>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6">
          <h3 className="text-xl font-bold mt-6">General</h3>
          <AppItemInfo
            label="Applicant Name"
            icon={<User />}
            content={<p>{methods.getValues("name")}</p>}
          />
          <AppItemInfo
            label="Work Permit Type"
            icon={<FileCheck2 />}
            content={<p>{methods.getValues("ptwTemplate.name")}</p>}
          />
          <AppItemInfo
            label="Location"
            icon={<MapPin />}
            content={<p>{methods.getValues("location")}</p>}
          />
          <AppItemInfo
            label="Email"
            icon={<User />}
            content={<p>{methods.getValues("email")}</p>}
          />
          <AppItemInfo
            label="Ref No"
            icon={<Hash />}
            content={<p>{methods.getValues("refNo")}</p>}
          />
          <AppItemInfo
            label="IC / Passport No."
            icon={<User />}
            content={<p>{methods.getValues("icNo")}</p>}
          />

          <h3 className="text-xl font-bold mt-8">Work Permit Fields</h3>
          <PtwFormatRenderer arrayFields={arrayFields} isDisplayOnly />
        </div>
      </FormProvider>
      <ExportCustomPtwPdf />
      {data?.ptwStatus === "NEW" && (
        <div className="flex gap-4 mt-4">
          <PtwApproveBtn />
          <PtwRejectBtn />
        </div>
      )}
    </div>
  );
}
