import { z } from "zod";
import { dynamicFieldSchema, dynamicFieldsSchema } from "../dynamic-fields";

export const getWorkRequestQrCodeWithOptionalFieldSchema = z.object({
  projectId: z.string(),
  isLocationRequired: z.string(),
});

export type GetWorkRequestQrCodeWithOptionalField = z.infer<
  typeof getWorkRequestQrCodeWithOptionalFieldSchema
>;

export const getWorkRequestSettingPublicSchema = z.object({
  projectWorkRequestCode: z.string(),
});

export type GetWorkRequestSettingPublicInput = z.infer<
  typeof getWorkRequestSettingPublicSchema
>;

const visibilityEnum = z.enum(["HIDDEN", "VISIBLE"]);
const requiredEnum = z.enum(["REQUIRED", "NOT_REQUIRED"]);

// The dynamicFieldSchema has been moved to ../dynamic-fields

const updateWorkRequestSettingSchema = z.object({
  projectId: z.number(),
  emailRequired: requiredEnum.optional(),
  emailVisibility: visibilityEnum.optional(),
  subjectRequired: requiredEnum.optional(),
  subjectVisibility: visibilityEnum.optional(),
  descriptionRequired: requiredEnum.optional(),
  descriptionVisibility: visibilityEnum.optional(),
  contactNoRequired: requiredEnum.optional(),
  contactNoVisibility: visibilityEnum.optional(),
  locationRequired: requiredEnum.optional(),
  locationVisibility: visibilityEnum.optional(),
  locationDetailRequired: requiredEnum.optional(),
  locationDetailVisibility: visibilityEnum.optional(),
  nameRequired: requiredEnum.optional(),
  nameVisibility: visibilityEnum.optional(),
  departmentRequired: requiredEnum.optional(),
  departmentVisibility: visibilityEnum.optional(),
  photoRequired: requiredEnum.optional(),
  photoVisibility: visibilityEnum.optional(),
  dynamicFields: dynamicFieldsSchema.optional().nullable(),
});

export { updateWorkRequestSettingSchema };

export type UpdateWorkRequestSettingInput = z.infer<
  typeof updateWorkRequestSettingSchema
>;

export const getOneWorkRequestSettingSchema = z.object({
  projectId: z.number(),
});

export type GetOneWorkRequestSettingInput = z.infer<
  typeof getOneWorkRequestSettingSchema
>;

export const getOneWorkRequestSchema = z.object({
  workRequestId: z.string(),
});

export type GetOneWorkRequestInput = z.infer<typeof getOneWorkRequestSchema>;

export const getWorkRequestsSchema = z.object({
  projectId: z.string(),
  workRequestStatus: z
    .enum(["NEW", "IN_PROGRESS", "COMPLETED", "CANCELLED"])
    .optional(),
  cursorId: z.string().optional(),
  search: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  showUnlinkedOnly: z.boolean().optional(),
  locFilterId: z.string().optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
});

export type GetWorkRequestsInput = z.infer<typeof getWorkRequestsSchema>;
