import { useParams, useSearchParams } from "react-router-dom";
import { FileDown } from "lucide-react";
import { trpc } from "../../../../../lib/trpc";
import AppButton from "../../../../AppButton";
import {
  getWorkClearanceChecklistHtml,
  getDefaultWorkClearanceChecklistProps,
  getEngineeringPlantServiceReportHtml,
  getDefaultEngineeringPlantServiceReportProps,
  getRequisitionFormHtml,
  getDefaultRequisitionFormProps,
  mapDynamicFieldsToWorkClearanceChecklist,
  mapDynamicFieldsToEngineeringPlantServiceReport,
  mapDynamicFieldsToRequisitionForm,
} from "@cerev-cmms/pdf-html";
import { format, formatInTimeZone } from "date-fns-tz";

interface ExportPdfButtonProps {
  tabName: string;
  tabId: number;
}

export function ExportPdfButton({ tabName, tabId }: ExportPdfButtonProps) {
  const [searchParams] = useSearchParams();
  const pmChecklistId = searchParams.get("pmChecklistId");

  // Fetch PM checklist custom fields if pmChecklistId is available
  const { data: customFields } =
    trpc.pm.getPmChecklistCustomFields.useQuery(
      {
        pmChecklistId: Number(pmChecklistId),
        pmChecklistCustomTabId: tabId,
      },
      {
        enabled: !!pmChecklistId && !!tabId,
      }
    );

  // Fetch PM checklist details to get createdBy and createdOn
  const { data: pmChecklistDetails } = trpc.pm.getOnePmChecklist.useQuery(
    { pmChecklistId: Number(pmChecklistId) },
    { enabled: !!pmChecklistId }
  );

  const handleExportPdf = () => {
    const formName = tabName.toLowerCase().replace(/\s+/g, "");
    let htmlContent = "";
    let documentTitle = "";

    // Get form data or use default props if no data is available
    const formData = customFields?.fieldValues
      ? typeof customFields.fieldValues === "string"
        ? JSON.parse(customFields.fieldValues)
        : customFields.fieldValues
      : {};

    if (formName === "workclearancechecklist") {
      // Add createdBy name and createdOn date to the form data
      if (pmChecklistDetails) {
        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Engineering signature (from PM checklist creator)
        formData.engineeringSignature = pmChecklistDetails.createdBy?.name || "";
        formData.engineeringDate = pmChecklistDetails.createdOn
          ? formatInTimeZone(
              new Date(pmChecklistDetails.createdOn),
              userTimeZone,
              "d MMM yyyy"
            )
          : "";

        // Set the close date
        formData.acceptedByName = pmChecklistDetails.updatedBy?.name || pmChecklistDetails.createdBy?.name || "";
        formData.acceptedByDate = pmChecklistDetails.completedOn
          ? formatInTimeZone(
              new Date(pmChecklistDetails.completedOn),
              userTimeZone,
              "d MMM yyyy"
            )
          : "";
      }

      const mappedProps = mapDynamicFieldsToWorkClearanceChecklist(formData);
      htmlContent = getWorkClearanceChecklistHtml(mappedProps);
      documentTitle = "Work Clearance Checklist";
    } else if (formName === "engineeringplantservicereport") {
      // Add createdBy name and createdOn date to the form data for Engineering Plant Service Report
      if (pmChecklistDetails) {
        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Engineering signature (from PM checklist creator)
        formData.engineeringName = pmChecklistDetails.createdBy?.name || "";
        formData.engineeringDate = pmChecklistDetails.createdOn
          ? formatInTimeZone(
              new Date(pmChecklistDetails.createdOn),
              userTimeZone,
              "d MMM yyyy"
            )
          : "";
      }

      const mappedProps = mapDynamicFieldsToEngineeringPlantServiceReport(formData);
      htmlContent = getEngineeringPlantServiceReportHtml(mappedProps);
      documentTitle = "Engineering Plant Service Report";
    } else if (formName === "requisitionformforrepairsandmaintenanceworks") {
      // Add createdBy name and createdOn date to the form data for Requisition Form
      if (pmChecklistDetails) {
        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Set requestedBy from PM checklist creator
        formData.requestedBy = pmChecklistDetails.createdBy?.name || "";
        
        // Set dateTimeRequest from PM checklist creation date
        formData.dateTimeRequest = pmChecklistDetails.createdOn
          ? formatInTimeZone(
              new Date(pmChecklistDetails.createdOn),
              userTimeZone,
              "dd/MM/yyyy HH:mm"
            )
          : "";
          
        // Set dateTimeStart from created on of PM checklist
        formData.dateTimeStart = pmChecklistDetails.createdOn
          ? formatInTimeZone(
              new Date(pmChecklistDetails.createdOn),
              userTimeZone,
              "dd/MM/yyyy HH:mm"
            )
          : "";
          
        // Set machine and area if available
        formData.machine = pmChecklistDetails?.asset?.name || "";
        formData.area = pmChecklistDetails?.asset?.location?.name || "";

        formData.dateTimeCompleted = pmChecklistDetails.completedOn
          ? formatInTimeZone(
              new Date(pmChecklistDetails.completedOn),
              userTimeZone,
              "dd/MM/yyyy HH:mm"
            )
          : "";

        formData.verifiedBy = pmChecklistDetails.updatedBy?.name || pmChecklistDetails.createdBy?.name || "";
        formData.repairsServiceDoneBy = pmChecklistDetails.assignedUsers?.map((user) => user.name)?.join(", ") || "";
      }

      const mappedProps = mapDynamicFieldsToRequisitionForm(formData);
      htmlContent = getRequisitionFormHtml(mappedProps);
      documentTitle = "Requisition Form for Repairs and Maintenance Works";
    } else {
      alert("PDF export is only available for specific forms.");
      return;
    }

    // Open a new window
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      console.error("Failed to open new window. Pop-up might be blocked.");
      alert(
        "Failed to open PDF. Please check if pop-ups are blocked in your browser."
      );
      return;
    }

    try {
      // Create a blob with the HTML content
      const blob = new Blob([htmlContent], { type: "text/html" });

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Navigate to the blob URL
      newWindow.location.href = url;

      // Clean up the URL object when the window is closed
      newWindow.addEventListener("beforeunload", () => {
        URL.revokeObjectURL(url);
      });

      // Trigger print dialog after content is loaded
      newWindow.addEventListener("load", () => {
        setTimeout(() => {
          newWindow.print();
        }, 500);
      });
    } catch (error) {
      console.error("Error creating PDF content:", error);
      newWindow.close();
      alert("An error occurred while creating the PDF content.");
    }
  };

  // Only show the Export PDF button for supported forms
  const formName = tabName.toLowerCase().replace(/\s+/g, "");
  const supportedForms = [
    "workclearancechecklist",
    "engineeringplantservicereport",
    "requisitionformforrepairsandmaintenanceworks",
  ];

  if (!supportedForms.includes(formName)) {
    return null;
  }

  return (
    <AppButton
      onClick={handleExportPdf}
      icon={<FileDown className="h-4 w-4" />}
      size="sm"
      variant="outline"
    >
      Export PDF
    </AppButton>
  );
} 