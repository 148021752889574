import { cn } from "@/lib/utils";

interface CalibrationStatusCardProps {
  title: string;
  value: number;
  total: number;
  className?: string;
}

export function CalibrationStatusCard({
  title,
  value,
  total,
  className,
}: CalibrationStatusCardProps) {
  const percentage = total > 0 ? Math.round((value / total) * 100) : 0;

  return (
    <div
      className={cn(
        "p-4 rounded-lg border shadow-sm flex flex-col",
        className
      )}
    >
      <div className="text-lg font-semibold">{title}</div>
      <div className="mt-2 flex justify-between items-center">
        <div className="text-2xl font-bold">{value}</div>
        <div className="text-sm text-gray-500">{percentage}%</div>
      </div>
      <div className="mt-2 text-sm text-gray-500">
        {value} out of {total} equipment
      </div>
    </div>
  );
} 