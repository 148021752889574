import { ChevronLeft } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
} from "../../../components/ui/sidebar";
import AppButton from "../../../components/AppButton";
import {
  useWoPriorityEnabled,
  useWoTradeEnabled,
} from "../../../hooks/useFeatures";
import { useAppSelector } from "../../../redux/store";
import { NavigationMenuItem } from "../../../components/navigation/NavigationMenuItem";
import {
  Users,
  FileBox,
  Clock,
  FileInput,
  KanbanSquare,
  PanelTop,
  FileCheck2,
  FormInput,
} from "lucide-react";
import useAccountType, {
  COMPANY_ADMIN_ACC_TYPE,
  SUPER_ADMIN_ACC_TYPE,
} from "../../../hooks/useAccountType";

export default function SettingsSidebar() {
  const nav = useNavigate();
  const { pathname } = useLocation();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const woPriorityEnabled = useWoPriorityEnabled({
    projectId: activeProj?.id?.toString(),
  });
  const woTradeEnabled = useWoTradeEnabled({
    projectId: activeProj?.id?.toString(),
  });
  
  const { hasAccess: isCompanyAdmin } = useAccountType({
    accType: COMPANY_ADMIN_ACC_TYPE,
  });
  
  const { hasAccess: isSuperAdmin } = useAccountType({
    accType: SUPER_ADMIN_ACC_TYPE,
  });

  const isActive = (path: string) => pathname === path;
  
  // User has company admin access if they're either a company admin or super admin
  const hasCompanyAdminAccess = isCompanyAdmin || isSuperAdmin;
  // Only super admins have super admin access
  const hasSuperAdminAccess = isSuperAdmin;

  return (
    <Sidebar className="border-r border-slate-200 bg-white fixed right-0 w-[274px]">
      <SidebarHeader>
        <div className="flex gap-4 items-center">
          <AppButton
            icon={<ChevronLeft className="h-4 w-4 text-white" />}
            variant="ghost"
            className="hover:bg-sidebar-accent"
            onClick={() => {
              nav("/home");
            }}
          />
          <h1 className="text-lg font-semibold">Settings</h1>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel className="text-xs font-medium px-3 mb-2">
            General Settings
          </SidebarGroupLabel>
          <SidebarMenu>
            {
              hasCompanyAdminAccess && (
                <NavigationMenuItem
                  to="/home/settings"
                  icon={<Users className="size-4" />}
                  label="User Accounts"
                  isActive={isActive("/home/settings")}
                />
              )
            }
            {hasSuperAdminAccess && (
              <NavigationMenuItem
                to="/home/settings/data-import"
                icon={<FileInput className="size-4" />}
                label="Data Import"
                isActive={isActive("/home/settings/data-import")}
              />
            )}
          </SidebarMenu>
        </SidebarGroup>

        {hasCompanyAdminAccess && (
          <>
            <SidebarGroup className="mt-6">
              <SidebarGroupLabel className="text-xs font-medium px-3 mb-2">
                Work Request Settings
              </SidebarGroupLabel>
              <SidebarMenu>
                <NavigationMenuItem
                  to="/home/settings/work-request-settings"
                  icon={<KanbanSquare className="size-4" />}
                  label="Required Fields Settings"
                  isActive={isActive("/home/settings/work-request-settings")}
                />
              </SidebarMenu>
            </SidebarGroup>

            <SidebarGroup className="mt-6">
              <SidebarGroupLabel className="text-xs font-medium px-3 mb-2">
                Work Order Settings
              </SidebarGroupLabel>
              <SidebarMenu>
                <NavigationMenuItem
                  to="/home/settings/work-order-type"
                  icon={<FileBox className="size-4" />}
                  label="Work Order Type"
                  isActive={isActive("/home/settings/work-order-type")}
                />
                <NavigationMenuItem
                  to="/home/settings/man-hour-settings"
                  icon={<Clock className="size-4" />}
                  label="Man Hours"
                  isActive={isActive("/home/settings/man-hour-settings")}
                />
                <NavigationMenuItem
                  to="/home/settings/work-order/required-fields"
                  icon={<FormInput className="size-4" />}
                  label="Required Fields Settings"
                  isActive={isActive("/home/settings/work-order/required-fields")}
                />
                {woPriorityEnabled && (
                  <NavigationMenuItem
                    to="/home/settings/work-order-priority"
                    icon={<PanelTop className="size-4" />}
                    label="Work Order Priority"
                    isActive={isActive("/home/settings/work-order-priority")}
                  />
                )}
                {woTradeEnabled && (
                  <NavigationMenuItem
                    to="/home/settings/work-order-trade"
                    icon={<FileCheck2 className="size-4" />}
                    label="Work Order Trade"
                    isActive={isActive("/home/settings/work-order-trade")}
                  />
                )}
              </SidebarMenu>
            </SidebarGroup>
          </>
        )}
      </SidebarContent>
    </Sidebar>
  );
}
