export interface EngineeringPlantServiceReportProps {
  location: string;
  requestDate: string;
  timeOfDay: 'Morning' | 'Noon' | 'Night';
  requestBy: string;
  checkInTime: string;
  checkOutTime: string;
  affectProduction: boolean;
  productName: string;
  hasAllergen: boolean;
  toolsCleaning: boolean;
  toolsType: {
    normal: boolean;
    syrupFillingRoom: boolean;
    pureeRoom: boolean;
  };
  problemDescription: string;
  problemType: {
    setting: boolean;
    human: boolean;
    partsMaterial: boolean;
    machine: boolean;
    control: boolean;
  };
  solution: string;
  repairType: {
    fixRepair: boolean;
    temporaryRepair: boolean;
  };
  temporaryRepairRemarks: string;
  temporaryRepairFollowUp: string;
  followUpBy: string;
  closeDate: string;
  sparePartsRemarks: string;
  clearanceAreaCondition: {
    tools: boolean;
    spareParts: boolean;
    clearanceForm: boolean;
    allergenToolsCleaning: boolean;
  };
  engineering: {
    name: string;
    date: string;
  };
  acceptedBy: {
    name: string;
    date: string;
  };
}

export function getDefaultEngineeringPlantServiceReportProps(): EngineeringPlantServiceReportProps {
  return {
    location: 'Filling Section - Machine #3',
    requestDate: new Date().toLocaleDateString('en-GB'),
    timeOfDay: 'Morning',
    requestBy: 'John Smith',
    checkInTime: '09:30',
    checkOutTime: '11:45',
    affectProduction: true,
    productName: 'Vanilla Syrup',
    hasAllergen: false,
    toolsCleaning: true,
    toolsType: {
      normal: true,
      syrupFillingRoom: false,
      pureeRoom: false,
    },
    problemDescription: 'Filling nozzle misalignment causing irregular fill levels.',
    problemType: {
      setting: true,
      human: false,
      partsMaterial: false,
      machine: true,
      control: false,
    },
    solution: 'Adjusted nozzle alignment and calibrated fill sensors.',
    repairType: {
      fixRepair: true,
      temporaryRepair: false,
    },
    temporaryRepairRemarks: '',
    temporaryRepairFollowUp: '',
    followUpBy: '',
    closeDate: '',
    sparePartsRemarks: 'Replaced worn O-rings on nozzle assembly.',
    clearanceAreaCondition: {
      tools: true,
      spareParts: true,
      clearanceForm: true,
      allergenToolsCleaning: false,
    },
    engineering: {
      name: 'David Lee',
      date: new Date().toLocaleDateString('en-GB'),
    },
    acceptedBy: {
      name: 'Sarah Chen',
      date: new Date().toLocaleDateString('en-GB'),
    },
  };
}

export const getEngineeringPlantServiceReportHtml = (props: EngineeringPlantServiceReportProps = getDefaultEngineeringPlantServiceReportProps()) => {
  return /*html*/`
  <div style="font-family: Arial, sans-serif; width: 100%; max-width: 800px; margin: 0 auto;">
    <!-- Header Section -->
    <table style="width: 100%; border-collapse: collapse; border: 1px solid #000;">
      <tr>
        <td style="width: 120px; padding: 8px; border-right: 1px solid #000; text-align: center; vertical-align: middle;">
        <img src="https://storage.googleapis.com/cerev-prod.appspot.com/public/monin-asia-logo.png" style="width: 160px; height: 90px;" />
        </td>
        <td style="padding: 8px; vertical-align: middle; font-size: 22px; text-align: center; font-weight: bold;">
          <div style="font-weight: bold;">Engineering Plant Service Report</div>
        </td>
        <td style="width: 150px; padding: 4px; border-left: 1px solid #000; font-size: 11px;">
          <div><span style="font-weight: bold;">Doc No:</span> <span style="color: #444;">MA / F215</span></div>
          <div><span style="font-weight: bold;">Rev No:</span> <span style="color: #444;">2</span></div>
          <div><span style="font-weight: bold;">Date:</span> <span style="color: #444;">29/07/2024</span></div>
          <div><span style="font-weight: bold;">Page:</span> <span style="color: #444;">1 of 2</span></div>
        </td>
      </tr>
    </table>

    <!-- Form Content -->
    <div style="padding: 10px;">
      <!-- Location and Date Row -->
      <div style="display: flex; margin-bottom: 8px;">
        <div style="flex: 1;">
          <div style="margin-bottom: 6px;">
            <div><span style="font-weight: bold; font-size: 13px;">1. Location: Section and Machine:</span> <span style="color: #444;">${props.location}</span></div>
          </div>
          <div style="margin-bottom: 6px; display: flex;">
            <div><span style="font-weight: bold; font-size: 13px;">2. Date:</span> <span style="color: #444;">${props.requestDate}</span></div>
            <div style="display: flex; gap: 8px; margin-left: 15px;">
              <span style="font-weight: bold; font-size: 12px;">Morning</span> <input type="checkbox" style="vertical-align: middle;" ${props.timeOfDay === 'Morning' ? 'checked' : ''}>
              <span style="font-weight: bold; font-size: 12px;">Noon</span> <input type="checkbox" style="vertical-align: middle;" ${props.timeOfDay === 'Noon' ? 'checked' : ''}>
              <span style="font-weight: bold; font-size: 12px;">Night</span> <input type="checkbox" style="vertical-align: middle;" ${props.timeOfDay === 'Night' ? 'checked' : ''}>
            </div>
          </div>
          <div style="margin-bottom: 6px;">
            <div><span style="font-weight: bold; font-size: 13px;">3. Request/Call By:</span> <span style="color: #444;">${props.requestBy}</span></div>
          </div>
          <div style="margin-bottom: 6px; display: flex;">
            <div style="flex: 1;"><span style="font-weight: bold; font-size: 13px;">4. Check In Time:</span> <span style="color: #444;">${props.checkInTime}</span></div>
            <div style="flex: 1;"><span style="font-weight: bold; font-size: 13px;">5. Check Out Time:</span> <span style="color: #444;">${props.checkOutTime}</span></div>
          </div>
          <div style="margin-bottom: 6px;">
            <div><span style="font-weight: bold; font-size: 13px;">6. Affect Production:</span> 
              <span style="font-weight: bold; font-size: 12px;">Yes</span> <input type="checkbox" style="vertical-align: middle;" ${props.affectProduction ? 'checked' : ''}>
              <span style="font-weight: bold; font-size: 12px;">No</span> <input type="checkbox" style="vertical-align: middle;" ${!props.affectProduction ? 'checked' : ''}></div>
          </div>
          <div style="margin-bottom: 6px;">
            <div><span style="font-weight: bold; font-size: 13px;">7. Product Name:</span> <span style="color: #444;">${props.productName}</span> 
              <span style="font-weight: bold; margin-left: 8px; font-size: 12px;">Allergen:</span> 
              <span style="font-weight: bold; font-size: 12px;">Yes</span> <input type="checkbox" style="vertical-align: middle;" ${props.hasAllergen ? 'checked' : ''}>
              <span style="font-weight: bold; font-size: 12px;">No</span> <input type="checkbox" style="vertical-align: middle;" ${!props.hasAllergen ? 'checked' : ''}>
              <span style="font-weight: bold; margin-left: 8px; font-size: 12px;">Tools Cleaning</span> <input type="checkbox" style="vertical-align: middle;" ${props.toolsCleaning ? 'checked' : ''}></div>
          </div>
          <div style="margin-bottom: 6px;">
            <div><span style="font-weight: bold; font-size: 13px;">8. Tools Type:</span> 
              <span style="font-weight: bold; font-size: 12px;">Normal Tools</span> <input type="checkbox" style="vertical-align: middle;" ${props.toolsType.normal ? 'checked' : ''}>
              <span style="font-weight: bold; font-size: 12px;">Syrup Filling Room/Process Room Tools Box</span> <input type="checkbox" style="vertical-align: middle;" ${props.toolsType.syrupFillingRoom ? 'checked' : ''}>
              <span style="font-weight: bold; font-size: 12px;">Puree Room Tools Box</span> <input type="checkbox" style="vertical-align: middle;" ${props.toolsType.pureeRoom ? 'checked' : ''}></div>
          </div>
        </div>
      </div>

      <!-- Problem Description Box -->
      <div style="border: 1px solid #000; margin-bottom: 8px; padding: 6px;">
        <div><span style="font-weight: bold; font-size: 13px;">6. Problem Description:</span> 
          <span style="font-weight: bold; margin-left: 8px; font-size: 12px;">7. Problem Type:</span>
          <span style="font-weight: bold; font-size: 12px;">Setting</span> <input type="checkbox" style="vertical-align: middle;" ${props.problemType.setting ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Human</span> <input type="checkbox" style="vertical-align: middle;" ${props.problemType.human ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Parts/Material</span> <input type="checkbox" style="vertical-align: middle;" ${props.problemType.partsMaterial ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Machine</span> <input type="checkbox" style="vertical-align: middle;" ${props.problemType.machine ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Control</span> <input type="checkbox" style="vertical-align: middle;" ${props.problemType.control ? 'checked' : ''}></div>
        <div style="min-height: 40px; margin-top: 3px; color: #444; padding: 3px; font-size: 12px;">${props.problemDescription}</div>
      </div>

      <!-- Solution Box -->
      <div style="border: 1px solid #000; margin-bottom: 8px; padding: 6px;">
        <div><span style="font-weight: bold; font-size: 13px;">8. Solution:</span> 
          <span style="margin-left: 20px; font-weight: bold; font-size: 12px;">9. Repair Type:</span>
          <span style="font-weight: bold; font-size: 12px;">Fix Repair</span> <input type="checkbox" style="vertical-align: middle;" ${props.repairType.fixRepair ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Temporary Repair</span> <input type="checkbox" style="vertical-align: middle;" ${props.repairType.temporaryRepair ? 'checked' : ''}></span>
        </div>
        <div style="min-height: 40px; margin-top: 3px; color: #444; padding: 3px; font-size: 12px;">${props.solution}</div>
      </div>

      <!-- Temporary Repair Remarks Box -->
      <div style="border: 1px solid #000; margin-bottom: 8px; padding: 6px;">
        <div style="font-weight: bold; font-size: 13px;">10. Temporary Repair Remarks:</div>
        <div style="min-height: 30px; margin-top: 3px; color: #444; padding: 3px; font-size: 12px;">${props.temporaryRepairRemarks}</div>
      </div>

      <!-- Temporary Repair Follow Up Box -->
      <div style="border: 1px solid #000; margin-bottom: 8px; padding: 6px;">
        <div style="display: flex;">
          <div style="flex: 1;"><span style="font-weight: bold; font-size: 13px;">11. Temporary Repair Next Follow Up:</span> <span style="color: #444; font-size: 12px;">${props.temporaryRepairFollowUp}</span></div>
          <div style="flex: 1;"><span style="font-weight: bold; font-size: 12px;">Follow Up By:</span> <span style="color: #444; font-size: 12px;">${props.followUpBy}</span></div>
          <div style="flex: 1;"><span style="font-weight: bold; font-size: 12px;">Date for Close:</span> <span style="color: #444; font-size: 12px;">${props.closeDate}</span></div>
        </div>
        <div style="height: 20px;"></div>
      </div>

      <!-- Spare Parts Box -->
      <div style="border: 1px solid #000; margin-bottom: 8px; padding: 6px;">
        <div style="font-weight: bold; font-size: 13px;">12. Spare Parts / Remarks:</div>
        <div style="min-height: 30px; margin-top: 3px; color: #444; padding: 3px; font-size: 12px;">${props.sparePartsRemarks}</div>
      </div>

      <!-- Clearance Area Box -->
      <div style="border: 1px solid #000; margin-bottom: 8px; padding: 6px;">
        <div><span style="font-weight: bold; font-size: 13px;">14. Clearance Area Condition:</span> 
          <span style="font-weight: bold; font-size: 12px;">Tools</span> <input type="checkbox" style="vertical-align: middle;" ${props.clearanceAreaCondition.tools ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Spare Parts</span> <input type="checkbox" style="vertical-align: middle;" ${props.clearanceAreaCondition.spareParts ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Clearance Form</span> <input type="checkbox" style="vertical-align: middle;" ${props.clearanceAreaCondition.clearanceForm ? 'checked' : ''}>
          <span style="font-weight: bold; font-size: 12px;">Allergen Tools Cleaning</span> <input type="checkbox" style="vertical-align: middle;" ${props.clearanceAreaCondition.allergenToolsCleaning ? 'checked' : ''}></div>
        <div style="height: 20px;"></div>
      </div>

      <!-- Signature Section -->
      <div style="display: flex; margin-top: 15px;">
        <div style="flex: 1; text-align: center;">
          <div><span style="font-weight: bold; font-size: 12px;">Engineering:</span> <span style="color: #444; font-size: 12px;">${props.engineering.name}</span></div>
          <div style="margin-top: 5px;"><span style="font-weight: bold; font-size: 12px;">Date:</span> <span style="color: #444; font-size: 12px;">${props.engineering.date}</span></div>
        </div>
        <div style="flex: 1; text-align: center;">
          <div><span style="font-weight: bold; font-size: 12px;">Accepted By:</span> <span style="color: #444; font-size: 12px;">${props.acceptedBy.name}</span></div>
          <div style="margin-top: 5px;"><span style="font-weight: bold; font-size: 12px;">Date:</span> <span style="color: #444; font-size: 12px;">${props.acceptedBy.date}</span></div>
        </div>
      </div>

      <!-- Legend/Notes Section -->
      <div style="margin-top: 10px; font-size: 10px; border-top: 1px solid #ddd; padding-top: 5px;">
        <div style="font-weight: bold; margin-bottom: 2px;">Legend:</div>
        <div>*1. Setting/adjustment of machine. 2.Human=problem create by human (wrong operation)</div>
        <div>3.Parts/Material = Raw material problem (bottle, carton etc) 4.Machine=Mechanical problem</div>
        <div>5.Control = PLC, sensor, electrical or electronic faults.</div>
      </div>
    </div>
  </div>

  <!-- PAGE BREAK -->
  <div style="page-break-before: always;"></div>

  <!-- SECOND PAGE - SOP -->
  <div style="font-family: Arial, sans-serif; width: 100%; max-width: 800px; margin: 0 auto;">
    <!-- Header Section -->
    <table style="width: 100%; border-collapse: collapse; border: 1px solid #000;">
      <tr>
        <td style="width: 120px; padding: 8px; border-right: 1px solid #000; text-align: center; vertical-align: middle;">
        <img src="https://storage.googleapis.com/cerev-prod.appspot.com/public/monin-asia-logo.png" style="width: 160px; height: 90px;" />
        </td>
        <td style="padding: 8px; vertical-align: middle; font-size: 22px; text-align: center; font-weight: bold;">
          <div style="font-weight: bold;">Engineering Plant Service Report</div>
        </td>
        <td style="width: 150px; padding: 4px; border-left: 1px solid #000; font-size: 11px;">
        <div><span style="font-weight: bold;">Doc No:</span> <span style="color: #444;">MA / F215</span></div>
        <div><span style="font-weight: bold;">Rev No:</span> <span style="color: #444;">2</span></div>
        <div><span style="font-weight: bold;">Date:</span> <span style="color: #444;">29/07/2024</span></div>
          <div><span style="font-weight: bold;">Page:</span> <span style="color: #444;">2 of 2</span></div>
        </td>
      </tr>
    </table>

    <!-- SOP Content -->
    <div style="padding: 10px;">
      <!-- Requirements Section -->
      <div style="margin-bottom: 10px;">
        <h1 style="font-size: 16px; color: #333; margin-bottom: 6px; border-bottom: 2px solid #333; padding-bottom: 3px;">Requirements</h1>
        <p style="font-size: 12px; color: #444; margin-top: 4px;">The form header needs to follow the format shown above and include all the required information.</p>
      </div>

      <!-- SOP Title -->
      <div style="margin-bottom: 10px;">
        <h1 style="font-size: 16px; color: #333; margin-bottom: 6px; border-bottom: 2px solid #333; padding-bottom: 3px;">Standard Operating Procedure (SOP) for Filling Out the Engineering Plant Service Report Form</h1>
      </div>

      <!-- Purpose Section -->
      <div style="margin-bottom: 8px;">
        <h2 style="font-size: 14px; color: #333; margin-bottom: 4px; margin-top: 6px;">1. Purpose</h2>
        <p style="margin-left: 10px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px;">To establish a standardized method for documenting engineering service activities, ensuring proper recording of machine malfunctions, repairs, and maintenance actions in the plant.</p>
      </div>

      <!-- Scope Section -->
      <div style="margin-bottom: 8px;">
        <h2 style="font-size: 14px; color: #333; margin-bottom: 4px; margin-top: 6px;">2. Scope</h2>
        <p style="margin-left: 10px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px;">This SOP applies to all engineering personnel responsible for servicing and maintaining machines at the plant.</p>
      </div>

      <!-- Responsibilities Section -->
      <div style="margin-bottom: 8px;">
        <h2 style="font-size: 14px; color: #333; margin-bottom: 4px; margin-top: 6px;">3. Responsibilities*</h2>
        <ul style="margin-left: 20px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px; padding-left: 15px;">
          <li><strong>Engineering Team:</strong> Complete the form accurately and promptly.</li>
          <li><strong>Supervisor/Manager:</strong> Review and approve the report.</li>
          <li><strong>Operators:</strong> Provide necessary information regarding machine issues.</li>
          <li>Any downtime exceeding 40 minutes will be considered major.</li>
          <li>The job must be executed within 45 minutes.</li>
        </ul>
      </div>

      <!-- Procedure Section -->
      <div style="margin-bottom: 8px;">
        <h2 style="font-size: 14px; color: #333; margin-bottom: 4px; margin-top: 6px;">4. Procedure</h2>
        
        <!-- Filling Out the Report -->
        <div style="margin-left: 10px; margin-bottom: 6px;">
          <h3 style="font-size: 13px; color: #333; margin-bottom: 4px; margin-top: 4px;">4.1 Filling Out the Report</h3>
          
          <ol style="margin-left: 15px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px; padding-left: 15px;">
            <li>
              <strong>Location: Section and Machine</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Specify the exact section and machine name where the issue occurred.</li>
                <li>Example: "Line 3 – Labeller."</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Date & Time</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Write the service date in <em>DD/MM/YY</em> format.</li>
                <li>Indicate the shift: Morning, Noon, or Night by checking the appropriate box.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Request/Call By</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Record the name of the person who reported the issue.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Check-In & Check-Out Time</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Enter the time the engineering team arrived and completed the service (in 24-hour format).</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Affect Production</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Mark <em>Yes</em> if the issue impacts production, otherwise mark <em>No</em>.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Product Name & Allergen*</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Specify the product affected (e.g., "Strawberry Puree").</li>
                <li>Tick <em>Yes</em> or <em>No</em> if allergens are involved.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Tools Used</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Check the appropriate box if specific tools were used.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Problem Description</strong>
            </li>
          </ol>
        </div>
        
        <!-- Problem Identification & Repair -->
        <div style="margin-left: 10px; margin-bottom: 6px;">
          <h3 style="font-size: 13px; color: #333; margin-bottom: 4px; margin-top: 4px;">4.3 Problem Identification & Repair*</h3>
          
          <ol style="margin-left: 15px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px; padding-left: 15px;">
            <li>
              The engineer identifies the problem under one of the following categories:
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li><strong>Setting:</strong> Machine adjustments needed.</li>
                <li><strong>Human:</strong> Errors caused by operators.</li>
                <li><strong>Parts/Material:</strong> Issues with raw materials or packaging components.</li>
                <li><strong>Machine:</strong> Mechanical failure.</li>
                <li><strong>Control:</strong> PLC, sensor, electrical, or electronic faults.</li>
              </ul>
            </li>
            <li style="margin-top: 4px;">Describe the issue (e.g., "Wet label cylinder malfunction").</li>
            <li style="margin-top: 4px;">Outline the solution provided (e.g., "Reset machine").</li>
            <li style="margin-top: 4px;">Mark the type of repair performed: <em>Fix Repair</em> or <em>Temporary Repair</em>.</li>
          </ol>
          
          <ol start="9" style="margin-left: 15px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px; padding-left: 15px;">
            <li style="margin-top: 4px;">
              <strong>Solution Provided</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Briefly describe the solution applied (e.g., "Reset machine").</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Repair Type</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Tick either <em>Fix Repair</em> (permanent) or <em>Temporary Repair</em> if the issue requires follow-up.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Temporary Repair Follow-Up</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Indicate the name of the person responsible for follow-up and a tentative date.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Spare Parts Used</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Mention any spare parts used (if applicable). If spare parts are unavailable, get a quotation and raise a PR for further action.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Clearance Area Condition</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>Tick the appropriate box for <em>Tools, Spare Parts, Clearance Form, or Allergen Tools Cleaning</em> to ensure workplace cleanliness and safety.</li>
              </ul>
            </li>
            
            <li style="margin-top: 4px;">
              <strong>Signatures & Approval</strong>
              <ul style="margin-left: 10px; margin-top: 2px; padding-left: 15px;">
                <li>The Engineering Team must sign and date the form.</li>
                <li>The <em>Supervisor/Manager</em> must sign under "Accepted By" and provide the approval date.</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <!-- Compliance Section -->
      <div style="margin-bottom: 10px;">
        <h2 style="font-size: 14px; color: #333; margin-bottom: 4px; margin-top: 6px;">5. Compliance & Record Keeping</h2>
        <ul style="margin-left: 20px; color: #444; font-size: 12px; margin-top: 2px; margin-bottom: 2px; padding-left: 15px;">
          <li>Forms must be <em>legible and accurate</em> to maintain proper documentation.</li>
          <li>Completed forms should be submitted to the engineering records department.</li>
          <li>Reports should be retained for <em>audit and reference purposes</em> as per company policy.</li>
        </ul>
      </div>
    </div>
  </div>
  `
}

type WorkOrderFormData = Record<string, any>;

// Helper function to safely get values from form data
const getValue = (formData: WorkOrderFormData, fieldId: string): string => {
  if (formData && fieldId in formData) {
    const value = formData[fieldId];
    
    // Handle null/undefined
    if (value == null) {
      return "";
    }
    
    // Handle select field (object with id and label)
    if (typeof value === 'object' && 'id' in value && 'label' in value) {
      return value.id;
    }
    
    // Handle empty array
    if (Array.isArray(value) && value.length === 0) {
      return "";
    }
    
    return String(value);
  }
  return "";
};

// Helper function to get boolean value from form data
const getBooleanValue = (formData: WorkOrderFormData, fieldId: string): boolean => {
  const value = formData[fieldId];
  
  // Handle empty array (unchecked)
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  
  // Handle object with id/label (selected value)
  if (value && typeof value === 'object' && 'id' in value) {
    return value.id.toLowerCase() === 'yes';
  }
  
  return false;
};

// Function to map dynamic field values to EngineeringPlantServiceReport props
export const mapDynamicFieldsToEngineeringPlantServiceReport = (
  formData: WorkOrderFormData
): EngineeringPlantServiceReportProps => {
  return {
    // Basic Info
    location: getValue(formData, "location"),
    requestDate: getValue(formData, "requestDate"),
    timeOfDay: getValue(formData, "timeOfDay") as 'Morning' | 'Noon' | 'Night',
    requestBy: getValue(formData, "requestBy"),
    checkInTime: getValue(formData, "checkInTime"),
    checkOutTime: getValue(formData, "checkOutTime"),

    // Production Impact
    affectProduction: getBooleanValue(formData, "affectProduction"),
    productName: getValue(formData, "productName"),
    hasAllergen: getBooleanValue(formData, "hasAllergen"),
    toolsCleaning: getBooleanValue(formData, "toolsCleaning"),

    // Tools Type
    toolsType: {
      normal: getBooleanValue(formData, "toolsTypeNormal"),
      syrupFillingRoom: getBooleanValue(formData, "toolsTypeSyrupFillingRoom"),
      pureeRoom: getBooleanValue(formData, "toolsTypePureeRoom"),
    },

    // Problem Details
    problemDescription: getValue(formData, "problemDescription"),
    problemType: {
      setting: getBooleanValue(formData, "problemTypeSetting"),
      human: getBooleanValue(formData, "problemTypeHuman"),
      partsMaterial: getBooleanValue(formData, "problemTypePartsMaterial"),
      machine: getBooleanValue(formData, "problemTypeMachine"),
      control: getBooleanValue(formData, "problemTypeControl"),
    },

    // Solution and Repair
    solution: getValue(formData, "solution"),
    repairType: {
      fixRepair: getBooleanValue(formData, "repairTypeFixRepair"),
      temporaryRepair: getBooleanValue(formData, "repairTypeTemporaryRepair"),
    },

    // Follow-up Information
    temporaryRepairRemarks: getValue(formData, "temporaryRepairRemarks"),
    temporaryRepairFollowUp: getValue(formData, "temporaryRepairFollowUp"),
    followUpBy: getValue(formData, "followUpBy"),
    closeDate: getValue(formData, "closeDate"),
    sparePartsRemarks: getValue(formData, "sparePartsRemarks"),

    // Clearance Area Condition
    clearanceAreaCondition: {
      tools: getBooleanValue(formData, "clearanceAreaConditionTools"),
      spareParts: getBooleanValue(formData, "clearanceAreaConditionSpareParts"),
      clearanceForm: getBooleanValue(formData, "clearanceAreaConditionClearanceForm"),
      allergenToolsCleaning: getBooleanValue(formData, "clearanceAreaConditionAllergenToolsCleaning"),
    },

    // Signatures
    engineering: {
      name: getValue(formData, "engineeringName"),
      date: getValue(formData, "engineeringDate"),
    },
    acceptedBy: {
      name: getValue(formData, "acceptedByName"),
      date: getValue(formData, "acceptedByDate"),
    },
  };
};   