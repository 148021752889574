import AppButton from "@/components/AppButton";
import { Card, CardContent } from "@/components/ui/card";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { useQueryClient } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { trpc } from "../../../../../lib/trpc";
import { formatCurrency } from "../../../../../utils/formatters";
import AssetDepreciationChart from "./AssetDepreciationChart";
import DepreciationRateDialog from "./DepreciationRateDialog";
import DepreciationVsMaintenanceChart from "./DepreciationVsMaintenanceChart";
import InstallationDateDialog from "./InstallationDateDialog";
import MaintenanceCostChart from "./MaintenanceCostChart";
import PurchasePriceDialog from "./PurchasePriceDialog";
import useDate from "../../../../../hooks/useDate";

type Asset = RouterOutputs["assets"]["getOneAsset"];

interface AssetLifecycleTabProps {
  asset?: Asset;
}

export default function AssetLifecycleTab({ asset }: AssetLifecycleTabProps) {
  const [dialogOpen, setDialogOpen] = useState<
    "purchasePrice" | "depreciationRate" | "installationDate" | null
  >(null);
  const utils = trpc.useUtils();
  const qc = useQueryClient();
  const { formatDateToLocal } = useDate();

  const { mutate: updateAssetDepreciation } =
    trpc.assets.updateAssetDepreciation.useMutation({
      onSuccess: () => {
        setDialogOpen(null);
        utils.assets.invalidate();
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("asset"),
        });
      },
    });

  if (!asset) {
    return <div>No data available</div>;
  }

  const handleEdit = (
    field: "purchasePrice" | "depreciationRate" | "installationDate"
  ) => {
    setDialogOpen(field);
  };

  const handleUpdate = (field: string, value: number | Date) => {
    updateAssetDepreciation({
      assetId: asset.id,
      [field]: value,
    });
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        <Card>
          <CardContent className="flex justify-between items-center p-4">
            <div>
              <h3 className="font-semibold">Purchase Price</h3>
              <p>
                {asset.purchasePrice
                  ? formatCurrency(Number(asset.purchasePrice))
                  : "-"}
              </p>
            </div>
            <AppButton
              icon={<Pencil className="h-4 w-4" />}
              variant="ghost"
              onClick={() => handleEdit("purchasePrice")}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex justify-between items-center p-4">
            <div>
              <h3 className="font-semibold">Depreciation Rate</h3>
              <p>{Number(asset.depreciationRate) ?? "-"}</p>
            </div>
            <AppButton
              icon={<Pencil className="h-4 w-4" />}
              variant="ghost"
              onClick={() => handleEdit("depreciationRate")}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex justify-between items-center p-4">
            <div>
              <h3 className="font-semibold">Installation Date</h3>
              <p>
                {asset.installationDate
                  ? formatDateToLocal({
                      dateStr: asset.installationDate.toISOString(),
                      formatting: "d MMM yyyy",
                    })
                  : "N/A"}
              </p>
            </div>
            <AppButton
              icon={<Pencil className="h-4 w-4" />}
              variant="ghost"
              onClick={() => handleEdit("installationDate")}
            />
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <Card className="col-span-1">
          <AssetDepreciationChart assetId={asset.id} />
        </Card>
        <Card className="col-span-1">
          <MaintenanceCostChart assetId={asset.id} />
        </Card>
        <Card className="col-span-2">
          <DepreciationVsMaintenanceChart assetId={asset.id} />
        </Card>
      </div>

      <PurchasePriceDialog
        open={dialogOpen === "purchasePrice"}
        onOpenChange={() => setDialogOpen(null)}
        onUpdate={(value) => handleUpdate("purchasePrice", value)}
        currentValue={Number(asset.purchasePrice) ?? undefined}
      />
      <DepreciationRateDialog
        open={dialogOpen === "depreciationRate"}
        onOpenChange={() => setDialogOpen(null)}
        onUpdate={(value) => handleUpdate("depreciationRate", value)}
        currentValue={Number(asset.depreciationRate) ?? undefined}
      />
      <InstallationDateDialog
        open={dialogOpen === "installationDate"}
        onOpenChange={() => setDialogOpen(null)}
        onUpdate={(value) => handleUpdate("installationDate", value)}
        currentValue={asset.installationDate?.toISOString() ?? undefined}
      />
    </div>
  );
}
