import { z } from "zod";

export const getPublicAssetDetailInputSchema = z.object({
  assetId: z.number(),
  projectCode: z.string(),
});

export type GetPublicAssetDetailInput = z.infer<
  typeof getPublicAssetDetailInputSchema
>;

export const updateAssetDepreciationInputSchema = z.object({
  assetId: z.number(),
  purchasePrice: z.number().optional(),
  depreciationRate: z.number().optional(),
  installationDate: z.date().optional(),
});

export type UpdateAssetDepreciationInput = z.infer<
  typeof updateAssetDepreciationInputSchema
>;

export const createAssetSchema = z.object({
  name: z.string(),
  assetCode: z.string().optional(),
  description: z.string().optional(),
  assetTypeId: z.number(),
  installationDate: z.date().optional().nullable(),
  locationId: z.number().optional(),
  projectId: z.number(),
  createdById: z.number(),
  model: z.string().optional(),
  capacity: z.string().optional(),
  serialNo: z.string().optional(),
  inverterModel: z.string().optional(),
  motorCapacity: z.string().optional(),
  warrantyDate: z.date().optional().nullable(),
  callibrationDate: z.date().optional().nullable(),
  brand: z.string().optional(),
  photos: z
    .array(
      z.object({
        name: z.string(),
        gsPath: z.string(),
        url: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({
          id: z.number(),
        }),
      })
    )
    .optional()
    .default([]),
  additionalDocuments: z
    .array(
      z.object({
        name: z.string(),
        gsPath: z.string(),
        url: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({
          id: z.number(),
        }),
      })
    )
    .optional()
    .default([]),
});

export type CreateAssetInput = z.infer<typeof createAssetSchema>;

export const updateAssetSchema = z.object({
  assetId: z.number(),
  name: z.string(),
  assetCode: z.string().optional(),
  description: z.string().optional(),
  assetTypeId: z.number(),
  installationDate: z.date().optional().nullable(),
  locationId: z.number().optional(),
  projectId: z.number(),
  updatedById: z.number(),
  model: z.string().optional(),
  capacity: z.string().optional(),
  serialNo: z.string().optional(),
  inverterModel: z.string().optional(),
  motorCapacity: z.string().optional(),
  brand: z.string().optional(),
  warrantyDate: z.date().optional().nullable(),
  callibrationDate: z.date().optional().nullable(),
  photos: z
    .array(
      z.object({
        name: z.string(),
        gsPath: z.string(),
        url: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({
          id: z.number(),
        }),
      })
    )
    .optional()
    .default([]),
  additionalDocuments: z
    .array(
      z.object({
        name: z.string(),
        gsPath: z.string(),
        url: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({
          id: z.number(),
        }),
      })
    )
    .optional()
    .default([]),
});

export type UpdateAssetInput = z.infer<typeof updateAssetSchema>;

export const getOneAssetSchema = z.object({
  assetId: z.number(),
  projectId: z.number(),
});

export type GetOneAssetInput = z.infer<typeof getOneAssetSchema>;

export const createSubAssetSchema = z.object({
  parentAssetId: z.number(),
  name: z.string(),
  code: z.string().optional(),
  description: z.string().optional(),
  assetTypeId: z.number(),
  installationDate: z.date().optional().nullable(),
  locationId: z.number().optional(),
  projectId: z.number(),
  model: z.string().optional(),
  capacity: z.string().optional(),
  serialNo: z.string().optional(),
  inverterModel: z.string().optional(),
  motorCapacity: z.string().optional(),
  warrantyDate: z.date().optional().nullable(),
  callibrationDate: z.date().optional().nullable(),
  brand: z.string().optional(),
  photos: z
    .array(
      z.object({
        name: z.string(),
        gsPath: z.string(),
        url: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({
          id: z.number(),
        }),
      })
    )
    .optional()
    .default([]),
  additionalDocuments: z
    .array(
      z.object({
        name: z.string(),
        gsPath: z.string(),
        url: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({
          id: z.number(),
        }),
      })
    )
    .optional()
    .default([]),
});

export type CreateSubAssetInput = z.infer<typeof createSubAssetSchema>;

export const deleteAssetSchema = z.object({
  assetId: z.number(),
});

export type DeleteAssetInput = z.infer<typeof deleteAssetSchema>;

export const deleteAttachmentSchema = z.object({
  assetId: z.number(),
  attachmentId: z.number(),
});

export type DeleteAttachmentInput = z.infer<typeof deleteAttachmentSchema>;

export const deletePhotoSchema = z.object({
  assetId: z.number(),
  attachmentId: z.number(),
});

export type DeletePhotoInput = z.infer<typeof deletePhotoSchema>;

export const getFullAssetsInputSchema = z.object({
  projectId: z.number(),
  assetTypeIds: z.union([
    z.number(),
    z.array(z.number())
  ]).optional(),
  search: z.string().optional(),
});

export type GetFullAssetsInput = z.infer<typeof getFullAssetsInputSchema>;

export const getAssetPmInputSchema = z.object({
  assetId: z.string(),
  cursorId: z.string().optional(),
});

export type GetAssetPmInput = z.infer<typeof getAssetPmInputSchema>;

export const GetAssetWorkOrderInput = z.object({
  assetId: z.string(),
  cursorId: z.string().optional(),
});

export type GetAssetWorkOrderInput = z.infer<typeof GetAssetWorkOrderInput>;

export const getPaginatedAssetsInputSchema = z.object({
  projectId: z.number(),
  assetTypeIds: z.array(z.number()).optional(),
  locationIds: z.array(z.number()).optional(),
  search: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
});

export type GetPaginatedAssetsInput = z.infer<typeof getPaginatedAssetsInputSchema>;


export const GetAssetTypesInputSchema = z.object({
  companyId: z.string(), // Using string to handle both number and string inputs
  projectId: z.string().optional(), // Optional project ID filter
  search: z.string().optional(), // Optional search term
  page: z.number().optional(), // For pagination
  rowsPerPage: z.number().optional(), // For pagination
});

export type GetAssetTypesInput = z.infer<typeof GetAssetTypesInputSchema>; 
