import SelectableChart from "../../../../components/SelectableChart";
import { useAppSelector } from "../../../../redux/store";
import { ListOfLowStock } from "./ListOfLowStock/ListOfLowStock";
import { InventoryPieChart } from "./InventoryPieChart";
import { InventoryMetrics } from "./InventoryMetrics";

export default function InventoryBalanceAnalytics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);
  
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Inventory Overview</h2>
      <InventoryMetrics />
      <SelectableChart chartId="inventory-pie-chart">
        <InventoryPieChart />
      </SelectableChart>
      <ListOfLowStock />
    </div>
  );
} 