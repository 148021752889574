import { z } from "zod";

export const getLocationsSchema = z.object({
  projectId: z.string(),
  locationId: z.string().optional(),
  search: z.string().optional(),
});

export type GetLocationsInput = z.infer<typeof getLocationsSchema>;

const AttachmentInput = z.object({
  name: z.string(),
  url: z.string().url(),
  gsPath: z.string(),
  fileSizeMb: z.string(),
  underProject: z.object({
    id: z.union([z.string(), z.number()]).optional(),
  }).optional(),
  underProjectId: z.number(),
});

export const UpdateLocationInput = z.object({
  locationId: z.string(),
  name: z.string(),
  updatedById: z.string(),
  description: z.string().optional(),
  locationCode: z.string(),
  additionalDocuments: z.array(AttachmentInput).optional(),
  photos: z.array(AttachmentInput).optional(),
  parentId: z.number().optional(),
  projectId: z.number(),
});

export type UpdateLocationInput = z.infer<typeof UpdateLocationInput>;

