export interface RequisitionFormProps {
  dateTimeRequest: string;
  machine: string;
  requestedBy: string;
  area: string;
  description: string;
  causeOfProblems: string;
  beforeMaintenance: {
    properPowerSupplyIsolation: boolean;
    requesterAwareOfRepairs: boolean;
    toolsFreeFromContaminationHazards: boolean;
  };
  duringMaintenance: {
    replacementPartsFreeFromContaminationHazards: boolean;
    lubricationOilGreaseFoodGradeQuality: boolean;
    awareOfSafetyPrecaution: boolean;
    strictlyNoRepairsOfMovingRotatingParts: boolean;
  };
  afterMaintenance: {
    allToolsAndPartsRemovedFromMachineArea: boolean;
    repairedPartsFreeFromContaminationHazards: boolean;
    allFencingGuardingInPlace: boolean;
    handoverToUserInGoodCondition: boolean;
  };
  remarks: {
    beforeMaintenance: string;
    duringMaintenance: string;
    afterMaintenance: string;
  };
  spareParts: Array<{
    description: string;
    rkCode: string;
    qtyUsed: string;
    stkQty: string;
  }>;
  repairsServiceDoneBy: string;
  dateTimeStart: string;
  dateTimeCompleted: string;
  verifiedBy: string;
}

export function getDefaultRequisitionFormProps(): RequisitionFormProps {
  return {
    dateTimeRequest: new Date().toLocaleDateString('en-GB'),
    machine: '',
    requestedBy: '',
    area: '',
    description: '',
    causeOfProblems: '',
    beforeMaintenance: {
      properPowerSupplyIsolation: false,
      requesterAwareOfRepairs: false,
      toolsFreeFromContaminationHazards: false,
    },
    duringMaintenance: {
      replacementPartsFreeFromContaminationHazards: false,
      lubricationOilGreaseFoodGradeQuality: false,
      awareOfSafetyPrecaution: false,
      strictlyNoRepairsOfMovingRotatingParts: false,
    },
    afterMaintenance: {
      allToolsAndPartsRemovedFromMachineArea: false,
      repairedPartsFreeFromContaminationHazards: false,
      allFencingGuardingInPlace: false,
      handoverToUserInGoodCondition: false,
    },
    remarks: {
      beforeMaintenance: '',
      duringMaintenance: '',
      afterMaintenance: '',
    },
    spareParts: [
      { description: '', rkCode: '', qtyUsed: '', stkQty: '' },
      { description: '', rkCode: '', qtyUsed: '', stkQty: '' }
    ],
    repairsServiceDoneBy: '',
    dateTimeStart: '',
    dateTimeCompleted: '',
    verifiedBy: ''
  };
}

export const getRequisitionFormHtml = (props: RequisitionFormProps = getDefaultRequisitionFormProps()) => {
  return /*html*/`
  <div style="font-family: Arial, sans-serif; width: 100%; max-width: 800px; margin: 0 auto;">
    <!-- Header Section -->
    <div style="text-align: center; font-weight: bold; font-size: 14px;">
      MONIN ASIA KL SDN BHD
    </div>
    
    <table style="width: 100%; border-collapse: collapse; border: 1px solid #000;">
      <tr>
        <td style="width: 120px; padding: 8px; border-right: 1px solid #000; text-align: center; vertical-align: middle;">
          <img src="https://storage.googleapis.com/cerev-prod.appspot.com/public/monin-asia-logo.png" style="width: 160px; height: 90px;" />
        </td>
        <td style="padding: 8px; vertical-align: middle; font-size: 16px; text-align: center; font-weight: bold; border-right: 1px solid #000;">
          <div style="font-weight: bold;">Requisition Form for Repairs and Maintenance Works</div>
        </td>
        <td style="width: 150px; padding: 4px; font-size: 10px;">
          <div><span style="font-weight: bold;">Doc No:</span> <span style="color: #444;">MA / F061</span></div>
          <div><span style="font-weight: bold;">Rev No:</span> <span style="color: #444;">2</span></div>
          <div><span style="font-weight: bold;">Date:</span> <span style="color: #444;">01/03/2023</span></div>
          <div><span style="font-weight: bold;">Page:</span> <span style="color: #444;">1 of 2</span></div>
        </td>
      </tr>
    </table>

    <!-- Main Form Content -->
    <div style="border: 1px solid #000; border-top: none; padding: 0; ">
      <div style="text-align: right; font-weight: bold; font-size: 16px; color: red; padding: 8px;">MR</div>
      
      <div style="display: flex; margin: 8px;">
        <div style="flex: 1; font-weight: bold; font-size: 12px;">Date & time request: ${props.dateTimeRequest}</div>
        <div style="flex: 1; font-weight: bold; font-size: 12px;">Machine: ${props.machine}</div>
      </div>
      
      <div style="display: flex; margin: 8px;">
        <div style="flex: 1; font-weight: bold; font-size: 12px;">Requested by: ${props.requestedBy}</div>
        <div style="flex: 1; font-weight: bold; font-size: 12px;">Area: ${props.area}</div>
      </div>
      
      <div style="margin: 8px;">
        <div style="font-weight: bold; font-size: 12px;">Description of problem/requested item:</div>
        <div style="min-height: 20px; border-bottom: 1px dotted #999; font-size: 12px;">${props.description}</div>
      </div>
      
      <div style="margin: 8px;">
        <div style="font-weight: bold; font-size: 12px;">Cause of problems/description of repairs:</div>
        <div style="min-height: 40px; border-bottom: 1px dotted #999; font-size: 12px;">${props.causeOfProblems}</div>
      </div>
      
      <!-- Combined Maintenance and Spare Parts Table -->
      <table style="width: 100%; border-collapse: collapse; margin: 0;">
        <!-- Before Maintenance Section -->
        <tr style="background-color: #ffff99;">
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 70%; font-size: 12px;">Before maintenance</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 10%; text-align: center; font-size: 12px;">Y/N</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 20%; font-size: 12px;">Remarks</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Proper power supply isolation.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.beforeMaintenance.properPowerSupplyIsolation ? 'Y' : 'N'}</td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;" rowspan="3">${props.remarks.beforeMaintenance}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Requester aware of the repairs.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.beforeMaintenance.requesterAwareOfRepairs ? 'Y' : 'N'}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Tools are free from contamination hazards at all times.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.beforeMaintenance.toolsFreeFromContaminationHazards ? 'Y' : 'N'}</td>
        </tr>
        
        <!-- During Maintenance Section -->
        <tr style="background-color: #ffff99;">
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; font-size: 12px;">During maintenance</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; text-align: center; font-size: 12px;">Y/N</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; font-size: 12px;">Remarks</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Replacement parts are free from contamination hazards.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.duringMaintenance.replacementPartsFreeFromContaminationHazards ? 'Y' : 'N'}</td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;" rowspan="4">${props.remarks.duringMaintenance}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Lubrication oil/grease are Food Grade quality.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.duringMaintenance.lubricationOilGreaseFoodGradeQuality ? 'Y' : 'N'}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Aware of safety precaution at all times.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.duringMaintenance.awareOfSafetyPrecaution ? 'Y' : 'N'}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Strictly no repairs of moving/rotating parts.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.duringMaintenance.strictlyNoRepairsOfMovingRotatingParts ? 'Y' : 'N'}</td>
        </tr>
        
        <!-- After Maintenance Section -->
        <tr style="background-color: #ffff99;">
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; font-size: 12px;">After maintenance</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; text-align: center; font-size: 12px;">Y/N</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; font-size: 12px;">Remarks</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">All tools and parts already removed from machine area.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.afterMaintenance.allToolsAndPartsRemovedFromMachineArea ? 'Y' : 'N'}</td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;" rowspan="4">${props.remarks.afterMaintenance}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Repaired parts are free from contamination hazards.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.afterMaintenance.repairedPartsFreeFromContaminationHazards ? 'Y' : 'N'}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">All fencing/guarding in place.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.afterMaintenance.allFencingGuardingInPlace ? 'Y' : 'N'}</td>
        </tr>
        <tr>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">Handover to user in good condition.</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${props.afterMaintenance.handoverToUserInGoodCondition ? 'Y' : 'N'}</td>
        </tr>
      </table>
      
      <!-- Spare Parts Table -->
      <table style="width: 100%; border-collapse: collapse;">
        <tr style="background-color: #ffff99;">
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 40%; font-size: 12px;">Spare/replacement parts spec details (Desc)</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 15%; text-align: center; font-size: 12px;">Rk & Code</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 15%; text-align: center; font-size: 12px;">Qty used</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 15%; text-align: center; font-size: 12px;">Stk qty</td>
          <td style="padding: 4px; font-weight: bold; border: 1px solid #000; width: 15%; font-size: 12px;">Repairs/service done by:</td>
        </tr>
        ${props.spareParts.map(part => `
        <tr>
          <td style="padding: 4px; border: 1px solid #000; height: 25px; font-size: 12px;">${part.description}</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${part.rkCode}</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${part.qtyUsed}</td>
          <td style="padding: 4px; border: 1px solid #000; text-align: center; font-size: 12px;">${part.stkQty}</td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;">${props.repairsServiceDoneBy}</td>
        </tr>
        `).join('')}
        <tr>
          <td style="padding: 4px; border: 1px solid #000; height: 25px; font-size: 12px;"></td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;"></td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;"></td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;"></td>
          <td style="padding: 4px; border: 1px solid #000; font-size: 12px;"></td>
        </tr>
      </table>
      
      <!-- Signature Section -->
      <table style="width: 100%; border-collapse: collapse; border: 1px solid #000; border-top: none;">
        <tr>
          <td style="padding: 8px; border: 1px solid #000; border-top: none; padding-top: 20px;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 16px;">
              <div style="flex: 1;">
                <div style="font-weight: bold; font-size: 12px;">Date & time start: ${props.dateTimeStart}</div>
              </div>
              <div style="flex: 1;">
                <div style="font-weight: bold; font-size: 12px;">Date & time completed: ${props.dateTimeCompleted}</div>
              </div>
            </div>
            
            <div style="margin-top: 24px;">
              <div style="font-weight: bold; font-size: 12px;">Verified by: ${props.verifiedBy}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!-- PAGE BREAK -->
  <div style="page-break-before: always;"></div>

  <!-- SECOND PAGE - SOP -->
  <div style="font-family: Arial, sans-serif; width: 100%; max-width: 800px; margin: 0 auto;">
    <!-- Header Section -->
    <div style="text-align: center; font-weight: bold; font-size: 16px; margin-bottom: 10px;">
      MONIN ASIA KL SDN BHD
    </div>
    
    <table style="width: 100%; border-collapse: collapse; border: 1px solid #000; margin-bottom: 20px;">
      <tr>
        <td style="width: 120px; padding: 8px; border-right: 1px solid #000; text-align: center; vertical-align: middle;">
          <img src="https://storage.googleapis.com/cerev-prod.appspot.com/public/monin-asia-logo.png" style="width: 160px; height: 90px;" />
        </td>
        <td style="padding: 8px; vertical-align: middle; font-size: 18px; text-align: center; font-weight: bold; border-right: 1px solid #000;">
          <div style="font-weight: bold;">Requisition Form for Repairs and Maintenance Works</div>
        </td>
        <td style="width: 150px; padding: 4px; font-size: 11px;">
          <div><span style="font-weight: bold;">Doc No:</span> <span style="color: #444;">MA / F061</span></div>
          <div><span style="font-weight: bold;">Rev No:</span> <span style="color: #444;">2</span></div>
          <div><span style="font-weight: bold;">Date:</span> <span style="color: #444;">01/03/2023</span></div>
          <div><span style="font-weight: bold;">Page:</span> <span style="color: #444;">2 of 2</span></div>
        </td>
      </tr>
    </table>

    <!-- SOP Content -->
    <div style="padding: 0 20px;">
      <div style="margin-bottom: 20px;">
        <h2 style="font-size: 16px; margin-bottom: 10px;">1. Purpose</h2>
        <p style="margin: 0; line-height: 1.5;">
          This SOP outlines the steps for requesting, performing, and documenting repairs and maintenance 
          work to ensure the safe and efficient operation of machinery.
        </p>
      </div>

      <div style="margin-bottom: 20px;">
        <h2 style="font-size: 16px; margin-bottom: 10px;">2. Scope</h2>
        <p style="margin: 0; line-height: 1.5;">
          Applicable to all employees responsible for requesting, performing, and verifying 
          repairs/maintenance.
        </p>
      </div>

      <div style="margin-bottom: 20px;">
        <h2 style="font-size: 16px; margin-bottom: 10px;">3. Responsibilities</h2>
        <p style="margin: 0 0 10px 0; line-height: 1.5;">
          - Requester: Completes the form with accurate details of the issue.
        </p>
        <p style="margin: 0; line-height: 1.5;">
          - Maintenance Technician: Performs the repair/maintenance and documents all necessary details.
          (SOP FOR TO PM REFER TO: MA / MT / W01)
        </p>
      </div>

      <div style="margin-bottom: 20px;">
        <h2 style="font-size: 16px; margin-bottom: 10px;">4. Procedure</h2>
        
        <div style="margin-bottom: 15px;">
          <h3 style="font-size: 14px; margin-bottom: 10px;">4.1 Initiating a Repair Request</h3>
          <ol style="margin: 0; padding-left: 20px; line-height: 1.5;">
            <li>The form is filled out by the maintenance team, and the job will be assigned during the machine stop.</li>
            <li>The form must be linked to the safety permit to conduct the job.</li>
            <li>Fill in the Date & Time Request field.</li>
            <li>Enter the Machine Name/ID and Area where the issue is located.</li>
            <li>Write the Description of the Problem/Requested Item.</li>
            <li>Mention the Cause of the Problem/Description of Repairs.</li>
          </ol>
        </div>

        <div style="margin-bottom: 15px;">
          <h3 style="font-size: 14px; margin-bottom: 10px;">4.2 Before Maintenance</h3>
          <p style="margin: 0 0 10px 0; line-height: 1.5;">
            1. Power and Safety:
          </p>
          <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
            <li>Ensure proper power shutoff and isolation</li>
            <li>Inform all affected personnel of ongoing repairs</li>
          </ul>
          
          <p style="margin: 10px 0 10px 0; line-height: 1.5;">
            2. Hazard Prevention:
          </p>
          <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
            <li>Confirm all tools are free from contamination hazards.</li>
            <li>Ensure replacement parts are clean and food-grade</li>
          </ul>
        </div>

        <div style="margin-bottom: 15px;">
          <h3 style="font-size: 14px; margin-bottom: 10px;">4.3 During Maintenance</h3>
          <ol style="margin: 0; padding-left: 20px; line-height: 1.5;">
            <li>Verify replacement parts are free from contamination</li>
            <li>Use only approved lubricants/grease (e.g., Anglomoil PMG grade)</li>
            <li>Follow all safety precautions strictly.</li>
            <li>Do not perform repairs on moving/rotating parts.</li>
          </ol>
        </div>

        <div style="margin-bottom: 15px;">
          <h3 style="font-size: 14px; margin-bottom: 10px;">4.4 After Maintenance</h3>
          <ol style="margin: 0; padding-left: 20px; line-height: 1.5;">
            <li>Ensure all tools and spare parts are removed.</li>
            <li>Verify all repaired parts are contamination-free.</li>
            <li>Inspect and confirm fencing/guarding is in place.</li>
            <li>Handover the machine to the user in good condition.</li>
          </ol>
        </div>

        <div style="margin-bottom: 15px;">
          <h3 style="font-size: 14px; margin-bottom: 10px;">4.5 Documentation and Approval</h3>
          <ol style="margin: 0; padding-left: 20px; line-height: 1.5;">
            <li>Record the Spare/Replacement Parts Used.</li>
            <li>Enter the Start and Completion Time of the work.</li>
            <li>The assigned technician signs off the form.</li>
            <li>A supervisor or authorized personnel verifies and approves the completed work.</li>
          </ol>
        </div>

        <div style="margin-bottom: 15px;">
          <h3 style="font-size: 14px; margin-bottom: 10px;">5. Work Instructions for Filling the Requisition Form</h3>
          
          <div style="margin-bottom: 10px;">
            <h4 style="font-size: 13px; margin-bottom: 5px;">5.1 Header Information</h4>
            <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
              <li>Document Number & Revision: Used for version control.</li>
              <li>Date of Request: The date when the request is made.</li>
            </ul>
          </div>

          <div style="margin-bottom: 10px;">
            <h4 style="font-size: 13px; margin-bottom: 5px;">5.2 Machine & Problem Description</h4>
            <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
              <li>Machine Name/Area: Clearly specify the affected equipment and location.</li>
              <li>Description of the Problem: Briefly describe the issue (e.g., "Oil leakage, strange noise").</li>
              <li>Cause of Problem: If known, describe potential reasons (e.g., "Wear and tear of bearings").</li>
            </ul>
          </div>

          <div style="margin-bottom: 10px;">
            <h4 style="font-size: 13px; margin-bottom: 5px;">5.3 Checklist for Safety & Compliance</h4>
            <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
              <li>Mark Y (Yes) or N (No) for each safety criterion.</li>
              <li>Any remarks (e.g., "Used Anglomoil PMG grease") must be noted.</li>
            </ul>
          </div>

          <div style="margin-bottom: 10px;">
            <h4 style="font-size: 13px; margin-bottom: 5px;">5.4 Spare Parts & Service Details</h4>
            <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
              <li>Specify spare parts used (e.g., Anglomoil PMG grease).</li>
              <li>Record quantity and stock code.</li>
              <li>Technician performing the work signs the form.</li>
              <li>Mention any spare parts used (if applicable). If spare parts unavailable, get quotation raise PR for further action.</li>
            </ul>
          </div>

          <div style="margin-bottom: 10px;">
            <h4 style="font-size: 13px; margin-bottom: 5px;">5.5 Completion and Verification</h4>
            <ul style="margin: 0; padding-left: 20px; line-height: 1.5;">
              <li>Enter Start & End Time of the maintenance.</li>
              <li>Obtain a signature from the verifying authority.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
}

type WorkOrderFormData = Record<string, any>;

// Helper function to safely get values from form data
const getValue = (formData: WorkOrderFormData, fieldId: string): string => {
  if (formData && fieldId in formData) {
    const value = formData[fieldId];
    
    // Handle null/undefined
    if (value == null) {
      return "";
    }
    
    // Handle select field (object with id and label)
    if (typeof value === 'object' && 'id' in value && 'label' in value) {
      return value.label || "";
    }
    
    // Handle empty array
    if (Array.isArray(value) && value.length === 0) {
      return "";
    }
    
    return String(value);
  }
  return "";
};

// Helper function to get boolean value from form data
const getBooleanValue = (formData: WorkOrderFormData, fieldId: string): boolean => {
  const value = formData[fieldId];
  
  // Handle empty array (unchecked)
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  
  // Handle object with id/label (selected value)
  if (value && typeof value === 'object' && 'id' in value) {
    return value.id.toLowerCase() === 'yes';
  }
  
  return Boolean(value);
};

// Function to map dynamic field values to RequisitionForm props
export const mapDynamicFieldsToRequisitionForm = (
  formData: WorkOrderFormData
): RequisitionFormProps => {
  // Extract spare parts data if available
  const spareParts: Array<{
    description: string;
    rkCode: string;
    qtyUsed: string;
    stkQty: string;
  }> = [];
  
  if (formData.spareParts && Array.isArray(formData.spareParts)) {
    for (const part of formData.spareParts) {
      spareParts.push({
        description: getValue(part, "description"),
        rkCode: getValue(part, "rkCode"),
        qtyUsed: getValue(part, "qtyUsed"),
        stkQty: getValue(part, "stkQty")
      });
    }
  } else {
    // Add default empty rows if no spare parts data
    spareParts.push({ description: '', rkCode: '', qtyUsed: '', stkQty: '' });
    spareParts.push({ description: '', rkCode: '', qtyUsed: '', stkQty: '' });
  }

  return {
    dateTimeRequest: getValue(formData, "dateTimeRequest"),
    machine: getValue(formData, "machine"),
    requestedBy: getValue(formData, "requestedBy"),
    area: getValue(formData, "area"),
    description: getValue(formData, "description"),
    causeOfProblems: getValue(formData, "causeOfProblems"),
    
    beforeMaintenance: {
      properPowerSupplyIsolation: getBooleanValue(formData, "beforeMaintenanceProperPowerSupplyIsolation"),
      requesterAwareOfRepairs: getBooleanValue(formData, "beforeMaintenanceRequesterAwareOfRepairs"),
      toolsFreeFromContaminationHazards: getBooleanValue(formData, "beforeMaintenanceToolsFreeFromContaminationHazards"),
    },
    
    duringMaintenance: {
      replacementPartsFreeFromContaminationHazards: getBooleanValue(formData, "duringMaintenanceReplacementPartsFreeFromContaminationHazards"),
      lubricationOilGreaseFoodGradeQuality: getBooleanValue(formData, "duringMaintenanceLubricationOilGreaseFoodGradeQuality"),
      awareOfSafetyPrecaution: getBooleanValue(formData, "duringMaintenanceAwareOfSafetyPrecaution"),
      strictlyNoRepairsOfMovingRotatingParts: getBooleanValue(formData, "duringMaintenanceStrictlyNoRepairsOfMovingRotatingParts"),
    },
    
    afterMaintenance: {
      allToolsAndPartsRemovedFromMachineArea: getBooleanValue(formData, "afterMaintenanceAllToolsAndPartsRemovedFromMachineArea"),
      repairedPartsFreeFromContaminationHazards: getBooleanValue(formData, "afterMaintenanceRepairedPartsFreeFromContaminationHazards"),
      allFencingGuardingInPlace: getBooleanValue(formData, "afterMaintenanceAllFencingGuardingInPlace"),
      handoverToUserInGoodCondition: getBooleanValue(formData, "afterMaintenanceHandoverToUserInGoodCondition"),
    },
    
    remarks: {
      beforeMaintenance: getValue(formData, "remarksBeforeMaintenance"),
      duringMaintenance: getValue(formData, "remarksDuringMaintenance"),
      afterMaintenance: getValue(formData, "remarksAfterMaintenance"),
    },
    
    spareParts,
    repairsServiceDoneBy: getValue(formData, "repairsServiceDoneBy"),
    dateTimeStart: getValue(formData, "dateTimeStart"),
    dateTimeCompleted: getValue(formData, "dateTimeCompleted"),
    verifiedBy: getValue(formData, "verifiedBy")
  };
};
