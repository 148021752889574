import React from "react";
import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DataTable } from "@/components/react-table/DataTable";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { columns } from "./columns";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";

export function ListOfLowStock() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [globalFilter, setGlobalFilter] = React.useState("");

  const { data: inventoryList, isLoading } = trpc.inventory.getInventorySummary.useQuery(
    {
      projectId: project?.id ?? 0,
    },
    {
      enabled: !!project,
      select: (data) =>
        data.filter(
          (stock) => 
            stock.warningBalance !== null && 
            stock.balance <= (stock.warningBalance || 0)
        ),
    }
  );

  const table = useReactTable({
    data: inventoryList ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: Math.ceil((inventoryList?.length ?? 0) / pageSize),
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      globalFilter,
    },
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({
          pageIndex,
          pageSize,
        });
        setPageIndex(newState.pageIndex);
        setPageSize(newState.pageSize);
      } else {
        setPageIndex(updater.pageIndex);
        setPageSize(updater.pageSize);
      }
    },
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: false,
    globalFilterFn: "includesString",
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Low Inventory Levels</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center pb-4">
          <div className="relative w-full max-w-sm">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search inventory..."
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="w-full pl-8 focus-visible:ring-primary-900"
            />
          </div>
        </div>
        <DataTable
          table={table}
          totalCount={inventoryList?.length ?? 0}
          pageIdx={pageIndex}
          rowsPerPage={pageSize}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
} 