import { RouterOutputs } from "@cerev-cmms/trpc";
import { DynamicField as DynamicFieldType } from "@cerev-cmms/zod-types";
import { ChevronLeft, FileText, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import AppButton from "../../../components/AppButton";
import DynamicField from "../../../components/DynamicField/DynamicField";
import { trpc } from "../../../lib/trpc";
import { useAppSelector } from "../../../redux/store";

// Use a non-null assertion to handle the potential null value
type WorkOrderConfigOutput = NonNullable<RouterOutputs["workOrders"]["getConfiguration"]>;
type WorkOrderTab = WorkOrderConfigOutput["workOrderTabs"][number];
type WorkOrderCustomFieldsOutput = RouterOutputs["workOrders"]["getWorkOrderCustomFields"];

export default function DynamicWorkOrderFormDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useAppSelector((state) => state.root.activeProject);
  const tabId = searchParams.get("tabId");
  const workOrderId = searchParams.get("workOrderId");
  const [isLoadingFieldValues, setIsLoadingFieldValues] = useState(false);
  const nav = useNavigate();
  
  const methods = useForm({
    defaultValues: {
      dynamicFields: {}
    }
  });

  // Fetch work order configuration
  const { data: workOrderConfig, isLoading: isLoadingConfig } =
    trpc.workOrders.getConfiguration.useQuery(
      { projectId: project?.id ?? 0 },
      {
        enabled: !!project?.id,
      }
    );

  // Submit dynamic form mutation
  const { mutate: submitForm, isPending: isSubmitting } = 
    trpc.workOrders.submitDynamicWorkOrderForm.useMutation({
      onSuccess: () => {
        toast.success("Form submitted successfully");
        handleGoBack();
      },
      onError: (error) => {
        toast.error(`Error submitting form: ${error.message}`);
      }
    });

  // Query for getting custom fields
  const { refetch: fetchCustomFields } = 
    trpc.workOrders.getWorkOrderCustomFields.useQuery(
      { 
        workOrderId: Number(workOrderId || 0), 
        workOrderCustomTabId: Number(tabId || 0) 
      },
      {
        enabled: false, // Don't run automatically
      }
    );

  // Find the selected tab
  const selectedTab = workOrderConfig?.workOrderTabs.find(
    (tab) => tab.id === Number(tabId)
  );

  // Fetch existing field values when tab and work order are available
  useEffect(() => {
    if (workOrderId && tabId && project?.id) {
      setIsLoadingFieldValues(true);
      
      fetchCustomFields().then(result => {
        if (result.data) {
          // Set the form values with the retrieved field values
          methods.reset({
            dynamicFields: result.data.fieldValues
          });
        }
        setIsLoadingFieldValues(false);
      }).catch(error => {
        console.error("Error fetching field values:", error);
        toast.error("Failed to load existing form data");
        setIsLoadingFieldValues(false);
      });
    }
  }, [workOrderId, tabId, project?.id, fetchCustomFields, methods]);

  // Helper function to safely get fields from dynamicFieldSchema
  const getFields = (tab?: WorkOrderTab): DynamicFieldType[] => {
    if (!tab) return [];
    
    try {
      // Parse the JSON if it's a string, or use it directly if it's already an object
      const schema = typeof tab.dynamicFieldSchema === "string"
        ? JSON.parse(tab.dynamicFieldSchema) 
        : tab.dynamicFieldSchema;
      
      return schema?.fields || [];
    } catch (error) {
      console.error("Error parsing dynamicFieldSchema:", error);
      return [];
    }
  };

  const fields = getFields(selectedTab);

  const handleGoBack = () => {
    nav(-1);
  };

  const onSubmit = (data: any) => {
    if (!workOrderId || !tabId || !project?.id) {
      toast.error("Missing required information");
      return;
    }
    
    submitForm({
      workOrderId: Number(workOrderId),
      workOrderCustomTabId: Number(tabId),
      fieldValues: data.dynamicFields
    });
  };

  if (isLoadingConfig || isLoadingFieldValues) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (!selectedTab) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center p-8 text-center">
        <FileText className="h-16 w-16 text-gray-300 mb-4" />
        <h3 className="text-lg font-medium text-gray-700">
          Form Not Found
        </h3>
        <p className="text-sm text-gray-500 mt-2 max-w-md">
          The requested form could not be found. Please try again or contact your administrator.
        </p>
        <AppButton 
          className="mt-4"
          onClick={handleGoBack}
        >
          Go Back
        </AppButton>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex items-center mb-6">
        <button 
          onClick={handleGoBack}
          className="mr-3 p-1 rounded-full hover:bg-gray-100"
        >
          <ChevronLeft className="h-5 w-5 text-gray-500" />
        </button>
        <h2 className="text-xl font-semibold text-gray-800">
          {selectedTab.tabName}
        </h2>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
          <div className="bg-primary-100 border border-slate-300 rounded-lg p-4 mb-6">
            <p className="text-sm text-primary-900">
              Fill in the form fields below. Required fields are marked with an asterisk (*).
            </p>
          </div>

          {fields.map((field) => (
            <DynamicField
              key={field.id}
              field={field}
              control={methods.control}
              formState={methods.formState}
              showOptionalText={false}
            />
          ))}

          <div className="flex justify-end space-x-3 pt-6 border-t">
            <AppButton
              type="button"
              variant="outline"
              onClick={handleGoBack}
              disabled={isSubmitting}
            >
              Cancel
            </AppButton>
            <AppButton
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </AppButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
