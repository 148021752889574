import { useQuery } from "@tanstack/react-query";
import { addMonths, format, isBefore, isAfter, subMonths } from "date-fns";
import SelectableChart from "../../../../components/SelectableChart";
import { useAppSelector } from "../../../../redux/store";
import { ListOfCalibrationDue } from "./ListOfCalibrationDue/ListOfCalibrationDue";
import { CalibrationPieChart } from "./CalibrationPieChart";
import { CalibrationMetrics } from "./CalibrationMetrics";

export default function EquipmentCalibrationAnalytics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);
  
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Equipment Calibration Overview</h2>
      <CalibrationMetrics />
      <SelectableChart chartId="calibration-pie-chart">
        <CalibrationPieChart />
      </SelectableChart>
      <ListOfCalibrationDue />
    </div>
  );
}
