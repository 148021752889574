import { useSearchParams } from "react-router-dom";
import useDate from "../../../hooks/useDate";
import { trpc } from "../../../lib/trpc";
import AppItemInfo from "../../AppItemInfo";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import {
  Calendar,
  CalendarClock,
  Hash,
  Pencil,
} from "lucide-react";
import AppButton from "../../AppButton";
import { DRAWER_UPDATE_STATE, PM_CHECKLIST_FORM_DRAWER } from "../AppDrawer";
import PmChecklistEquipmentInfo from "../../pm/PmChecklistEquipmentInfo";
import DeletePmChecklistButton from "./components/DeletePmChecklistButton";
import { PlannedDateField } from "./components/PlannedDateField";
import PmFormatField, { PmFormatType } from "./components/PmFormatField";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import { useAppSelector } from "../../../redux/store";
import PmChecklistInfoTab from "./tabs/PmChecklistInfoTab/PmChecklistInfoTab";
import AdditionalFormsTab from "./tabs/AdditionalFormsTab/AdditionalFormsTab";

export default function PmChecklistDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pmChecklistId = searchParams.get("pmChecklistId");
  const activeProj = useAppSelector(state => state.root.activeProject);
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "pmChecklistTabIdx",
    defaultValue: "0",
  });

  const { data: pmChecklist, isLoading } = trpc.pm.getOnePmChecklist.useQuery(
    {
      pmChecklistId: Number(pmChecklistId),
    },
    {
      enabled: !!pmChecklistId,
    }
  );

  const { data: pmConfig } = trpc.pm.getPmChecklistConfiguration.useQuery({
    projectId: activeProj?.id ?? 0,
  }, {
    enabled: !!activeProj?.id,
  });


  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4 items-center">
        <h1 className="font-sans text-2xl font-bold">
          {pmChecklist?.pmTemplate.name ?? "PM Checklist"}
        </h1>
        <div className="flex gap-2">
          <AppButton
            label="Update"
            icon={<Pencil />}
            variant="outline"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", PM_CHECKLIST_FORM_DRAWER);
                p.set("drawerState", DRAWER_UPDATE_STATE);
                p.set("pmChecklistId", pmChecklistId ?? "");
                return p;
              });
            }}
          />
          <DeletePmChecklistButton pmChecklistId={Number(pmChecklistId)} />
        </div>
      </div>
      
      <div className="flex flex-col gap-2">
        <p className="text-gray-400">Status</p>
        <div className="flex">
          <div
            className="rounded-md py-2 px-10 mb-2 text-white font-bold"
            style={{ backgroundColor: `#${pmChecklist?.pmStatus?.colorHex}` }}
          >
            {pmChecklist?.pmStatus?.name}
          </div>
        </div>
      </div>

      <div className="col-span-2">
        <Tabs
          value={currentTabIdx}
          onValueChange={onTabChange}
          defaultValue="0"
        >
          <TabsList className="mb-4">
            <TabsTrigger value="0">
              <p>Info</p>
            </TabsTrigger>
            {pmConfig !== null ? (
              <TabsTrigger value="1">
                <p>Additional Forms</p>
              </TabsTrigger>
            ) : (
              <></>
            )}
          </TabsList>
          <TabsContent value="0">
            <PmChecklistInfoTab />
          </TabsContent>
          {pmConfig !== null ? (
            <TabsContent value="1">
              <AdditionalFormsTab />
            </TabsContent>
          ) : (
            <></>
          )}
        </Tabs>
      </div>
    </div>
  );
}
