import { useState } from "react";
import { ListRestart } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "../../../../ui/use-toast";
import { trpc } from "../../../../../lib/trpc";
import { useAppSelector } from "../../../../../redux/store";
import AppButton from "../../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../ui/dialog";
import { addDays } from "date-fns";
import { useForm, FormProvider } from "react-hook-form";
import { AppDateTimePickerForm } from "../../../../datetimePicker/AppDateTimePickerForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { createPmChecklistInputSchema, type CreatePmChecklistInput } from "@cerev-cmms/zod-types";

interface GeneratePmChecklistProps {
  pmTemplate: {
    id: number;
    asset: { id: number }[];
  };
}

export default function GeneratePmChecklist({ pmTemplate }: GeneratePmChecklistProps) {
  const [isOpen, setIsOpen] = useState(false);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { toast } = useToast();
  const qc = useQueryClient();
  const utils = trpc.useUtils();

  const form = useForm<CreatePmChecklistInput>({
    resolver: zodResolver(createPmChecklistInputSchema),
    defaultValues: {
      pmTemplateId: pmTemplate.id,
      projectId: activeProj?.id ?? 0,
      assetIds: pmTemplate.asset.map((asset) => asset.id),
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
    },
  });

  const { mutate: createPmChecklist, isPending: createPmChecklistIsPending } =
    trpc.pm.createPmChecklist.useMutation({
      onSuccess: () => {
        toast({
          title: "Success",
          description: "PM Checklist created successfully",
        });
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("pm-checklist");
          },
        });
        utils.pm.invalidate();
        setIsOpen(false);
        form.reset();
      },
    });

  const handleGenerate = (data: CreatePmChecklistInput) => {
    createPmChecklist(data);
  };

  return (
    <>
      <AppButton
        className="flex-grow"
        label="Generate"
        variant="outline"
        onClick={() => setIsOpen(true)}
        icon={<ListRestart />}
      />

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Generate PM Checklist</DialogTitle>
          </DialogHeader>
          
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleGenerate)} className="space-y-4">
              <div className="space-y-4 py-4">
                <AppDateTimePickerForm
                  name="startDate"
                  label="Start Date"
                  showTime={false}
                />
                
                <AppDateTimePickerForm
                  name="endDate"
                  label="End Date"
                  showTime={false}
                />
              </div>

              <DialogFooter>
                <AppButton
                  type="button"
                  label="Cancel"
                  variant="outline"
                  onClick={() => {
                    setIsOpen(false);
                    form.reset();
                  }}
                />
                <AppButton
                  type="submit"
                  label="Generate"
                  isLoading={createPmChecklistIsPending}
                />
              </DialogFooter>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
} 