import { useSearchParams } from "react-router-dom";
import useDate from "../../../../../hooks/useDate";
import AppItemInfo from "../../../../AppItemInfo";
import { Calendar, CalendarClock, Hash } from "lucide-react";
import PmChecklistEquipmentInfo from "../../../../pm/PmChecklistEquipmentInfo";
import { PlannedDateField } from "../../components/PlannedDateField";
import PmFormatField, { PmFormatType } from "../../components/PmFormatField";
import { RouterOutputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import { trpc } from "../../../../../lib/trpc";


export default function PmChecklistInfoTab() {
  const [searchParams] = useSearchParams();
  const pmChecklistId = searchParams.get("pmChecklistId");

  const { data: pmChecklist, isLoading } = trpc.pm.getOnePmChecklist.useQuery(
    {
      pmChecklistId: Number(pmChecklistId),
    },
    {
      enabled: !!pmChecklistId,
    }
  );
  const { formatDateToLocal, diffInDays } = useDate();

  const pmFormats = pmChecklist?.pmTemplate.pmFormat.sort((a, b) => a.order - b.order) ?? [];

  const getFormatValue = (format: any) => {
    switch (format.pmFormatType.name) {
      case "VARCHAR":
        return pmChecklist?.pmChecklistText?.find(
          (v) => v.pmFormatId === format.id
        )?.value;
      case "NUMBER":
        return pmChecklist?.pmChecklistNumber?.find(
          (v) => v.pmFormatId === format.id
        )?.value;
      case "BOOL":
        return pmChecklist?.pmChecklistBool?.find(
          (v) => v.pmFormatId === format.id
        )?.value;
      case "ATTACHMENT":
        return pmChecklist?.pmChecklistAttachment?.find(
          (v) => v.pmFormatId === format.id
        )?.attachments;
      case "SINGLE_OPTION":
        return pmChecklist?.singleOptionValues?.find(
          (v) => v.pmFormatId === format.id
        )?.selectedOption;
      case "MULTI_OPTION":
        return pmChecklist?.multiOptionValues?.find(
          (v) => v.pmFormatId === format.id
        )?.selectedOptions;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        <PmChecklistEquipmentInfo
          isMultipleEquipments={pmChecklist?.pmTemplate.pmTemplateType === 'MULTIPLE_EQUIPMENTS'}
          asset={pmChecklist?.asset}
          multipleAssets={pmChecklist?.multipleAssets}
        />
        <AppItemInfo
          label="Ref No"
          icon={<Hash className="text-primary-900" />}
          content={<p>{pmChecklist?.refNo ?? "-"}</p>}
        />
        <AppItemInfo
          label="Created"
          icon={<Calendar className="text-primary-900" />}
          content={
            <p>
              {formatDateToLocal({
                dateStr: pmChecklist?.createdOn.toISOString() ?? "",
                formatting: "d MMM yyyy",
              })}
            </p>
          }
        />
        <AppItemInfo
          label="Planned Date"
          icon={<Calendar className="text-primary-900" />}
          content={
            <PlannedDateField 
              pmChecklistId={pmChecklistId ?? ""}
              disabled={pmChecklist?.pmStatus?.name === "Close"}
            />
          }
        />
        <AppItemInfo
          label="Aging Days"
          icon={<CalendarClock className="text-primary-900" />}
          content={
            <p>
              {pmChecklist?.completedOn
                ? diffInDays({
                    startDateISO: pmChecklist?.createdOn.toISOString(),
                    endDateISO: pmChecklist?.completedOn.toISOString(),
                  })
                : "-"}
            </p>
          }
        />
      </div>
      <p className="font-sans text-xl font-bold my-4">Checklist</p>
      <div className="flex flex-col gap-4">
        {pmFormats.map((format) => (
          <PmFormatField
            key={format.id}
            type={format.pmFormatType.name as PmFormatType}
            name={format.name}
            value={getFormatValue(format)}
          />
        ))}
      </div>
    </div>
  );
} 