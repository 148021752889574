import { RouterOutputs } from '@cerev-cmms/trpc';
import { create } from "zustand";

import {
  GetLocationResponseDto,
  Location,
} from "../../api/model";
import { DateRange } from "react-day-picker";

type AssetType = RouterOutputs["assets"]["getAssetTypes"][number];

interface SelectAssetFilterState {
  assetTypes: AssetType[];
  filterLocation: Location | GetLocationResponseDto | null | undefined;
  dateRange: DateRange | null | undefined;
  search: string;
  setAssetTypes: (assetType: AssetType[]) => void;
  setFilterLocation: (
    loc: Location | GetLocationResponseDto | null | undefined
  ) => void;
  setDateRange: (dateRange: DateRange | undefined) => void;
  setSearch: (search: string) => void;
  getFilterCount: () => number;
  cleanUp: () => void;
}

export const useSelectAssetFilterStore = create<SelectAssetFilterState>(
  (set, get) => ({
    assetTypes: [],
    filterLocation: null,
    dateRange: null,
    search: "",
    setAssetTypes: (assetTypes) => set(() => ({ assetTypes })),
    setFilterLocation: (loc) => set(() => ({ filterLocation: loc })),
    setDateRange: (dateRange) => set(() => ({ dateRange })),
    setSearch: (search) => set(() => ({ search })),
    cleanUp: () =>
      set(() => ({
        filterLocation: null,
        dateRange: null,
        assetTypes: [],
        search: "",
      })),
    getFilterCount: () => {
      let filterCount = 0;
      if (get().filterLocation) filterCount++;
      if (get().dateRange) filterCount++;
      if (get().assetTypes.length > 0) filterCount++;
      return filterCount;
    },
  })
); 