import { Computer, MapPin, Upload } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../../components/ui/card";
import { useState } from "react";
import ImportLocationSheet from "./ImportLocationSheet";
import AppButton from "../../../../components/AppButton";
import ImportItem from "./components/ImportItem";
import ImportAssetSheet from "./ImportAssetSheet";
import AppTitle from "../../../HomeScreen/components/AppTitle";

export default function DataImportSettingsTab() {
  const [importLocationSheetOpen, setImportLocationSheetOpen] = useState(false);
  const [importAssetSheetOpen, setImportAssetSheetOpen] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-8 items-start">
        <AppTitle title="Data Import" />
      </div>
      <div className="grid grid-cols-2 gap-5">
        <>
          <ImportLocationSheet
            open={importLocationSheetOpen}
            setOpen={setImportLocationSheetOpen}
          />
          <ImportItem
            title="Import Location"
            description="Upload CSV files with a fixed template to bulk add your location of
              your project"
            onClick={() => {
              setImportLocationSheetOpen(true);
            }}
            icon={<MapPin className=" h-10 w-10 stroke-[1.5px] text-slate-500" />}
          />
        </>
        <>
          <ImportAssetSheet
            open={importAssetSheetOpen}
            setOpen={setImportAssetSheetOpen}
          />
          <ImportItem
            title="Import Equipments"
            description="Upload your equipments based on location code generated"
            onClick={() => {
              setImportAssetSheetOpen(true);
            }}
            icon={<Computer className=" h-10 w-10 stroke-[1.5px] text-slate-500" />}
          />
        </>
      </div>
    </div>
  );
}
