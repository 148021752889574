import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { addMonths, isBefore, isAfter } from "date-fns";
import { CalibrationStatusCard } from "./CalibrationStatusCard";

export function CalibrationMetrics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);

  const { data: assetsList, isLoading } = trpc.assets.getFullAssets.useQuery(
    {
      projectId: Number(activeProject?.id),
    },
    {
      enabled: !!activeProject?.id,
    }
  );

  if (isLoading || !assetsList) return null;

  // Filter assets that have calibration dates
  const assetsWithCalibration = assetsList.filter(
    (asset) => asset.callibrationDate
  );
  
  const today = new Date();
  
  // Count assets by calibration status
  const calibratedCount = assetsWithCalibration.filter((asset) => 
    asset.callibrationDate && isAfter(asset.callibrationDate, addMonths(today, 1))
  ).length;
  
  const upcomingCount = assetsWithCalibration.filter((asset) => 
    asset.callibrationDate && 
    isAfter(asset.callibrationDate, today) && 
    isBefore(asset.callibrationDate, addMonths(today, 1))
  ).length;
  
  const overdueCount = assetsWithCalibration.filter((asset) => 
    asset.callibrationDate && isBefore(asset.callibrationDate, today)
  ).length;

  const totalCount = assetsWithCalibration.length;

  return (
    <div className="grid gap-4 grid-cols-3">
      <CalibrationStatusCard
        title="Calibrated Equipment"
        value={calibratedCount}
        total={totalCount}
        className="bg-green-50"
      />
      <CalibrationStatusCard
        title="Due Soon (1 Month Left)"
        value={upcomingCount}
        total={totalCount}
        className="bg-yellow-50"
      />
      <CalibrationStatusCard
        title="Overdue Calibration"
        value={overdueCount}
        total={totalCount}
        className="bg-red-50"
      />
    </div>
  );
} 