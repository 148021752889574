import React from "react";

interface CheckboxProps {
  checked?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="2" y="2" width="20" height="20" rx="3" ry="3" fill="#f0f0f0" stroke="#000" />
    {checked && <polyline points="5 12 9 16 19 7" fill="none" stroke="#000" strokeWidth="3" />}
  </svg>
);

export default Checkbox; 