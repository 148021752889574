import { z } from "zod";

export const dynamicFieldSchema = z.object({
  id: z.string(),
  label: z.string(),
  type: z.enum(["text", "number", "date", "select", "multiselect", "checkbox"]),
  required: z.boolean(),
  order: z.number().min(0),
  options: z
    .array(
      z.object({
        name: z.string().optional(),
        label: z.string(),
        notifications: z.array(z.object({ id: z.number(), name: z.string() })),
      })
    )
    .optional(),
});

export const dynamicFieldsSchema = z.object({
  fields: z.array(dynamicFieldSchema).optional().nullable(),
});

export type DynamicField = z.infer<typeof dynamicFieldSchema>;