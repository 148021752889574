import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { PtwFormatType } from "../../../api/model";
import {
  usePtwControllerGetOnePtw,
  usePtwControllerUpdatePtw,
} from "../../../api/permit-to-work-adv/permit-to-work-adv";
import AppButton from "../../AppButton";
import PtwFormatRenderer from "./PtwFormatRenderer";
import { PtwFormSchema, PtwFormType } from "./PtwFormSchema";
import { format, parse, parseISO } from "date-fns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAppStorage from "../../../hooks/useAppStorage";
import { AttachmentProjectType } from "../../../redux/slices/OpenApi/cerevApi";
import { trpc } from "../../../lib/trpc";

export default function PtwFormDrawer() {
  const qc = useQueryClient();
  const utils = trpc.useUtils();
  const { uploadMultipleFile } = useAppStorage();
  const methods = useForm<PtwFormType>({
    resolver: zodResolver(PtwFormSchema),
    defaultValues: {
      formFields: [],
    },
  });
  const [searchParam, setSeachParam] = useSearchParams();
  const ptwId = searchParam.get("ptwId");

  const { mutateAsync: updatePtw } = usePtwControllerUpdatePtw();

  const arrayFields = useFieldArray({
    control: methods.control,
    name: "formFields",
  });

  const { data, isFetchedAfterMount } = usePtwControllerGetOnePtw(
    ptwId as string,
    {
      query: {
        enabled: !!ptwId,
        select: (res) => res.data.data,
      },
    }
  );

  // Populate the work permit form based on format in template
  useEffect(() => {
    if (isFetchedAfterMount && data) {
      methods.reset({
        ptwTemplate: data.ptwTemplate,
        ptwTexts: data.ptwTexts,
        ptwBools: data.ptwBools,
        ptwDates: data.ptwDates.map((ptwd) => ({
          ...ptwd,
          date: parseISO(ptwd.date),
        })),
        ptwNumbers: data.ptwNumbers,
        ptwImages: data.ptwImages,
        ptwAttachments: data.ptwAttachments,
        ptwOptionValues: data.ptwOptionValues,
        formFields: data.ptwTemplate.ptwFormat.map((f) => {
          return {
            id: f.id,
            name: f.name,
            ptwFormatType: f.ptwFormatType,
            heading: f.heading,
            description: f.description,
            ptwOptions: f.ptwOptions,
            ptwFormatId: f.id,
          };
        }),
      });
    }
  }, [isFetchedAfterMount, data]);

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: PtwFormType) => {
      // Upload attachment first
      for (const ptwAtt of data.ptwAttachments) {
        const uploadedAtt = await uploadMultipleFile({
          files: ptwAtt.newAttachments,
        });
        if (!uploadedAtt) continue;
        // add the new attachment to the existing attachment
        ptwAtt.attachments = ptwAtt.attachments.concat(
          uploadedAtt as AttachmentProjectType[]
        );
      }

      for (const ptwImg of data.ptwImages) {
        const uploadedImg = await uploadMultipleFile({
          files: ptwImg.newAttachments,
        });
        if (!uploadedImg) continue;
        ptwImg.attachments = ptwImg.attachments.concat(
          uploadedImg as AttachmentProjectType[]
        );
      }

      await updatePtw({
        ptwId: ptwId as string,
        data: {
          email: data.email,
          name: data.name,
          ptwTexts: data.ptwTexts,
          ptwNumbers: data.ptwNumbers,
          // To see how to fulfill the types, it may consist of union attachment types..
          ptwAttachments: data.ptwAttachments as any[],
          // To see how to fulfill the types, it may consist of union attachment types..
          ptwImages: data.ptwImages as any[],
          ptwOptionValues: data.ptwOptionValues.map((opt) => ({
            id: opt.id,
            ptwOptions: Array.isArray(opt.ptwOptions)
              ? opt.ptwOptions
              : [opt.ptwOptions],
          })),
          ptwBools: data.ptwBools,
          ptwDates: data.ptwDates.map((ptwd) => ({
            ...ptwd,
            date: ptwd.date.toISOString(),
          })),
        },
      });
    },
    onSuccess: () => {
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string).includes("ptw"),
      });
      utils.workPermit.invalidate();
      setSeachParam(new URLSearchParams());
    },
  });

  const onSubmit: SubmitHandler<PtwFormType> = async (data) => {
    // console.log(data);
    mutate(data);
  };

  return (
    <div className="flex flex-col gap-4">
      <FormProvider {...methods}>
        <h3 className="font-sans text-2xl font-bold">Permit To Work</h3>
        <PtwFormatRenderer arrayFields={arrayFields} />
        <div className="flex">
          <AppButton
            label="Confirm"
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isPending}
          />
        </div>
      </FormProvider>
    </div>
  );
}
