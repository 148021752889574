import { FileText } from "lucide-react";
import AppButton from "../../../../AppButton";
import { ADMCWorkPermitPdf } from "@cerev-cmms/pdf";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../../../lib/trpc";
import useDate from "../../../../../hooks/useDate";
import { useAppSelector } from "../../../../../redux/store";

// Function to determine which PDF component to render based on template name
const getPdfComponent = (
  templateName: string | undefined,
  projectCode: string
) => {
  if (!templateName) return null;

  switch (templateName.toLowerCase()) {
    case "contractor permit to work form":
      if (projectCode === "ADMC") return ADMCWorkPermitPdf;
      return null;
    // Add more cases here for different PDF templates
    default:
      return null; // Return null if no matching template is found
  }
};

export default function ExportCustomPtwPdf() {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const [searchParams] = useSearchParams();
  const ptwId = searchParams.get("ptwId");
  const { formatDateToLocal } = useDate();
  const activeProject = useAppSelector((state) => state.root.activeProject);

  const { data } = trpc.workPermit.getWorkPermit.useQuery(
    { ptwId: ptwId as string },
    {
      enabled: !!ptwId,
    }
  );

  const isCustomTemplate = data?.ptwTemplate?.setupType === "CUSTOM";
  const PdfComponent = getPdfComponent(data?.ptwTemplate?.name, activeProject?.code ?? "");

  // If not a custom template or no matching PDF component, don't render anything
  if (!isCustomTemplate || !PdfComponent) {
    return null;
  }

  // Helper function to get boolean values for a specific category
  const getBooleanValues = (category: "classification" | "safety" | "ppe") => {
    return (
      data?.ptwBools
        ?.filter((bool) => bool.value === true)
        ?.map((bool) => bool.ptwFormat.name) ?? []
    );
  };

  const getWorkers = () => {
    const workers = [];
    for (let i = 1; i <= 10; i++) {
      const workerName = data?.ptwTexts?.find(
        (text) => text.ptwFormat.name === `Worker ${i} - Name`
      );
      const workerIdentification = data?.ptwTexts?.find(
        (text) =>
          text.ptwFormat.name ===
          `Worker ${i} - Identification No. / Passport No.`
      );
      const workerCIDB = data?.ptwTexts?.find(
        (text) =>
          text.ptwFormat.name === `Worker ${i} - CIDB Card No. (if applicable)`
      );
      workers.push({
        name: workerName?.value ?? "",
        identificationNo: workerIdentification?.value ?? "",
        cidbCardNo: workerCIDB?.value ?? "",
      });
    }
    return workers;
  };

  // Helper function to get equipment data
  const getEquipmentData = () => {
    const equipmentList = [
      "Crane",
      "Skylift",
      "Gondola",
      "Scaffolding (more than 3 metres)",
      "Scaffolding (less than 3 metres)",
      "Ladder",
      "Premixed Pump",
      "Air Compressor / Receiver",
      "Vacuum Pump / Receiver",
      "Portable Generator Set",
      "Cement Mixer",
      "Hacking Machine",
      "Coring Machine",
      "Drilling Machine",
      "Welding Machine",
      "Oxy Acetylene Cutter",
      "Diamond / Cement Cutter",
      "Jig Saw Cutter / Tile Cutter",
      "Electric Saw",
      "Hand Grinder",
      "Nail Gun",
      "Nail Puncher"
    ];

    return equipmentList.map(equipment => ({
      name: equipment,
      isSelected: data?.ptwBools?.find(bool => bool.ptwFormat.name === equipment)?.value ?? false,
      quantity: Number(data?.ptwNumbers?.find(num => num.ptwFormat.name === `${equipment} Quantity`)?.value ?? 0),
      regulatoryRequirements: data?.ptwTexts?.find(text => text.ptwFormat.name === `${equipment} Regulartory Requirements`)?.value ?? ""
    }));
  };

  // Helper function to get chemicals data
  const getChemicalsData = () => {
    const chemicalsList = [
      "Adhesive / Glue",
      "Solvent / Thinner",
      "Paint",
      "Diesel / Petrol",
      "Acid / Corrosive Chemicals",
      "Cement",
      "Pesticides"
    ];

    return {
      selectedChemicals: chemicalsList.filter(chemical => 
        data?.ptwBools?.find(bool => bool.ptwFormat.name === chemical)?.value ?? false
      ),
      othersSpecify: data?.ptwTexts?.find(text => text.ptwFormat.name === "Others (Please specify)")?.value ?? ""
    };
  };

  return (
    <>
      <AppButton
        label="Export PDF"
        variant="outline"
        icon={<FileText />}
        onClick={() => {
          reactToPrintFn();
        }}
      />
      <div ref={contentRef} className="hidden print:block">
        <PdfComponent
          permitNumber={data?.id.toString()}
          companyName={data?.name ?? "-"}
          personInCharge={
            data?.ptwTexts?.find(
              (text) => text.ptwFormat.name === "Person in charge"
            )?.value
          }
          contactNo={
            data?.ptwTexts?.find(
              (text) => text.ptwFormat.name === "Contact no."
            )?.value
          }
          scopeOfWork={
            data?.ptwTexts?.find(
              (text) => text.ptwFormat.name === "Scope of work"
            )?.value
          }
          dateFrom={
            data?.ptwDates?.find(
              (p) => p.ptwFormat.name === "Date of work (from)"
            )?.date
              ? formatDateToLocal({
                  dateStr:
                    data?.ptwDates
                      ?.find((p) => p.ptwFormat.name === "Date of work (from)")
                      ?.date?.toISOString() ?? "",
                })
              : "-"
          }
          dateTo={
            data?.ptwDates?.find(
              (p) => p.ptwFormat.name === "Date of work (to)"
            )?.date
              ? formatDateToLocal({
                  dateStr:
                    data?.ptwDates
                      ?.find((p) => p.ptwFormat.name === "Date of work (to)")
                      ?.date?.toISOString() ?? "",
                })
              : "-"
          }
          timeFrom={
            data?.ptwDates.find(
              (p) => p.ptwFormat.name === "Date of work (from)"
            )?.date
              ? formatDateToLocal({
                  dateStr:
                    data?.ptwDates
                      .find((p) => p.ptwFormat.name === "Date of work (from)")
                      ?.date?.toISOString() ?? "",
                  formatting: "HH:mm",
                })
              : "-"
          }
          timeTo={
            data?.ptwDates.find((p) => p.ptwFormat.name === "Date of work (to)")
              ?.date
              ? formatDateToLocal({
                  dateStr:
                    data?.ptwDates
                      .find((p) => p.ptwFormat.name === "Date of work (to)")
                      ?.date?.toISOString() ?? "",
                  formatting: "HH:mm",
                })
              : "-"
          }
          classificationOfWork={getBooleanValues("classification")}
          safetyMeasures={getBooleanValues("safety")}
          ppeRequirements={getBooleanValues("ppe")}
          workers={getWorkers()}
          equipment={getEquipmentData()}
          chemicals={getChemicalsData()}
        />
      </div>
    </>
  );
}
