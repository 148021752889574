import { ReactNode } from 'react';
import DefaultDashboard from '../components/DefaultDashboard';
import IPMDashboard from '../components/IPMDashboard';
import KDNPDashboard from '../components/KDNPDashboard';
import OasisDashboard from '../components/OasisDashboard';
import { useIpmNewGraphsEnabled } from '../../../hooks/useFeatures';
import { useAppSelector } from '../../../redux/store';
import StandardDashboard from '../components/StandardDashboard';
import ADMCDashboard from '../components/ADMCDashboard/ADMCDashboard';
import MoninDashboard from '../components/MoninDashboard/MoninDashboard';

export interface DashboardProps {
  currentTabIdx: string;
}

export interface DashboardConfig {
  component: (props: DashboardProps) => ReactNode;
  requiresData?: boolean;
}

export const dashboardRegistry: Record<string, DashboardConfig> = {
  KDNP: {
    component: KDNPDashboard,
    requiresData: false,
  },
  OS: {
    component: OasisDashboard,
    requiresData: true,
  },
  ADMC: {
    component: ADMCDashboard,
    requiresData: true,
  },
  IPM: {
    component: IPMDashboard,
    requiresData: true,
  },
  MAK: {
    component: MoninDashboard,
    requiresData: true,
  },
  DEFAULT: {
    component: StandardDashboard,
    requiresData: true,
  },
};

export const getDashboardComponent = (projectCode?: string): DashboardConfig => {
  const activeProj = useAppSelector(state => state.root.activeProject);
  const ipmNewGraphsEnabled = useIpmNewGraphsEnabled({
    projectId: activeProj?.id?.toString() ?? "",
  });

  if (!projectCode) return dashboardRegistry.DEFAULT;
  
  if (projectCode === 'IPM') {
    return ipmNewGraphsEnabled 
      ? dashboardRegistry.IPM 
      : dashboardRegistry.DEFAULT;
  }
  
  return dashboardRegistry[projectCode] || dashboardRegistry.DEFAULT;
}; 