import { ColumnDef, Row } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { ASSET_DETAIL_DRAWER } from "../../../AppDrawer";
import { RouterOutputs } from "@cerev-cmms/trpc";

type SubAsset = RouterOutputs["assets"]["getOneAsset"]["childrenAsset"][number];

export const subAssetColumns: ColumnDef<SubAsset>[] = [
  {
    id: "id",
    accessorKey: "id",
  },
  {
    id: "name",
    header: "Sub Equipment",
    accessorKey: "name",
    cell: ({ row }) => <SubAssetNameNav row={row} />,
  },
  {
    id: "code",
    header: "Code",
    accessorKey: "code",
  },
];

const SubAssetNameNav = ({ row }: { row: Row<SubAsset> }) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", ASSET_DETAIL_DRAWER);
          p.set("assetId", row.getValue("id"));
          p.set("assetDetailTabIdx", "4");
          p.set("parentAssetId", row.original.parentAssetId?.toString() ?? "");
          return p;
        });
      }}
    >
      {row.getValue("name") ?? "-"}
    </div>
  );
};
