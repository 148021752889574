import { RouterOutputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import { ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import AppFullLocation from "../../../../components/AppFullLocation";
import {
  ASSET_DETAIL_DRAWER,
  DRAWER_VIEW_STATE,
} from "../../../../components/drawers/AppDrawer";

type Asset = RouterOutputs['assets']['getPaginatedAssets']['data'][number]

export const equipmentColumns: ColumnDef<Asset>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => {
      const [, setSearchParams] = useSearchParams();
      return (
        <p
          className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", ASSET_DETAIL_DRAWER);
              p.set("drawerState", DRAWER_VIEW_STATE);
              p.set("assetId", row.getValue("id")?.toString() ?? "");
              p.set("assetDetailTabIdx", "0");
              return p;
            });
          }}
        >
          {row.getValue("name")}
        </p>
      );
    },
  },
  {
    accessorKey: "code",
    header: "Code",
  },
  {
    header: "Equipment Type",
    accessorKey: "assetType.name",
  },
  {
    accessorKey: "locationId",
    header: "Location",
    cell: ({ row }) => {
      return (
        <AppFullLocation locationId={row.getValue("locationId")?.toString()} />
      );
    },
  },
];
