import { ColumnDef } from "@tanstack/react-table";
import { format, isBefore, startOfDay } from "date-fns";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  ASSET_DETAIL_DRAWER,
} from "@/components/drawers/AppDrawer";
import { Badge } from "@/components/ui/badge";
import { RouterOutputs } from "@cerev-cmms/trpc";

type Asset = RouterOutputs["assets"]["getFullAssets"][number];

export const columns: ColumnDef<Asset>[] = [
  {
    accessorKey: "index",
    header: "No.",
    cell: ({ row }) => row.index + 1,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "name",
    header: "Equipment Name",
    cell: ({ row }) => {
      const [searchParams, setSearchParams] = useSearchParams();
      return (
        <span
          className="cursor-pointer underline  text-primary-900 hover:text-primary/80"
          onClick={() => {
            setSearchParams((params) => {
              params.set("drawerType", ASSET_DETAIL_DRAWER);
              params.set("drawerState", DRAWER_VIEW_STATE);
              params.set("assetId", row.original.id.toString());
              return params;
            });
          }}
        >
          {row.original.name}
        </span>
      );
    },
  },
  {
    accessorKey: "code",
    header: "Asset Code",
    cell: ({ row }) => row.original.code || "-",
  },
  {
    accessorKey: "type",
    header: "Equipment Type",
    cell: ({ row }) => row.original.assetType?.name || "-",
    accessorFn: (row) => row.assetType?.name || "",
  },
  {
    accessorKey: "calibrationDate",
    header: "Calibration Date",
    cell: ({ row }) =>
      row.original.callibrationDate
        ? format(new Date(row.original.callibrationDate), "dd MMM yyyy")
        : "-",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const calibrationDate = row.original.callibrationDate;
      const today = startOfDay(new Date());

      let status = "Due Soon";
      if (calibrationDate && isBefore(calibrationDate, today)) {
        status = "Overdue";
      }

      return (
        <Badge
          variant="outline"
          className={
            status === "Overdue"
              ? "bg-red-500 text-white"
              : "bg-yellow-500 text-white"
          }
        >
          {status}
        </Badge>
      );
    },
    accessorFn: (row) => {
      const calibrationDate = row.callibrationDate;
      const today = startOfDay(new Date());
      return calibrationDate && isBefore(calibrationDate, today)
        ? "Overdue"
        : "Due Soon";
    },
  },
];
