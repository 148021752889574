import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components/ui/scroll-area";
import AppDialog from "../../components/dialogs/AppDialog";
import AppDrawer from "../../components/drawers/AppDrawer";
import GlobalSnackbar from "../../components/snackbar/GlobalSnackbar";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "../../components/ui/sidebar";
import { CerevSidebar } from "./components/CerevSidebar/CerevSidebar";
import { Separator } from "../../components/ui/separator";
import PmSchedulerSidebar from "../PmSchedulerScreen/components/PmSchedulerSidebar";
import WOSchedulerSidebar from "../WOSchedulerScreen/components/WOSchedulerSidebar";
import SettingsSidebar from "../SetttingScreen/components/SettingsSidebar";
import { cn } from "../../utils/util";

export default function HomeScreen() {
  const location = useLocation();
  const navigate = useNavigate();

  // To have indexed route when first navigating into home.
  useEffect(() => {
    if (location.pathname === "/home") navigate("/home/dashboard");
  }, [location]);

  const isPmSchedulerScreen = location.pathname.includes("/home/scheduler-pm");
  const isWOSchedulerScreen = location.pathname.includes("/home/wo-scheduler");
  const isSettingsScreen = location.pathname.includes("/home/settings");

  return (
    <SidebarProvider defaultOpen={true}>
      {isSettingsScreen ? <SettingsSidebar /> : <CerevSidebar />}
      <SidebarInset
        className={cn("p-5", {
          "pr-[416px]": isWOSchedulerScreen,
          "pr-[274px]": isPmSchedulerScreen,
        })}
      >
        <ScrollArea className="min-h-screen">
          {
           !isSettingsScreen && (
            <div className="flex pb-4  items-center gap-2 transition-[width,height] ease-linear">
            <SidebarTrigger className="ml-1 text-slate-400" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <p className="text-muted-foreground text-sm text-slate-400">
              Cerev CMMS
            </p>
          </div>
           )
          }
          <div className={
            cn("p-1", {
              "p-4": isSettingsScreen,
            })
          }>
            <Outlet />
          </div>
          <GlobalSnackbar />
        </ScrollArea>
      </SidebarInset>
      {isPmSchedulerScreen && <PmSchedulerSidebar />}
      {isWOSchedulerScreen && <WOSchedulerSidebar />}
      <AppDialog />
      <AppDrawer />
    </SidebarProvider>
  );
}
