import React from "react";
import Checkbox from "./Checkbox";

const styles = {
  // Base page styles for A4
  page: {
    fontFamily: "Fira Sans, sans-serif",
    padding: "15px 30px", // Reduced padding
    width: "21cm",
    minHeight: "29.7cm", // A4 height
    margin: "0 auto",
    background: "white",
    position: "relative" as const,
    boxSizing: "border-box" as const,
  },
  table: {
    width: "100%",
    borderCollapse: "collapse" as const,
    border: "1px solid #000",
  },
  tableCell: {
    border: "1px solid #000",
    padding: "4px",
    fontSize: "10px",
    minHeight: "24px",
    verticalAlign: "middle" as const,
  },
  header: {
    marginBottom: "15px", // Reduced margin
    position: "relative" as const,
  },
  headerTitle: {
    fontSize: "14px", // Reduced font size
    fontWeight: "bold",
    textAlign: "left" as const,
  },
  permitNumber: {
    position: "absolute" as const,
    top: 0,
    right: 0,
    border: "1px solid #000",
    padding: "2px", // Reduced padding
    width: "150px",
  },
  permitNumberText: {
    fontSize: "8px",
  },
  formContainer: {
    border: "1px solid #000",
    marginBottom: "10px", // Added margin bottom
  },
  title: {
    background: "#000",
    color: "#fff",
    padding: "3px", // Reduced padding
    textAlign: "center" as const,
    fontWeight: "bold",
    fontSize: "11px", // Adjusted font size
  },
  row: {
    display: "flex",
    borderBottom: "1px solid #000",
  },
  cell: {
    flex: 1,
    padding: "4px",
    borderRight: "1px solid #000",
    minHeight: "24px",
    fontSize: "10px",
  },
  cellLast: {
    borderRight: "none",
  },
  cellFlex3: {
    flex: 3,
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  flexGrow: {
    flexGrow: 1,
  },
  riskManagement: {
    borderTop: "1px solid #000",
  },
  riskHeader: {
    background: "#000",
    color: "#fff",
    padding: "4px",
    fontSize: "10px",
  },
  riskTable: {
    width: "100%",
    borderCollapse: "collapse" as const,
    fontSize: "7px", // Reduced font size
  },
  riskTableHeader: {
    background: "oklch(0.87 0 0)",
    fontSize: "8px", // Reduced font size
    textAlign: "left" as const,
    padding: "3px", // Reduced padding
    border: "1px solid #000",
    fontWeight: "bold",
  },
  riskTableCell: {
    border: "1px solid #000",
    padding: "2px", // Reduced padding
    verticalAlign: "top" as const,
  },
  checkboxItem: {
    display: "flex",
    alignItems: "center",
    margin: "1px 0",
    padding: "2px", // Reduced padding
    gap: "2px",
  },
  checkbox: {
    width: "7px",
    height: "7px",
    border: "1px solid #000",
    marginRight: "3px",
    marginTop: "1px",
    flexShrink: 0 as const,
    position: "relative" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
  },
  checkmark: {
    width: "7px",
    height: "7px",
    position: "absolute" as const,
    left: "0",
    top: "0",
  },
  checkboxLabel: {
    fontSize: "7px", // Reduced font size
  },
  indent: {
    marginLeft: "12px",
    fontSize: "8px",
  },
  documents: {
    background: "#000",
    color: "#fff",
    padding: "4px",
    fontSize: "10px",
  },
  documentsGrid: {
    display: "flex",
    padding: "8px",
  },
  documentsColumn: {
    flex: 1,
  },
  approval: {
    background: "#000",
    color: "#fff",
    padding: "4px",
    fontSize: "10px",
  },
  approvalGrid: {
    display: "flex",
  },
  approvalColumn: {
    flex: 1,
    padding: "4px",
    borderRight: "1px solid #000",
  },
  approvalColumnLast: {
    borderRight: "none",
  },
  approvalTitle: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  approvalText: {
    fontSize: "8px",
    marginTop: "4px",
  },
  signatureSection: {
    marginTop: "12px", // Reduced margin
  },
  signatureLine: {
    fontSize: "7px", // Reduced font size
    marginTop: "2px", // Reduced margin
  },
  terminationSignatureLine: {
    borderBottom: "1px solid #000",
    height: "20px",
  },
  footer: {
    position: "absolute" as const,
    bottom: "10px",
    right: "30px",
    fontSize: "6px",
    color: "#666",
  },
  briefingPage: {
    fontFamily: "Fira Sans, sans-serif",
    padding: "15px 30px",
    width: "21cm",
    minHeight: "29.7cm",
    margin: "0 auto",
    background: "white",
    position: "relative" as const,
    boxSizing: "border-box" as const,
    pageBreakBefore: "always" as const,
  },
  briefingTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  briefingSubtitle: {
    fontSize: "10px",
    marginBottom: "12px",
    fontStyle: "italic",
  },
  briefingSection: {
    border: "1px solid #000",
    padding: "4px",
  },
  briefingSectionTitle: {
    padding: "4px 0",
    fontSize: "10px",
    fontWeight: "bold",
  },
  briefingContent: {
    padding: "4px", // Reduced padding
  },
  briefingList: {
    margin: 0,
    padding: "0 0 0 20px",
    fontSize: "10px",
  },
  briefingListItem: {
    marginBottom: "2px",
  },
  briefingEmergencyNumbers: {
    display: "flex",
    gap: "16px",
    fontSize: "10px",
    marginTop: "2px",
    marginLeft: "16px",
  },
  terminationTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    marginTop: "16px",
    marginBottom: "8px",
  },
  terminationSection: {
    border: "1px solid #000",
    marginBottom: "8px",
  },
  terminationContent: {
    padding: "8px",
  },
  terminationList: {
    margin: 0,
    padding: "0 0 0 20px",
    fontSize: "10px",
    listStyle: "disc",
  },
  terminationListItem: {
    marginBottom: "4px",
  },
  nonComplianceBox: {
    margin: "8px 0",
    padding: "8px",
    minHeight: "30px",
  },
  nonComplianceLabel: {
    fontSize: "10px",
    marginBottom: "4px",
  },
  terminationSignatureGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    marginTop: "16px",
  },
  terminationSignatureBox: {
    border: "1px solid #000",
    padding: "8px",
  },
  terminationSignatureTitle: {
    fontSize: "10px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  signatureField: {
    marginBottom: "16px",
  },
  signatureLabel: {
    fontSize: "10px",
    marginBottom: "4px",
  },
  attachmentTable: {
    width: "100%",
    borderCollapse: "collapse" as const,
    marginBottom: "10px", // Added margin
  },
  attachmentCell: {
    border: "1px solid #000",
    padding: "2px", // Reduced padding
    fontSize: "7px", // Reduced font size
  },
};

interface ADMCWorkPermitProps {
  permitNumber?: string;
  companyName?: string;
  personInCharge?: string;
  contactNo?: string;
  locationOfWork?: string;
  scopeOfWork?: string;
  dateFrom?: string;
  dateTo?: string;
  timeFrom?: string;
  timeTo?: string;
  classificationOfWork?: string[];
  safetyMeasures?: string[];
  ppeRequirements?: string[];
  workers?: Array<{
    name?: string;
    identificationNo?: string;
    cidbCardNo?: string;
  }>;
  equipment?: Array<{
    name: string;
    isSelected: boolean;
    quantity: number;
    regulatoryRequirements: string;
  }>;
  chemicals?: {
    selectedChemicals: string[];
    othersSpecify: string;
  };
}

// Helper function to check if a value exists in an array
const isChecked = (value: string, array?: string[]) => {
  return array?.includes(value) ?? false;
};

// Helper function to get checkbox style with checkmark
const getCheckboxStyle = (checked: boolean) => ({
  width: "7px",
  height: "7px",
  border: "1px solid #000",
  marginRight: "3px",
  marginTop: "1px",
  flexShrink: 0 as const,
  position: "relative" as const,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: checked ? "#000" : "white",
});

function ADMCWorkPermit({
  permitNumber,
  companyName,
  personInCharge,
  contactNo,
  locationOfWork,
  scopeOfWork,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo,
  classificationOfWork = [],
  safetyMeasures = [],
  ppeRequirements = [],
  workers = [],
  equipment = [],
  chemicals = { selectedChemicals: [], othersSpecify: "" },
}: ADMCWorkPermitProps) {
  return (
    <>
      {/* ===== PAGE 1: CONTRACTOR PERMIT TO WORK FORM ===== */}
      <div style={styles.page}>
        <div style={styles.header}>
          <h1 style={styles.headerTitle}>Ara Damansara Medical Centre</h1>
          <div style={styles.permitNumber}>
            <p style={styles.permitNumberText}>Permit To Work No.</p>
            <p style={styles.permitNumberText}>{permitNumber}</p>
          </div>
        </div>

        <div style={styles.formContainer}>
          <div style={styles.title}>CONTRACTOR PERMIT TO WORK FORM</div>

          <table style={styles.table}>
            <tbody>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "150px",
                    fontWeight: "bold",
                    borderRight: "1px solid #000",
                  }}
                >
                  Company Name
                </td>
                <td style={styles.tableCell}>{companyName}</td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "150px",
                    fontWeight: "bold",
                  }}
                >
                  Person in charge
                </td>
                <td
                  style={{
                    ...styles.tableCell,
                    padding: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid #000",
                      padding: "6px",
                    }}
                  >
                    {personInCharge}
                  </div>

                  <div
                    style={{
                      fontWeight: "bold",
                      width: "20%",
                      padding: "6px",
                      borderRight: "1px solid #000",
                    }}
                  >
                    Contact no.
                  </div>
                  <span
                    style={{
                      marginLeft: "4px",
                      flex: 1,
                      padding: "6px",
                    }}
                  >
                    {contactNo ?? ""}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "150px",
                    fontWeight: "bold",
                  }}
                >
                  Location of work
                </td>
                <td style={styles.tableCell}>{locationOfWork}</td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "150px",
                    fontWeight: "bold",
                    borderRight: "1px solid #000",
                  }}
                >
                  Scope of work
                </td>
                <td style={styles.tableCell}>{scopeOfWork}</td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "150px",
                    fontWeight: "bold",
                    borderRight: "1px solid #000",
                  }}
                >
                  Date of work
                </td>
                <td style={styles.tableCell}>
                  <div style={styles.flexRow}>
                    <span style={{ marginRight: "8px" }}>from</span>
                    <div
                      style={{
                        ...styles.flexGrow,
                        borderRight: "1px solid #000",
                        marginRight: "8px",
                        paddingRight: "8px",
                      }}
                    >
                      {dateFrom}
                    </div>
                    <span style={{ marginRight: "8px" }}>to</span>
                    <div style={styles.flexGrow}>{dateTo}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "150px",
                    fontWeight: "bold",
                    borderRight: "1px solid #000",
                  }}
                >
                  Time of work
                </td>
                <td style={styles.tableCell}>
                  <div style={styles.flexRow}>
                    <span style={{ marginRight: "8px" }}>from</span>
                    <div
                      style={{
                        ...styles.flexGrow,
                        borderRight: "1px solid #000",
                        marginRight: "8px",
                        paddingRight: "8px",
                      }}
                    >
                      {timeFrom}
                    </div>
                    <span style={{ marginRight: "8px" }}>to</span>
                    <div style={styles.flexGrow}>{timeTo}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {/* Risk Management Section */}
          <div style={styles.riskManagement}>
            <div style={styles.riskHeader}>
              RISK MANAGEMENT - To be completed by Contractor [Please tick (√)
              where relevant]
            </div>
            <table style={styles.riskTable}>
              <thead>
                <tr>
                  <th style={{ ...styles.riskTableHeader, width: "30%" }}>
                    Classification of work:
                  </th>
                  <th style={{ ...styles.riskTableHeader, width: "40%" }}>
                    Safety Measures / Requirements:
                  </th>
                  <th style={{ ...styles.riskTableHeader, width: "30%" }}>
                    PPE Requirements:
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Electrical Group */}
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Equipment repair / maintenance (PPM)",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Equipment repair / maintenance (PPM)
                      </span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell} rowSpan={2}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Cordon worksite together with warning signage (where required)",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Cordon worksite together with warning signage (where
                        required)
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Isolate utility systems (where required) - electrical / water / gas / ventilation",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Isolate utility systems (where required) - electrical /
                        water / gas / ventilation
                      </span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell} rowSpan={7}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Safety Helmets", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Safety Helmets</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Hairnets", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Hairnets</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Chemical Safety Goggles",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Chemical Safety Goggles
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Safety Glasses", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Safety Glasses</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Respirators", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Respirators</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("N95 Masks", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>N95 Masks</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Surgical Masks", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Surgical Masks</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Ear Plugs", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Ear Plugs</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Ear Muffs", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Ear Muffs</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Face Shield", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Face Shield</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Safety Gloves", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Safety Gloves</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Safety Shoes", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Safety Shoes</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Safety Boots", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Safety Boots</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Protective Clothing",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Protective Clothing
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Protective Coverall",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Protective Coverall
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Safety Harness", ppeRequirements)}
                      />
                      <span style={styles.checkboxLabel}>Safety Harness</span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Welding Protective Equipment",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Welding Protective Equipment
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Others (Please specify) :",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Others (Please specify) :
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Sampling / Monitoring / Testing",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Sampling / Monitoring / Testing
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Electrical", classificationOfWork)}
                      />
                      <span style={styles.checkboxLabel}>Electrical</span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Isolate energy source - Lock Out and Tag Out (LOTO)",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Isolate energy source - Lock Out and Tag Out (LOTO)
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Portable RCD (30mA sensitivity) / Electrical DB is made available",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Portable RCD (30mA sensitivity) / Electrical DB is made
                        available
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Power plug point and cable extension in good condition",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Power plug point and cable extension in good condition
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Use of chemicals",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>Use of chemicals</span>
                    </div>
                    <div style={styles.indent}>(Paint / Glue / Solvent)</div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Complete Attachment B - List of equipment and chemicals",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Complete Attachment B - List of equipment and chemicals
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Isolate flammable and combustible chemicals from fire source",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Isolate flammable and combustible chemicals from fire
                        source
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Safety Data Sheet available at worksite",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Safety Data Sheet available at worksite
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Work at height (above 2 metres)",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Work at height (above 2 metres)
                      </span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Ladder - Good condition, stable and with rubber footing",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Ladder - Good condition, stable and with rubber footing
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Gondola - Valid Certificate of Fitness (CF) & training record",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Gondola - Valid Certificate of Fitness (CF) & training
                        record
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Scaffolding (above 3 metres) - Inspection by competent person",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Scaffolding (above 3 metres) - Inspection by competent
                        person
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Lifting - Crane / Skylift",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Lifting - Crane / Skylift
                      </span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Valid Certificate of Fitness (CF) and driver competency",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Valid Certificate of Fitness (CF) and driver competency
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Cordon worksite together with warning signage",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Cordon worksite together with warning signage
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Traffic Controller / Flag Man",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Traffic Controller / Flag Man
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Drilling / Coring",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Drilling / Coring
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Hacking / Demolition",
                          classificationOfWork
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Hacking / Demolition
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Excavation", classificationOfWork)}
                      />
                      <span style={styles.checkboxLabel}>Excavation</span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Cordon worksite together with warning signage",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Cordon worksite together with warning signage
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Isolate utility systems - electrical / water / gas / ventilation",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Isolate utility systems - electrical / water / gas /
                        ventilation
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Prevent dust migration",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Prevent dust migration
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Fire wall evaluation* (for drilling / coring / hacking / demolition)",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Fire wall evaluation* (for drilling / coring / hacking /
                        demolition)
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Prevent landslide / collapsing of soil (for excavation)",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Prevent landslide / collapsing of soil (for excavation)
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked("Hot Work", classificationOfWork)}
                      />
                      <span style={styles.checkboxLabel}>Hot Work</span>
                    </div>
                    <div style={styles.indent}>
                      (Welding / Soldering / Grinding / Gas
                    </div>
                    <div style={styles.indent}>
                      Cutting / Use of naked flame)
                    </div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Isolate flammable materials / liquids / gases",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Isolate flammable materials / liquids / gases
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Isolate fire alarm / gas system",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Isolate fire alarm / gas system
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Fire extinguisher is made available",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Fire extinguisher is made available
                      </span>
                    </div>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Flashback arrestor (for welding)",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Flashback arrestor (for welding)
                      </span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Confined Space Entry Apply for Confined Space Entry Permit To Work **",
                          safetyMeasures
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Confined Space Entry Apply for Confined Space Entry
                        Permit To Work **
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={styles.riskTableCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Others (Please specify) :",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Others (Please specify) :
                      </span>
                    </div>
                  </td>
                  <td style={styles.riskTableCell} colSpan={2}>
                    <div style={styles.checkboxItem}>
                      <Checkbox
                        checked={isChecked(
                          "Others (Please specify) :",
                          ppeRequirements
                        )}
                      />
                      <span style={styles.checkboxLabel}>
                        Others (Please specify) :
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Documents Section */}
          <div style={styles.documents}>
            DOCUMENTS - To be submitted by Contractor (Please tick (√) where
            relevant)
          </div>
          <div style={styles.documentsGrid}>
            <div style={styles.documentsColumn}>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={isChecked(
                    "List of workers - Attachment A",
                    safetyMeasures
                  )}
                />
                <span style={styles.checkboxLabel}>
                  List of workers - Attachment A
                </span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={isChecked(
                    "List of equipment & chemicals - Attachment B",
                    safetyMeasures
                  )}
                />
                <span style={styles.checkboxLabel}>
                  List of equipment & chemicals - Attachment B
                </span>
              </div>
            </div>
            <div style={styles.documentsColumn}>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={isChecked(
                    "Copy of Certificates / Licenses",
                    safetyMeasures
                  )}
                />
                <span style={styles.checkboxLabel}>
                  Copy of Certificates / Licenses
                </span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={isChecked(
                    "Safe Method Statement / Safe Working Chart",
                    safetyMeasures
                  )}
                />
                <span style={styles.checkboxLabel}>
                  Safe Method Statement / Safe Working Chart
                </span>
              </div>
            </div>
            <div style={styles.documentsColumn}>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={isChecked(
                    "Fire Wall - Before & After Photo",
                    safetyMeasures
                  )}
                />
                <span style={styles.checkboxLabel}>
                  Fire Wall - Before & After Photo
                </span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={isChecked(
                    "Certified Space Entry Permit To Work",
                    safetyMeasures
                  )}
                />
                <span style={styles.checkboxLabel}>
                  Certified Space Entry Permit To Work
                </span>
              </div>
            </div>
          </div>

          {/* Approval Section */}
          <div style={styles.approval}>
            ACCEPTANCE/APPROVAL /ACKNOWLEDGEMENT:
          </div>
          <div style={styles.approvalGrid}>
            <div style={styles.approvalColumn}>
              <div style={styles.approvalTitle}>ACCEPTANCE BY</div>
              <div style={styles.approvalText}>
                I / We understand and shall comply with Permit To Work
                conditions and requirements of ESH Manual for Contractors &
                Vendors.
              </div>
              <div style={styles.signatureSection}>
                <div style={styles.signatureLine}>Signature:</div>
                <div style={styles.signatureLine}>Name:</div>
                <div style={styles.signatureLine}>Date:</div>
              </div>
            </div>
            <div style={styles.approvalColumn}>
              <div style={styles.approvalTitle}>FACILITY / BIOMEDICAL</div>
              <div style={styles.signatureSection}>
                <div style={styles.signatureLine}>Signature:</div>
                <div style={styles.signatureLine}>Name:</div>
                <div style={styles.signatureLine}>Date:</div>
              </div>
            </div>
            <div style={styles.approvalColumn}>
              <div style={styles.approvalTitle}>SAFETY & SECURITY</div>
              <div style={styles.signatureSection}>
                <div style={styles.signatureLine}>Signature:</div>
                <div style={styles.signatureLine}>Name:</div>
                <div style={styles.signatureLine}>Date:</div>
              </div>
            </div>
            <div style={styles.approvalColumn}>
              <div style={styles.approvalTitle}>INFECTION CONTROL</div>
              <div style={styles.signatureSection}>
                <div style={styles.signatureLine}>Signature:</div>
                <div style={styles.signatureLine}>Name:</div>
                <div style={styles.signatureLine}>Date:</div>
              </div>
            </div>
            <div
              style={{ ...styles.approvalColumn, ...styles.approvalColumnLast }}
            >
              <div style={styles.approvalTitle}>SHODESIGNEE</div>
              <div style={styles.signatureSection}>
                <div style={styles.signatureLine}>Signature:</div>
                <div style={styles.signatureLine}>Name:</div>
                <div style={styles.signatureLine}>Date:</div>
              </div>
            </div>
          </div>
        </div>

        <div style={styles.footer}>Last Update: SOM/ESH/FOR/05/January2024</div>
      </div>

      {/* ===== PAGE 2: BRIEFING TO CONTRACTORS ===== */}
      <div style={{ ...styles.page, pageBreakBefore: "always" }}>
        <div style={styles.briefingTitle}>BRIEFING TO CONTRACTORS</div>
        <div style={styles.briefingSubtitle}>
          (General requirements for Contractor)
        </div>

        <div style={styles.briefingSection}>
          <div style={styles.briefingSectionTitle}>
            Environmental Safety and Health (ESH) and Safety & Security
            Requirements:
          </div>
          <ul style={styles.briefingList}>
            <li style={styles.briefingListItem}>
              Eat & Drinking – NO eating and drinking at worksite.
            </li>
            <li style={styles.briefingListItem}>
              Signage – Secure worksite from public access with signage to alert
              the public.
            </li>
            <li style={styles.briefingListItem}>
              Personal Protective Equipment (PPE) – Comply with the usage of the
              agreed PPE at all times.
            </li>
            <li style={styles.briefingListItem}>
              Person-in-charge - Designation of personnel on-site to oversee the
              implementation of safety requirements (for renovation projects)
            </li>
            <li style={styles.briefingListItem}>
              Contractor Pass – Wear the pass at all times when working within
              the hospital compounds.
            </li>
            <li style={styles.briefingListItem}>
              Working Hours - 7:00 AM to 7:00 PM from Monday to Saturday unless
              specified otherwise by hospital authorized personnel.
            </li>
            <li style={styles.briefingListItem}>
              Access & Egress - Through basement or designated route. NO ENTRY
              to other areas except to the designated worksite.
            </li>
            <li style={styles.briefingListItem}>
              Smoking, Alcohol & Drugs – STRICTLY NO smoking and NO usage of
              alcohol and drugs within the hospital / college compounds
            </li>
            <li style={styles.briefingListItem}>
              Violence & Harassment – ZERO Tolerance for any form of physical
              violence and harassment by workers
            </li>
          </ul>
        </div>

        <div style={styles.briefingSection}>
          <div style={styles.briefingSectionTitle}>
            Infection Control Requirements:
          </div>
          <ul style={styles.briefingList}>
            <li style={styles.briefingListItem}>
              Proper hoarding and sealing of worksite from ceiling to floor with
              signage to alert the public.
            </li>
            <li style={styles.briefingListItem}>Isolate ventilation system</li>
            <li style={styles.briefingListItem}>
              Place sticky mats at worksite entrance
            </li>
            <li style={styles.briefingListItem}>
              Floor protection for the walkway and transportation of equipment /
              materials to and from the worksite
            </li>
            <li style={styles.briefingListItem}>
              Cover equipment / materials appropriately during transportation
            </li>
            <li style={styles.briefingListItem}>
              Clean site by Hepa filter vacuum - Broom is NOT allowed
            </li>
          </ul>
        </div>

        <div style={styles.briefingSection}>
          <div style={styles.briefingSectionTitle}>
            Housekeeping and Waste Management Requirements:
          </div>
          <ul style={styles.briefingList}>
            <li style={styles.briefingListItem}>
              Maintain good housekeeping at the worksite.
            </li>
            <li style={styles.briefingListItem}>
              Remove waste daily from worksite and dispose it into designated
              waste collection area.
            </li>
            <li style={styles.briefingListItem}>
              Dispose scheduled wastes according to the Environmental Quality
              Act and its regulations
            </li>
          </ul>
        </div>

        <div style={styles.briefingSection}>
          <div style={styles.briefingSectionTitle}>Fire & Evacuation:</div>
          <ul style={styles.briefingList}>
            <li style={styles.briefingListItem}>
              Emergency Numbers
              <div style={styles.briefingEmergencyNumbers}>
                <span>General - 5555</span>
                <span>Hotline 03 – 7840 0172</span>
                <span>Mobile 019 – 335 1348</span>
              </div>
            </li>
            <li style={styles.briefingListItem}>
              Know the emergency evacuation route and exit from the worksite.
            </li>
            <li style={styles.briefingListItem}>
              Activate the break glass and notify the hospital / college
              authorized personnel immediately.
            </li>
            <li style={styles.briefingListItem}>
              Follow instruction from Bomba / Fire Commandant / Fire Squad
            </li>
            <li style={styles.briefingListItem}>
              Fire Fighting Facilities – NO obstruction to any firefighting
              facilities, emergency exits and corridors.
            </li>
          </ul>
        </div>

        <div style={styles.briefingSection}>
          <div style={styles.briefingSectionTitle}>Accidents:</div>
          <ul style={styles.briefingList}>
            <li style={styles.briefingListItem}>
              Notify all accidents at worksite to the hospital / college
              authorized personnel within 24 hours followed by investigation
              report within 7 days.
            </li>
            <li style={styles.briefingListItem}>
              Contractor to notify accidents to DOSH / DOE as per legal
              requirements
            </li>
          </ul>
        </div>

        <div style={styles.briefingSection}>
          <div style={styles.briefingSectionTitle}>
            ESH Manual for Contractors & Vendors:
          </div>
          <ul style={styles.briefingList}>
            <li style={styles.briefingListItem}>
              Comply to all environment, safety, and health requirements as per
              the ESH Manual for Contractors & Vendors
            </li>
            <li style={styles.briefingListItem}>
              Comply to other specific legal / operational requirements
            </li>
          </ul>
        </div>

        <div style={styles.terminationTitle}>TERMINATION OF WORK PERMIT</div>
        <div style={styles.terminationSection}>
          <div style={styles.terminationContent}>
            <ul style={styles.terminationList}>
              <li style={styles.terminationListItem}>
                Completion of work – work area / equipment has been made safe
                and clean, fire detection system re-activated (for hot work),
                utility points(electrical / medical gas / LPG / water)
                re-energized if isolation was made prior to work commencement.
              </li>
              <li style={styles.terminationListItem}>
                Expiry of validity - renewal of permit is required.
              </li>
              <li style={styles.terminationListItem}>
                Adverse whether condition (for outdoor work) – new permit is to
                be obtained once it is safe to resume work outdoors.
              </li>
              <li style={styles.terminationListItem}>
                Stop Work Order due to non-compliance – no further work shall be
                allowed unless non-compliance has been rectified.
              </li>
            </ul>

            <div style={styles.nonComplianceBox}>
              <div style={styles.nonComplianceLabel}>
                Details of non-compliance:
              </div>
            </div>
          </div>

          <table
            style={{
              ...styles.table,
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "50%",
                    fontWeight: "bold",
                    borderBottom: "1px solid #000",
                    padding: "8px",
                  }}
                >
                  TERMINATION BY FACILITY / BIOMEDICAL:
                </td>
                <td
                  style={{
                    ...styles.tableCell,
                    width: "50%",
                    fontWeight: "bold",
                    borderBottom: "1px solid #000",
                    padding: "8px",
                  }}
                >
                  TERMINATION BY SHO/DESIGNEE (For Stop Work Order only):
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styles.tableCell,
                    padding: "8px",
                    borderTop: "none",
                  }}
                >
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "10px", marginBottom: "4px" }}>
                      Signature:
                    </div>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "10px", marginBottom: "4px" }}>
                      Name:
                    </div>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "10px", marginBottom: "4px" }}>
                      Date:
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    ...styles.tableCell,
                    padding: "8px",
                    borderTop: "none",
                  }}
                >
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "10px", marginBottom: "4px" }}>
                      Signature:
                    </div>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "10px", marginBottom: "4px" }}>
                      Name:
                    </div>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "10px", marginBottom: "4px" }}>
                      Date:
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={styles.footer}>Last Update: SQM/ESH/FORMS/January2024</div>
      </div>

      {/* ===== PAGE 3: ATTACHMENTS A & B ===== */}
      <div style={{ ...styles.page, pageBreakBefore: "always" }}>
        <div style={styles.header}>
          <h1 style={styles.headerTitle}>Ara Damansara Medical Centre</h1>
          <div style={styles.permitNumber}>
            <p style={styles.permitNumberText}>Permit To Work No.</p>
            <p style={styles.permitNumberText}>{permitNumber}</p>
          </div>
        </div>

        <div style={styles.formContainer}>
          <div style={styles.title}>ATTACHMENT A – LIST OF WORKERS</div>
          <div style={{ padding: "8px", fontSize: "10px" }}>
            To be completed by Contractor - Attach separate list if more than 10
            workers
          </div>

          <table style={styles.attachmentTable}>
            <thead>
              <tr>
                <th style={styles.attachmentCell}>No.</th>
                <th style={styles.attachmentCell}>Name</th>
                <th style={styles.attachmentCell}>
                  Identification Card No. / Passport No.
                </th>
                <th style={styles.attachmentCell}>
                  CIDB Card No. (if applicable)
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(10)].map((_, index) => (
                <tr key={index}>
                  <td style={{...styles.attachmentCell, width: "2.5%"}}>{index + 1}</td>
                  <td style={{...styles.attachmentCell, width: "25%"}}>
                    {workers?.[index]?.name ?? ""}
                  </td>
                  <td style={styles.attachmentCell}>
                    {workers?.[index]?.identificationNo ?? ""}
                  </td>
                  <td style={styles.attachmentCell}>
                    {workers?.[index]?.cidbCardNo ?? ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={styles.formContainer}>
          <div style={styles.title}>
            ATTACHMENT B – LIST OF EQUIPMENT AND CHEMICALS
          </div>
          <div style={{ padding: "8px", fontSize: "10px" }}>
            To be completed by Contractor - Please tick (√) where relevant.
            Attach separate sheet if necessary.
          </div>

          {/* Equipment Table */}
          <table style={styles.attachmentTable}>
            <thead>
              <tr>
                <th style={styles.attachmentCell}>Equipment</th>
                <th style={styles.attachmentCell}>Quantity</th>
                <th style={styles.attachmentCell}>Regulatory Requirement</th>
              </tr>
            </thead>
            <tbody>
              {equipment.map((item, index) => (
                <tr key={index}>
                  <td style={styles.attachmentCell}>
                    <div style={styles.checkboxItem}>
                      <Checkbox checked={item.isSelected} />
                      <span style={styles.checkboxLabel}>{item.name}</span>
                    </div>
                  </td>
                  <td style={styles.attachmentCell}>{item.quantity}</td>
                  <td style={styles.attachmentCell}>{item.regulatoryRequirements}</td>
                </tr>
              ))}
              {/* Add two empty rows at the end */}
              <tr>
                <td style={styles.attachmentCell}>
                  <div style={styles.checkboxItem}>
                    <Checkbox checked={false} />
                    <span style={styles.checkboxLabel}></span>
                  </div>
                </td>
                <td style={styles.attachmentCell}></td>
                <td style={styles.attachmentCell}></td>
              </tr>
              <tr>
                <td style={styles.attachmentCell}>
                  <div style={styles.checkboxItem}>
                    <Checkbox checked={false} />
                    <span style={styles.checkboxLabel}></span>
                  </div>
                </td>
                <td style={styles.attachmentCell}></td>
                <td style={styles.attachmentCell}></td>
              </tr>
            </tbody>
          </table>

          {/* Chemicals Section */}
          <div
            style={{
              borderTop: "1px solid #000",
              marginTop: "20px",
              padding: "8px",
              fontSize: "10px",
              fontWeight: "bold",
            }}
          >
            Chemicals
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              padding: "8px",
              gap: "8px",
            }}
          >
            <div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Adhesive / Glue")}
                />
                <span style={styles.checkboxLabel}>Adhesive / Glue</span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Solvent / Thinner")}
                />
                <span style={styles.checkboxLabel}>Solvent / Thinner</span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Paint")}
                />
                <span style={styles.checkboxLabel}>Paint</span>
              </div>
            </div>
            <div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Diesel / Petrol")}
                />
                <span style={styles.checkboxLabel}>Diesel / Petrol</span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Acid / Corrosive Chemicals")}
                />
                <span style={styles.checkboxLabel}>
                  Acid / Corrosive Chemicals
                </span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Cement")}
                />
                <span style={styles.checkboxLabel}>Cement</span>
              </div>
            </div>
            <div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={chemicals.selectedChemicals.includes("Pesticides")}
                />
                <span style={styles.checkboxLabel}>Pesticides</span>
              </div>
              <div style={styles.checkboxItem}>
                <Checkbox
                  checked={!!chemicals.othersSpecify}
                />
                <span style={styles.checkboxLabel}>
                  Others (Please specify) : {chemicals.othersSpecify}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.footer}>Last Update: SQM/ESH/FORMS/January2024</div>
      </div>
    </>
  );
}

export const ADMCWorkPermitPdf = ADMCWorkPermit;
