import AppButton from "@/components/AppButton";
import AppItemInfo from "@/components/AppItemInfo";
import {
  DRAWER_UPDATE_STATE,
  PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER
} from "@/components/drawers/AppDrawer";
import { Pencil, Tag } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../lib/trpc";
import PtwTemplateDetailItem from "./components/PtwTemplateDetailItem/PtwTemplateDetailItem";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

export default function PtwTemplateDetailDrawer() {
  const [searchParam, setSearchParams] = useSearchParams();
  const ptwTemplateId = searchParam.get("ptwTemplateId");

  const { data: ptw } = trpc.workPermit.getOneWorkPermitTemplate.useQuery(
    {
      ptwTemplateId: ptwTemplateId as string,
    },
    {
      enabled: !!ptwTemplateId,
    }
  );

  const isCustomTemplate = ptw?.setupType === "CUSTOM";

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 items-center">
          <h3 className="font-sans text-2xl font-bold">{ptw?.name}</h3>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <div>
                  <AppButton
                    label="Edit"
                    icon={<Pencil />}
                    variant="outline"
                    disabled={isCustomTemplate}
                    onClick={() => {
                      setSearchParams((p) => {
                        p.set("drawerType", PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER);
                        p.set("drawerState", DRAWER_UPDATE_STATE);
                        return p;
                      });
                    }}
                  />
                </div>
              </TooltipTrigger>
              {isCustomTemplate && (
                <TooltipContent className="max-w-[300px]">
                  <p>Custom work permits setup can only be done through administrator of the solution. Please contact them to amend the template further.</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </div>
        {isCustomTemplate && (
          <Badge 
            variant="outline" 
            className={cn(
              "w-fit text-xs px-3 py-1 bg-primary-50 border-primary-900 text-primary-900",
              "flex items-center gap-1.5"
            )}
          >
            <div className="w-1.5 h-1.5 rounded-full bg-primary-900" />
            Custom Template
          </Badge>
        )}
      </div>
      <AppItemInfo
        icon={<Tag className="text-primary-900" />}
        label="Name"
        content={<p className="font-sans">{ptw?.name}</p>}
      />
      <h3 className="font-sans text-xl font-bold">Fields</h3>
      <PtwTemplateDetailItem ptwFormats={ptw?.ptwFormat ?? []} />
    </div>
  );
}
