import { Checkbox } from "@/components/ui/checkbox";
import { trpc } from "@/lib/trpc";
import { useAppSelector } from "../../../../../redux/store";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import type { UpdateWorkOrderSettingInput } from "@cerev-cmms/zod-types";

export interface WorkOrderSettingRequiredCheckboxProps {
  keyValue: "subjectRequired" | "descriptionRequired" | "workOrderTypeRequired" | "assignToRequired";
  disabled?: boolean;
}

export default function WorkOrderSettingRequiredCheckbox({
  keyValue,
  disabled = false,
}: WorkOrderSettingRequiredCheckboxProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const utils = trpc.useUtils();

  const { data: settings, isLoading } = trpc.settings.getWorkOrderSetting.useQuery(
    { projectId: activeProj?.id ?? 0 },
    { enabled: !!activeProj?.id }
  );

  const updateSettings = trpc.settings.updateWorkOrderSetting.useMutation({
    onSuccess: () => {
      utils.settings.getWorkOrderSetting.invalidate();
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Loader2 className={cn("h-4 w-4 animate-spin")} />
      </div>
    );
  }

  if (!settings?.data || !activeProj?.id) return null;

  const currentSettings = settings.data as UpdateWorkOrderSettingInput;
  const projectId = activeProj.id;

  return (
    <Checkbox
      checked={currentSettings[keyValue] === "REQUIRED"}
      onCheckedChange={(checked) => {
        updateSettings.mutate({
          ...currentSettings,
          projectId,
          [keyValue]: checked ? "REQUIRED" : "NOT_REQUIRED",
        });
      }}
      disabled={disabled}
    />
  );
}