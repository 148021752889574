import { isBefore } from "date-fns";
import { z } from "zod";

// Base schemas for PTW components
const ptwAttachmentSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  url: z.string(),
  underProject: z.object({
    id: z.string(),
  }),
});

const ptwFormatSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
});

// Input schemas
export const createWorkPermitInput = z
  .object({
    applierCompanyName: z
      .string()
      .min(1, { message: "Please enter the company name" }),
    email: z.string().email(),
    telNo: z.string().min(6, { message: "Please enter a valid phone number" }),
    description: z
      .string()
      .min(1, { message: "Please enter a description of the works" }),
    workStartDate: z.coerce.date({
      required_error: "You need to select a start date",
    }),
    workEndDate: z.coerce.date({
      required_error: "You need to select an end date",
    }),
    locationOfWork: z.string().min(1, { message: "Please enter a location" }),
    projectCode: z.string(),
    workers: z
      .object({
        name: z.string(),
        icNo: z.string(),
      })
      .array()
      .nonempty({ message: "Must add at least one worker" }),
  })
  .refine(
    (data) => {
      const startDate = data.workStartDate;
      const endDate = data.workEndDate;
      return !isBefore(endDate, startDate);
    },
    {
      message: "End date cannot be earlier than start date",
      path: ["workEndDate"],
    }
  );

export const getWorkPermitsInput = z.object({
  projectId: z.string(),
  ptwStatus: z.enum(["NEW", "APPROVED", "REJECTED"]),
  search: z.string().optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  cursorId: z.string().optional(),
});

// Base schemas for attachments and options
const attachmentWithProjectSchema = z.object({
  id: z.number().optional(),
  gsPath: z.string(),
  url: z.string(),
  name: z.string(),
  underProject: z.object({
    id: z.number(),
  }),
  underProjectId: z.number(),
  fileSizeMb: z.string(),
});

const ptwOptionSchema = z.object({
  id: z.number(),
  value: z.string(),
});

// Form field schemas
const ptwTextFormField = z.object({
  id: z.number(),
  value: z.string(),
});

const ptwNumFormField = z.object({
  id: z.number(),
  value: z.number(),
});

const ptwAttachmentFormField = z.object({
  id: z.number(),
  attachments: z.array(attachmentWithProjectSchema),
});

const ptwImageFormField = z.object({
  id: z.number(),
  attachments: z.array(attachmentWithProjectSchema),
});

const ptwDateFormField = z.object({
  id: z.number(),
  date: z.coerce.date(),
});

const ptwOptionValues = z.object({
  id: z.number(),
  ptwOptions: z.array(ptwOptionSchema),
});

const ptwBoolFormField = z.object({
  id: z.number(),
  value: z.boolean(),
});

// Main update input schema
export const updateWorkPermitInput = z.object({
  name: z.string(),
  email: z.string().email(),
  ptwTexts: z.array(ptwTextFormField),
  ptwNumbers: z.array(ptwNumFormField),
  ptwAttachments: z.array(ptwAttachmentFormField),
  ptwImages: z.array(ptwImageFormField),
  ptwDates: z.array(ptwDateFormField),
  ptwOptionValues: z.array(ptwOptionValues),
  ptwBools: z.array(ptwBoolFormField),
});

// Additional input schemas
export const getWorkPermitInput = z.object({
  ptwId: z.string(),
});

export const updateWorkPermitParamsInput = z.object({
  ptwId: z.string(),
  dto: updateWorkPermitInput,
});

export const deleteWorkPermitAttachmentInput = z.object({
  ptwAttId: z.string(),
  attId: z.string(),
});

export const workPermitActionInput = z.object({
  ptwId: z.string(),
});

export const getWorkPermitTemplatesInput = z.object({
  projectId: z.number(),
  search: z.string().optional(),
  page: z.number().optional(),
  limit: z.number().optional(),
});

export const GetOneWorkPermitTemplateInput = z.object({
  ptwTemplateId: z.string(),
});

// Type exports
export type CreateWorkPermitInput = z.infer<typeof createWorkPermitInput>;
export type GetWorkPermitsInput = z.infer<typeof getWorkPermitsInput>;
export type GetWorkPermitInput = z.infer<typeof getWorkPermitInput>;
export type UpdateWorkPermitInput = z.infer<typeof updateWorkPermitInput>;
export type UpdateWorkPermitParamsInput = z.infer<typeof updateWorkPermitParamsInput>;
export type DeleteWorkPermitAttachmentInput = z.infer<typeof deleteWorkPermitAttachmentInput>;
export type WorkPermitActionInput = z.infer<typeof workPermitActionInput>;
export type GetWorkPermitTemplatesInput = z.infer<typeof getWorkPermitTemplatesInput>;
export type GetOneWorkPermitTemplateInput = z.infer<typeof GetOneWorkPermitTemplateInput>;
