import { GetPaginatedAssetsInput } from "@cerev-cmms/zod-types";
import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Filter } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import AppTextField from "../../../../components/AppTextField";
import { ASSET_FILTER_DRAWER } from "../../../../components/drawers/AppDrawer";
import { DataTable } from "../../../../components/react-table/DataTable";
import { Badge } from "../../../../components/ui/badge";
import { Button } from "../../../../components/ui/button";
import useDebounce from "../../../../hooks/useDebounce";
import { trpc } from "../../../../lib/trpc";
import { cn } from "../../../../lib/utils";
import { useAppSelector } from "../../../../redux/store";
import { useSelectAssetFilterStore } from "../../../../stores/assets/assetFilterStore";
import { equipmentColumns } from "./equipmentColumn";

export default function EquipmentTableView() {
  const methods = useForm<{ search: string; selectedEquipmentTypeId: string }>({
    defaultValues: {
      search: "",
      selectedEquipmentTypeId: "",
    },
  });

  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const search = methods.watch("search");
  const selectedEquipmentTypeId = methods.watch("selectedEquipmentTypeId");

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Get values from asset filter store
  const assetTypesFilter = useSelectAssetFilterStore((state) => state.assetTypes);
  const filterLocation = useSelectAssetFilterStore((state) => state.filterLocation);
  const dateRange = useSelectAssetFilterStore((state) => state.dateRange);
  const filterCount = useSelectAssetFilterStore((state) => state.getFilterCount);
  const cleanUp = useSelectAssetFilterStore((state) => state.cleanUp);
  const setSearch = useSelectAssetFilterStore((state) => state.setSearch);

  useEffect(() => {
    // Update search in filter store when search input changes
    setSearch(search);
    
    // Clean up when component unmounts
    return () => {
      cleanUp();
    };
  }, [search, setSearch, cleanUp]);

  const { data: equipmentTypes, isLoading: equipmentTypesLoading } =
    trpc.assets.getAssetTypes.useQuery(
      {
        projectId: activeProj?.id?.toString() ?? "-",
        companyId: activeComp?.id?.toString() ?? "-",
      },
      {
        enabled: !!activeProj && !!activeComp,
      }
    );

  // Create the query arg with filter values
  const queryArg = useDebounce<GetPaginatedAssetsInput>({
    value: {
      assetTypeIds: selectedEquipmentTypeId
        ? [Number(selectedEquipmentTypeId)]
        : assetTypesFilter.length > 0
        ? assetTypesFilter.map((at) => at.id ?? 0)
        : equipmentTypes?.map((et) => et.id ?? 0) ?? [],
      projectId: activeProj?.id ?? 0,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
      locationIds: filterLocation ? [filterLocation.id ?? 0] : undefined,
      // Note: The API doesn't currently support fromDate, or toDate
      // If you want to add these filters, you'll need to update the API schema
    },
    delay: 500,
  });

  const { data, isLoading, isFetching } =
    trpc.assets.getPaginatedAssets.useQuery(queryArg, {
      enabled: !!activeProj && !!equipmentTypes?.length,
    });

  const table = useReactTable({
    data: data?.data ?? [],
    columns: equipmentColumns,
    manualPagination: true,
    getRowId: (row) => row.id?.toString() ?? "-",
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: data?.data
      ? Math.ceil(+data.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
      pagination,
    },
  });

  const handleEquipmentTypeChange = (value: string) => {
    methods.setValue("selectedEquipmentTypeId", value);
    table.resetPagination();
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex gap-4">
            <AppTextField
              name="search"
              className="w-[400px]"
              inputClassName="bg-transparent"
              placeholder="Search..."
              onChange={() => table.resetPagination()}
            />
            <Button
              className={cn("hover:cursor-pointer truncate", {
                "bg-primary-900 hover:bg-primary-900": filterCount() > 0,
              })}
              variant="outline"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", ASSET_FILTER_DRAWER);
                  return p;
                });
              }}
              asChild
            >
              <div className="flex gap-2">
                <Filter
                  className={cn("text-gray-300 mr-1", {
                    "text-white": filterCount() > 0,
                  })}
                />
                <div
                  className={cn("hover:cursor-pointer text-gray-300", {
                    "text-white": filterCount() > 0,
                  })}
                >
                  Filter by
                </div>
                {filterCount() > 0 && (
                  <Badge variant="destructive" className="text-white">{filterCount()}</Badge>
                )}
              </div>
            </Button>
          </div>
        </div>

        <DataTable
          table={table}
          isLoading={isLoading || equipmentTypesLoading}
          isFetching={isFetching}
          totalCount={data?.totalCount ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </div>
    </FormProvider>
  );
}
