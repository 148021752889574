import { Calendar } from "lucide-react";
import { differenceInDays, isFuture } from "date-fns";
import { Badge } from "../../../../../ui/badge";
import { cn } from "../../../../../../lib/utils";
import useDate from "../../../../../../hooks/useDate";
import AppItemInfo from "../../../../../AppItemInfo";

interface CalibrationDateDisplayProps {
  calibrationDate?: Date | null;
}

export default function CalibrationDateDisplay({ calibrationDate }: CalibrationDateDisplayProps) {
  const { formatDateToLocal } = useDate();

  const getCalibrationStatus = (calibrationDate: Date) => {
    if (isFuture(calibrationDate)) {
      const daysRemaining = differenceInDays(calibrationDate, new Date());
      if (daysRemaining > 30) {
        return {
          label: "Valid",
          variant: "default" as const,
          className: "bg-green-500",
        };
      }
      return {
        label: `${daysRemaining} days left`,
        variant: "secondary" as const,
        className: "bg-yellow-500",
      };
    }
    return {
      label: "Calibration Due",
      variant: "destructive" as const,
      className: "bg-red-500",
    };
  };

  return (
    <AppItemInfo
      label="Calibration Date"
      icon={<Calendar className="text-primary-900" />}
      content={
        <div className="flex items-center gap-2">
          <p className="font-sans text-neutral-900">
            {calibrationDate ? (
              formatDateToLocal({
                dateStr: calibrationDate.toISOString(),
                formatting: "dd MMM yyyy"
              })
            ) : (
              <>-</>
            )}
          </p>
          {calibrationDate && (
            <Badge
              variant={getCalibrationStatus(calibrationDate).variant}
              className={cn(
                "text-xs py-0.25",
                getCalibrationStatus(calibrationDate).className
              )}
            >
              {getCalibrationStatus(calibrationDate).label}
            </Badge>
          )}
        </div>
      }
    />
  );
} 