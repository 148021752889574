import { dynamicFieldSchema } from "@cerev-cmms/zod-types";
import { z } from "zod";
import AppItemInfo from "../../../AppItemInfo";
import { AlignLeft, Check, Hash, Calendar } from "lucide-react";
import { format } from "date-fns";
import { Separator } from "../../../ui/separator";

type DynamicField = z.infer<typeof dynamicFieldSchema>;

interface WorkRequestDynamicFieldsProps {
  fields: DynamicField[];
  values: Record<string, any>;
}

/**
 * WorkRequestDynamicFields component
 * Renders dynamic fields for work requests
 * 
 * Note: For select and multiselect fields, the values are stored as JSON objects
 * with a 'label' property (not 'name'). This is important for proper rendering.
 */
export default function WorkRequestDynamicFields({
  fields,
  values,
}: WorkRequestDynamicFieldsProps) {
  /**
   * Renders the value of a dynamic field based on its type
   * 
   * @param field - The dynamic field definition
   * @returns JSX element with the rendered field value
   */
  function renderFieldValue(field: DynamicField) {
    const value = values[field.id];
    if (!value) return "-";

    switch (field.type) {
      case "text":
        const textJsonValue = JSON.parse(value);
        return <p className="font-sans text-neutral-900">{textJsonValue}</p>;

      case "number":
        const numberJsonValue = JSON.parse(value);
        return <p className="font-sans text-neutral-900">{numberJsonValue}</p>;

      case "date":
        try {
          return (
            <p className="font-sans text-neutral-900">
              {format(new Date(value), "dd/MM/yyyy")}
            </p>
          );
        } catch {
          return <p className="font-sans text-neutral-900">{value}</p>;
        }

      case "select":
        // IMPORTANT: jsonValue uses 'label' property, not 'name'
        // The select field stores objects with { label, value } structure
        const jsonValue = JSON.parse(value);
        return <p className="font-sans text-neutral-900">{jsonValue.label}</p>;

      case "multiselect":
        // IMPORTANT: multiSelectJsonValue uses 'label' property, not 'name'
        // The multiselect field stores an array of objects with { label, value } structure
        const mutliSelectJsonValue = JSON.parse(value);
        return (
          <p className="font-sans text-neutral-900">
            {mutliSelectJsonValue
              .map((v: { label: string }) => v.label)
              .join(", ")}
          </p>
        );

      case "checkbox":
        return (
          <div className="flex items-center">
            {value ? (
              <Check className="h-5 w-5 text-primary-900" />
            ) : (
              <p className="font-sans text-neutral-900">No</p>
            )}
          </div>
        );

      default:
        return <p className="font-sans text-neutral-900">-</p>;
    }
  }

  /**
   * Gets the appropriate icon for a field type
   * 
   * @param fieldType - The type of dynamic field
   * @returns JSX element with the appropriate icon
   */
  function getFieldIcon(fieldType: DynamicField["type"]) {
    switch (fieldType) {
      case "text":
        return <AlignLeft className="text-primary-900" />;
      case "number":
        return <Hash className="text-primary-900" />;
      case "date":
        return <Calendar className="text-primary-900" />;
      case "select":
      case "multiselect":
      case "checkbox":
        return <Check className="text-primary-900" />;
      default:
        return <AlignLeft className="text-primary-900" />;
    }
  }

  return (
    <div className="space-y-5">
      <Separator />
      <p className="text-xl font-bold">Additional Fields</p>
      {fields
        .map((field) => (
          <AppItemInfo
            key={field.id}
            label={field.label}
            icon={getFieldIcon(field.type)}
            content={renderFieldValue(field)}
          />
        ))}
      <Separator />
    </div>
  );
}
